import React from "react";
import "../styles/homescreen.css";
import SiteHeader from "../components/header";
import MiniHeader from "../components/miniHeader";
import Footer from "../components/footer";

const PrivacyPolicy = () => {
  return (
    <div className="container-div">
      <div style={{ marginBottom: "50px" }}>
        <SiteHeader />
      </div>
      <MiniHeader name={"Privacy Policy"} />
      <div
        style={{
          justifyContent: "flex-start",
          alignItems: "flex-start",
          display: "flex",
          flexDirection: "column",
          padding: 10,
          color: "#000",
          marginTop: "50px",
          marginBottom: "50px",
          width: "90%",
          justifySelf: "center",
          alignSelf: "center",
          borderBottom: "1px solid #888888",
        }}
      >
        <p
          style={{
            alignSelf: "center",
          }}
        >
          Last modified on Jul 04, 2024{" "}
        </p>
        <br />
        <p
          style={{
            alignSelf: "center",
          }}
        >
          {" "}
          FITFLEX Pvt. Ltd provides the platform (“FITFLEX”).
        </p>
        <br />{" "}
        <p style={{ textAlign: "justify" }}>
          {" "}
          FITFLEX recognizes that privacy is important, and we value and take
          steps to ensure the privacy of our users. FITFLEX is committed to
          providing quality services to you and this privacy policy (“Privacy
          Policy”) outlines our ongoing obligations to you in respect of how we
          manage your Personal Information. Personal Information includes any
          information that directly and/or indirectly and/or in combination with
          other information, allows identification of an individual or group of
          individuals. This includes information about individuals even if no
          names or other contact details are collected. For example, information
          relating to an IP address, device or an online identifier may itself
          be Personal Information even with no direct link to a name or contact.
        </p>{" "}
        <p style={{ textAlign: "justify" }}>
          This page is used to inform visitors/subscribers regarding our
          policies with the collection, use, and disclosure of Personal
          Information if anyone decides to use our services. If you choose to
          use our Service, then you agree to the collection and use of
          information in relation to this policy. We will not use or share your
          information with anyone except as described in this Policy.
        </p>{" "}
        <p style={{ textAlign: "justify" }}>
          The terms used in this Privacy Policy have the same meanings as in our
          Terms and Conditions, which are accessible at
          http://fitflexapp.com/terms unless otherwise defined in this Privacy
          Policy.
        </p>{" "}
        <br />
        <strong> 1. CONSENT AND MODIFICATION</strong>
        <p style={{ textAlign: "justify" }}>
          By using the Service, you consent to the terms of this Privacy Policy
          and to our collection, processing and sharing of Personal Information
          for the purposes set forth herein. If you do not agree to this Privacy
          Policy, please do not access or otherwise use the Service. We reserve
          the right, at our sole discretion, to change, modify or otherwise
          alter this Policy from time to time. Thus, you are advised to review
          this section periodically for any changes. These changes are effective
          immediately after they are posted on this page.
        </p>{" "}
        <br />
        <strong> 2. INFORMATION COLLECTION AND USE </strong>
        <p style={{ textAlign: "justify" }}>
          Upon subscribing FITFLEX and usage, we collect your personal
          information such as sign-up information, your device and its technical
          specification, usage history, associated server logs, your transaction
          history, traffic data, location and internet data etc. (“Personal
          Information”). This is set out below in detail as well:
        </p>{" "}
        <h4 style={{ textAlign: "justify", fontWeight: "700" }}>
          Personal Information
        </h4>
        <p style={{ textAlign: "justify" }}>
          Collection of Primary Account Information: We collect information you
          provide when you sign up for a FITFLEX account, including but not
          limited to your email address, mobile number, name, and any other
          identifying information you choose to provide.
        </p>{" "}
        <p style={{ textAlign: "justify" }}>
          We use the collected primary account information to authenticate your
          identity and manage your account effectively. This information enables
          us to provide personalized recommendations and continually enhance
          your user experience. We also use it to communicate important updates
          and features directly relevant to your use of FITFLEX. Ensuring the
          security of your information is paramount; we employ measures to
          prevent unauthorized access or misuse of our services. Additionally,
          we comply with legal obligations and enforce our terms and policies to
          maintain a safe environment for all users. Internally, this data
          supports various operations including troubleshooting, data analysis,
          testing, research, and surveys, helping us to improve and refine
          FITFLEX for our community.
        </p>
        <p style={{ textAlign: "justify" }}>
          Log Data: Whenever you use our Service, in a case of an error in the
          FITFLEX we collect data and information (through third party products)
          on your phone called Log Data. This Log Data may include information
          such as your device Internet Protocol ("IP") address, device name,
          operating system version, the configuration of the FITFLEX when
          utilizing our Service, the time and date of your use of the Service,
          and other statistics.
        </p>{" "}
        <p style={{ textAlign: "justify" }}>
          Device and technical usage information: We collect information you
          provide on your consent when you use FITFLEX, such as your customer
          profile, unique identification code, mobile carrier, photos, videos,
          music, documents, contacts, SMS’s and time zone setting. We also
          collect device information such as operating systems, battery, signal
          strength, device identifiers, settings and software names. We use this
          data to let you know about your usage statistics, to assist us in
          enforcing our terms, conditions and policies, to improve FITFLEX, and
          also to administer internal operations (such as for troubleshooting,
          data analysis, testing, research, statistical and survey purposes), to
          keep FITFLEX safe and secure (including verifying accounts and
          activity) to comply with legal obligations.
        </p>{" "}
        <h4 style={{ textAlign: "justify", fontWeight: "700" }}>
          Other Ad-Hoc information
        </h4>
        <p style={{ textAlign: "justify" }}>
          We may conduct surveys or obtain other feedback, comments and input
          from you via FITFLEX or through websites or other platforms as deemed
          necessary based on viability. We use this information to understand
          your needs encircling what is working well, what improvements can be
          made in order to enhance your user experience and improve our
          platform. We may use such information to provide marketing to your
          pursuant to FITFLEX Marketplace and Marketing section below.
        </p>
        <h4 style={{ textAlign: "justify", fontWeight: "700" }}>
          Aggregated and anonymized Data
        </h4>
        <p style={{ textAlign: "justify" }}>
          We will use any of the above information to create aggregated and
          anonymized data, insights and reports for internal business planning
          purposes and to understand how to improve FITFLEX for our users.
        </p>
        <p style={{ textAlign: "justify" }}>
          We will also use the above mentioned information to contact you,
          inform you regarding any new developments, features or other important
          information about FITFLEX.
        </p>
        <br />
        <strong> 3. USER PERMISSIONS FOR MOBILE APP </strong>{" "}
        <p style={{ textAlign: "justify" }}>
          While using FITFLEX in order to provide features of the Application,
          we may collect, with your prior permission, personal information which
          includes SMS to read and verify OTP, internal and external media i.e.,
          internet and pictures from your Device’s camera and gallery.
        </p>{" "}
        <p style={{ textAlign: "justify" }}>
          For a better experience, while using our Service, we may require you
          to provide us with certain personally identifiable information,
          including but not limited to camera or location. The information that
          we request will be retained by us and used as described in this
          privacy policy.
        </p>{" "}
        <br />
        <strong> 4. COOKIES & THIRD-PARTY SITES</strong>
        <p style={{ textAlign: "justify" }}>
          Cookies are files with small amount of data that is commonly used an
          anonymous unique identifier. These are sent to your browser from the
          website that you visit and are stored on your device internal memory.
        </p>{" "}
        <p style={{ textAlign: "justify" }}>
          This Service does not use these "cookies" explicitly. However, the
          FITFLEX may use third party code and libraries that use "cookies" to
          collection information and to improve their services. You have the
          option to either accept or refuse these cookies and know when a cookie
          is being sent to your device. If you choose to refuse our cookies, you
          may not be able to use some portions of this Service.
        </p>{" "}
        <p style={{ textAlign: "justify" }}>
          FITFLEX uses certain third party service(s) that may collect
          information used to identify you. We may employ third-party companies
          and individuals due to the following reasons:
        </p>{" "}
        <ul>
          <li>
            <p style={{ textAlign: "justify" }}>To facilitate our Services;</p>
          </li>
          <li>
            <p style={{ textAlign: "justify" }}>
              To provide the Service on our behalf;
            </p>
          </li>
          <li>
            <p style={{ textAlign: "justify" }}>
              To perform Service-related services; or
            </p>
          </li>
          <li>
            <p style={{ textAlign: "justify" }}>
              To assist us in analyzing how our Service is used.
            </p>
          </li>
        </ul>
        <p style={{ textAlign: "justify" }}>
          We want to inform users of this Service that these third parties have
          access to your Personal Information. The reason is to perform the
          tasks assigned to them on our behalf. However, they are obligated not
          to disclose or use the information for any other purpose.
        </p>{" "}
        <p style={{ textAlign: "justify" }}>
          This Service may contain links to other sites. If you click on a
          third-party link, you will be directed to that site. Note that these
          external sites are not operated by us. Therefore, we strongly advise
          you to review the Privacy Policy of these websites. We have no control
          over and assume no responsibility for the content, privacy policies,
          or practices of any third-party sites or services.
        </p>{" "}
        <br />
        <strong>5. DISCLAIMER </strong>{" "}
        <p style={{ textAlign: "justify" }}>
          If you have not been physically active for more than 1 (one) year, or
          if you have any medical history that may put you at risk, including,
          without limitation, you are required to seek approval from a qualified
          health care practitioner prior to using the Application. The content
          is not intended to be a substitute for professional medical advice,
          diagnosis or treatment.
        </p>{" "}
        <p style={{ textAlign: "justify" }}>
          You understand that the FITFLEX service is intended to be used in
          connection with fitness activities. You expressly agree that engaging
          in fitness activities as part of the FITFLEX carry certain inherent
          and significant of property damage, bodily injury or death and that
          you voluntarily assume all known and unknown risks associated with
          these activities. FITFLEX is not responsible for any kind of
          health-related problems.
        </p>{" "}
        <br />
        <strong> 6. SECURITY RISKS</strong>{" "}
        <p style={{ textAlign: "justify" }}>
          FITFLEX is very concerned about safeguarding the confidentiality of
          your information and we take concrete steps from our end to ensure
          your privacy. However, be aware that no security measures that we take
          to protect your information is absolutely guaranteed to avoid
          unauthorized access or use of your Personal / Non-Personal Information
          or is impenetrable.
        </p>
        <br />
        <strong> 7. CALIFORNIA PRIVACY RIGHTS </strong>{" "}
        <p style={{ textAlign: "justify" }}>
          We do not disclose your Personal Information to third parties for the
          third parties direct marketing purposes, unless you first
          affirmatively agree to that disclosure.
        </p>{" "}
        <p style={{ textAlign: "justify" }}>
          California Civil Code Section 1798.83 permits user who are California
          residents to request certain information regarding disclosure of
          Personal Information to third parties for their direct marketing
          purposes. If you would like more information with respect to your
          privacy rights under California law, you may contact us at the contact
          point below. Please reference your request as California Privacy
          Policy Disclosure Information.
        </p>{" "}
        <br />
        <strong> 8. SENSITIVE INFORMATION</strong>{" "}
        <p style={{ textAlign: "justify" }}>
          We do not ask that to send or disclose, any sensitive Personal
          Information (e.g., information related to racial or ethnic origin,
          political opinions, religion or other beliefs, criminal background or
          membership in past organizations, including trade union memberships)
          on or through an application, or otherwise to us.
        </p>
        <br />
        <strong> 9. CONTACTING US / REPORTING VIOLATIONS</strong>{" "}
        <p style={{ textAlign: "justify" }}>
          If you have any questions or comments about this Policy or our privacy
          practices, or to report any violations of the Policy or abuse of
          Application, please contact us at customer.support@fitflexapp.com
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
