import React, { useEffect, useState } from "react";
import impFitnessIcon from "../assets/images/impFitnessIcon.png";
import looseWeightIcon from "../assets/images/looseWeightIcon.png";
import buildMusclesIcon from "../assets/images/buildMusclesIcon.png";
import "../styles/LpAboutYou.css";
import arrowIcon from "../assets/images/arrowIcon.png";
import { Button, Dropdown, Select, Spin } from "antd";
import { getGoal, getQuotes, setGoal } from "../api/requests";
import { LoadingOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import FoodIcon from "../assets/images/foodIcon.svg";
import { useTranslation } from "react-i18next";

const LpGoal = () => {
  const [quote, setQuote] = useState("Hello world");
  const [author, setAuthor] = useState("by Saad Usman");
  const [goal, setgoal] = useState();
  const navigate = useNavigate();
  // const [activity, setActivity] = useState("Set Activity");
  const { t } = useTranslation();
  const [goalData, setGoalData] = useState();
  const [loading, setLoading] = useState(true);
  const [physicalFactor, setPhysicalFactor] = useState("");
  const [calories, setCalories] = useState(0);
  const [meal, setMeal] = useState("");
  const [rmr, setRmr] = useState(0);
  const [band, setBand] = useState({
    breakfast: "",
    dinner: "",
    lunch: "",
    snack1: "",
    snack2: "",
    name: "",
  });
  // const location = useLocation();
  const data = JSON.parse(localStorage.getItem("user"));
  console.log("data is :::", data);
  const heightCm = JSON.parse(localStorage.getItem("heightCm"));
  // console.log("height CM IS :::", heightCm);

  const calculateRmr = () => {
    let RMR = 0;
    // console.log(data)
    let dob = moment(data.dob);
    // console.log("dob is :::", dob);
    let currentday = moment(new Date());
    let age = currentday.diff(dob, "years");
    console.log("age is :::", age);
    if (data.sex === "female") {
      RMR = 655.1 + 9.56 * data.target_weight + 1.85 * heightCm - 4.68 * age;
    } else {
      RMR = 66.47 + 13.75 * data.target_weight + 5 * heightCm - 6.76 * age;
    }
    console.log("RMR IS :::", RMR);
    return RMR;
  };
  useEffect(() => {
    const RMR = calculateRmr();
    setRmr(parseInt(RMR));

    let param = {
      language_id: 7,
    };

    getQuotes(param).then((response) => {
      if (response.status === 200) {
        // console.log("quote api response is :::", response.data);
        setQuote(response.data.Quote);
        setAuthor(response.data.Author);
      }
    });
    // console.log("rmr is :::", rmr);
    const getGoalData = () => {
      let params = {
        gender:
          data.sex === "male"
            ? "Men"
            : data.sex === "female"
            ? "Women"
            : "Other",
      };

      let token = JSON.parse(localStorage.getItem("jwt"));

      getGoal(params, token).then((response) => {
        console.log("response is :::", response);
        if (response.status === 200) {
          console.log("goal api response is :::", response.data);
          setGoalData(response.data);
          setLoading(false);
          setgoal(response.data.goal_id);
          setPhysicalFactor(response.data.selected[0].physical_factor);
          // if (response.data.selected[0].body_type !== null) {
          setMeal(response.data.selected[0].body_type);
          // }
          if (calories !== null) {
            setCalories(response.data.selected[0].calories_per_day);
          }
          let calorie = parseInt(
            response.data.selected[0].calories_per_day / 3
          );

          setBand({
            breakfast: calorie,
            dinner: calorie,
            lunch: calorie,
            snack1: 0,
            snack2: 0,
          });
        }
      });
    };
    getGoalData();
  }, []);

  const handleChange = (value) => {
    console.log("the value is :::", value);
    console.log(goalData.body_types[value]);
    if (value === "0") {
      let calorie = parseInt(calories / 3);
      // console.log("calorie is :::", calorie);
      setBand({
        breakfast: calorie,
        dinner: calorie,
        lunch: calorie,
        snack1: 0,
        snack2: 0,
        name: goalData.body_types[value].name,
      });
      setMeal(goalData.body_types[value].name);
    } else if (value === "1") {
      // console.log("select 1");
      let calorie = parseInt(calories / 3);
      let snackCalorie = parseInt(calories * 0.15);
      setBand({
        breakfast: calorie,
        dinner: calorie,
        lunch: calorie,
        snack1: snackCalorie,
        snack2: 0,
        name: goalData.body_types[value].name,
      });
      setMeal(goalData.body_types[value].name);
    } else {
      let calorie = parseInt(calories / 3);
      let snackCalorie = parseInt(calories * 0.15);
      let snack = parseInt(snackCalorie / 2);
      setMeal(goalData.body_types[value].name);

      setBand({
        breakfast: calorie,
        dinner: calorie,
        lunch: calorie,
        snack1: snack,
        snack2: snack,
        name: goalData.body_types[value].name,
      });
    }
  };

  // console.log("location is :::", location);
  const handlePhysicalChange = (value) => {
    // console.log("the physical value is :::", value);
    // console.log("physical factor is :::", rmr);
    // To do
    if (value === 0) {
      let x = parseInt(rmr * goalData.physical_factor[0].Very_Light_Activity);
      console.log("xyz is :::", x);

      setCalories(x);
      setPhysicalFactor("very light");
    } else if (value === 1) {
      let x = parseInt(rmr * goalData.physical_factor[0].Light_Activity);
      // console.log(
      //   "light activity is :::",
      //   goalData.physical_factor[0].Light_Activity
      // );
      setCalories(x);
      setPhysicalFactor("light");
    } else if (value === 2) {
      let x = parseInt(rmr * goalData.physical_factor[0].Moderate_Activity);

      setCalories(x);
      setPhysicalFactor("moderate");
    } else if (value === 3) {
      let x = parseInt(rmr * goalData.physical_factor[0].Heavy_Activity);
      setCalories(x);
      setPhysicalFactor("heavy");
    } else if (value === 4) {
      let x = parseInt(rmr * goalData.physical_factor[0].Extreme_Activity);
      setCalories(x);
      setPhysicalFactor("extreme");
    } else {
      setCalories(parseInt(rmr * 2.1));
    }
  };

  const meal_menu = [];
  if (!loading) {
    goalData.body_types.map((item, index) => {
      meal_menu.push(<Select.Option key={index}>{item.name}</Select.Option>);
    });
  }

  const antIcon = (
    <LoadingOutlined
      style={{ fontSize: 45, color: "#FF1E1E", marginBottom: 20 }}
      spin
    />
  );
  // console.log("calories are :::", calories);

  const mealStatment =
    meal === "3 Meals"
      ? t("meal_statment_1")
      : meal === "4 Meals"
      ? t("meal_statment_2")
      : meal === "5 Meals"
      ? t("meal_statment_3")
      : t("meal_statment_1");

  const physicalStatment =
    physicalFactor === "very Light"
      ? t("phy_statment_1")
      : physicalFactor === "light"
      ? t("phy_statment_2")
      : physicalFactor === "moderate"
      ? t("phy_statment_3")
      : physicalFactor === "heavy"
      ? t("phy_statment_4")
      : physicalFactor === "extreme"
      ? t("phy_statment_5")
      : t("phy_statment_1");

  const nutritionPlan = async () => {
    // console.log("calories are ::::", calories);
    const jwt = localStorage.getItem("jwt");
    const token = JSON.parse(jwt);
    let payload = {
      breakfast: band.breakfast,
      lunch: band.lunch,
      snack1: band.snack1,
      snack2: band.snack2,
      dinner: band.dinner,
      body_type: meal,
      calories_per_day: calories,
      goal_id: goal,
      flag: 1,
      physical_factor: physicalFactor,
    };
    // console.log("band is :::", band);
    // console.log("payload is :::", payload);
    // console.log("calories are :::", calories);
    await setGoal(payload, token).then((response) => {
      if (response.status === 200) {
        navigate("/home");
        // console.log("response is :::", response);
        // console.log("meal is :::", meal);
        // console.log("physical activity is :::", physicalFactor);
      }
    });
  };

  if (loading) {
    return (
      <div class="loader">
        <Spin className="spinner" indicator={antIcon} tip="Loading" />
      </div>
    );
  }

  return (
    <div className="AboutYouContainer">
      <div className="leftImageContainer">
        <p
          style={{
            color: "white",
            fontSize: "30px",
            fontWeight: "bold",
            textAlign: "center",
            width: "50%",
          }}
        >
          " {quote} "
        </p>
        <p style={{ color: "#979797", fontWeight: "bold", fontSize: "20px" }}>
          {author}
        </p>
      </div>
      <div className="RightDiv">
        <div className="RightDivTitle">
          <p className="RightDivTitleText">{t("get_started")}</p>
        </div>
        <div className="RightDivSc">
          <p className="RightDivScText">{t("provide_your_info")}</p>
        </div>
        <div className="InputsDiv">
          <div className="mealActivityDiv">
            <img src={buildMusclesIcon} alt="buildMusclesIcon" />
            <p style={{ color: "#fff" }}>{t("physical_activity")}</p>
            <Select
              style={{
                width: "100%",
                color: "#fff",
                border: "none",
                backgroundColor: "#161616",
                borderRadius: "8px",
                fontSize: "16px",
                fontWeight: "500",
                textAlign: "left",
                fontFamily: "Inter",
                textTransform: "capitalize",
              }}
              value={physicalFactor}
              suffixIcon={<img src={arrowIcon} alt="arrow icon" />}
              bordered={false}
              // placeholder={"Very Light"}
              options={[
                {
                  value: 0,
                  label: "Very Light",
                },
                {
                  value: 1,
                  label: "Light",
                },
                {
                  value: 2,
                  label: "Moderate",
                },
                {
                  value: 3,
                  label: "Heavy",
                },
                {
                  value: 4,
                  label: "Extreme",
                },
              ]}
              onChange={(x) => handlePhysicalChange(x)}
            ></Select>
            <p
              style={{
                fontSize: "12px",
                margin: "10px 0px 0px 0px",
                color: "grey",
                textAlign: "left",
              }}
            >
              {physicalStatment}
            </p>
          </div>
          <div className="physicalActivityDiv">
            <img
              src={FoodIcon}
              style={{
                width: "6%",
                filter:
                  " brightness(0) saturate(100%) invert(38%) sepia(30%) saturate(3142%) hue-rotate(338deg) brightness(94%) contrast(96%)",
              }}
              alt="FoodIcon"
            />
            <p style={{ color: "#fff" }}>{t("meal_frequency")}</p>
            <Select
              style={{
                width: "100%",
                color: "#fff",
                border: "none",
                backgroundColor: "#161616",
                borderRadius: "8px",
                fontSize: "16px",
                fontWeight: "500",
                textAlign: "left",
                fontFamily: "Inter",
              }}
              value={meal}
              bordered={false}
              suffixIcon={<img src={arrowIcon} alt="arrow icon" />}
              // placeholder={`${goalData.body_types[0].name}`}
              // defaultValue={goalData.body_types[0].name}
              onChange={(x) => handleChange(x)}
            >
              {meal_menu}
            </Select>

            {/* <Dropdown>
            <p style={{ color: "#fff" }}>{activity}</p>
          </Dropdown> */}
            <p
              style={{
                color: "grey",
                fontSize: "12px",
                margin: "10px 0px 0px 0px",
              }}
            >
              {mealStatment}
            </p>
          </div>
          <div className="GoalTitle">
            <p className="GoalTitleText">Specify Your Goal</p>
          </div>
          <div className="GoalDiv">
            <div
              className="GoalCard"
              onClick={() => setgoal(2)}
              style={{
                border: goal === 2 ? "2px solid #FF5858" : "none",
                cursor: "pointer",
              }}
            >
              <img src={looseWeightIcon} alt="boot" />
              <p className="GoalCardText">{t("lose_weight")}</p>
            </div>
            <div
              className="GoalCard"
              onClick={() => setgoal(1)}
              style={{
                border: goal === 1 ? "2px solid #FF5858" : "none",
                cursor: "pointer",
              }}
            >
              <img src={impFitnessIcon} alt="boot" />
              <p className="GoalCardText">{t("improve_fitness")}</p>
            </div>

            <div
              className="GoalCard"
              onClick={() => setgoal(3)}
              style={{
                border: goal === 3 ? "2px solid #FF5858" : "none",
                cursor: "pointer",
              }}
            >
              <img src={buildMusclesIcon} alt="boot" />
              <p className="GoalCardText">{t("build_muscles")}</p>
            </div>
          </div>

          <Button onClick={nutritionPlan} className="continueButton">
            {t("continue")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default LpGoal;
