import React, { useEffect, useState } from "react";
import {
  Modal,
  DatePicker,
  Input,
  Menu,
  Dropdown,
  Radio,
  Button,
  Spin,
} from "antd";
import "../styles/nutrition.css";
import { LoadingOutlined } from "@ant-design/icons";
import moment from "moment";
import { getAboutyou } from "../api/requests";
import { useNavigate } from "react-router-dom";

const NutritionAboutYou = () => {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [height, setHeight] = useState(0);
  const [weight, setWeight] = useState(0);
  const [weightType, setWeightType] = useState("kg");
  const [weightGram, setWeightGram] = useState();
  const [targetWeightGram, setTargetWeightGram] = useState();
  const [heightType, setHeightType] = useState("ft:in");
  const [heightInches, setHeightInches] = useState(0);
  const [heightInCm, setHeightInCm] = useState(0);
  const [weightInLb, setWeightInLb] = useState(0);
  const [targetWeightInLb, setTargetWeightInLb] = useState();
  const [targetWeight, setTargetWeight] = useState(0);
  const [gender, setGender] = useState("male");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [disable, setDisable] = useState(false);

  const [dob, setDob] = useState(moment().format("DD-MM-YYYY"));
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const value = localStorage.getItem("jwt");
    const token = JSON.parse(value);
    getAboutyou(token).then((response) => {
      console.log("response is x :::", response);
      if (response.status === 200) {
        if (
          response.data.gender === null ||
          response.data.gender === undefined
        ) {
          setGender("male");
        } else {
          setGender(response.data.gender);
        }
        if (
          response.data.weight_unit === null ||
          response.data.weight_unit === undefined
        ) {
          setWeightType("kg");
        } else {
          setWeightType(response.data.weight_unit);
        }
        if (
          response.data.height_unit === null ||
          response.data.weight_unit === undefined
        ) {
          setHeightType("ft");
        } else {
          setHeightType(response.data.height_unit);
        }
        if (
          response.data.dob === "00-00-0000" ||
          response.data.dob === null ||
          response.data.dob === undefined
        ) {
          setDob(moment().format("DD-MM-YYYY"));
        } else {
          setDob(response.data.dob);
        }
        if (
          response.data.weight_unit === "kg" ||
          response.data.weight_unit === "lb"
        ) {
          if (
            response.data.weight_kilo === null ||
            response.data.weight_kilo === undefined
          ) {
            setWeight(63.0);
          } else {
            if (
              response.data.weight_gram === undefined ||
              response.data.weight_gram === null
            ) {
              setWeight(`${response.data.weight_kilo}.0`);
              setTargetWeight(`${response.data.weight_kilo}.0`);
            } else {
              setWeight(
                `${response.data.weight_kilo}.${response.data.weight_gram}`
              );
              setTargetWeight(
                `${response.data.weight_kilo}.${response.data.weight_gram}`
              );
            }
          }
        }
        if (
          response.data.height_unit === "ft:in" ||
          response.data.height_unit === "feet" ||
          response.data.height_unit === "ft" ||
          response.data.height_unit === "inches" ||
          response.data.height_unit === "cm"
        ) {
          if (
            response.data.height_feet === null ||
            response.data.height_feet === undefined
          ) {
            setHeight(5.4);
          } else {
            if (
              response.data.height_inch === undefined ||
              response.data.height_inch === null
            ) {
              setHeight(`${response.data.height_feet}.0`);
            } else {
              setHeight(
                `${response.data.height_feet}.${response.data.height_inch}`
              );
            }

            // console.log("height is sss :::", height);
          }
        }
        setLoading(false);
        setWeightGram(response.data.weight_gram);
        setTargetWeightGram(response.data.weight_gram);
        setHeightInches(response.data.height_inch);
        setWeightInLb(response.data.weight_kilo * 2.204);
        setTargetWeightInLb(response.data.weight_kilo * 2.204);
        setHeightInCm(response.data.height_feet * 30);
      }
    });
  }, []);
  const data = {
    dob: moment(dob).format("YYYY-MM-DD"),
    weight: parseInt(weight),
    weightinlb: parseInt(weightInLb),
    height: parseInt(height),
    heightInCm: parseInt(heightInCm),
    targetWeight: parseInt(targetWeight),
    gender: gender,
    heightinInches: parseInt(heightInches),
    weighttype: weightType,
    heightType: heightType,
    weightInGram: parseInt(weightGram),
  };
  // console.log("weight in kg is :::", parseInt(weight));
  // console.log("weight in lb is :::", parseInt(weightInLb));
  // console.log("height in feet is :::", parseInt(height));
  // console.log("height in cm is :::", parseInt(heightInCm));

  const antIcon = (
    <LoadingOutlined
      style={{ fontSize: 45, color: "#FF1E1E", marginBottom: 20 }}
      spin
    />
  );

  if (loading) {
    return (
      <div class="loader">
        <Spin className="spinner" indicator={antIcon} tip="Loading" />
      </div>
    );
  }

  const openMethod = () => {
    setIsModalOpen(false);
    navigate("/nutritionActivityModal", {
      state: {
        data: data,
      },
    });

    console.log("height is :::", height);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    navigate("/home");
  };

  // Input setter Functions
  // console.log("height in ft is :::", height);

  const setDateOfBirth = (e) => {
    // console.log("e is :::", e);
    setDob(e);
    //console.log("Date of Birth is :::", e);
  };
  const setHeightTypeValue = (e) => {
    setHeightType(e.key);
    //console.log("Height type is :::", heightType);
  };
  const setWeightTypeValue = (e) => {
    setWeightType(e.key);
    // console.log("Weight type is :::", weightType);
  };

  const setWeightValue = (e) => {
    if (weightType === "kg") {
      setWeight(e.target.value);
      let gram = JSON.stringify(e.target.value).split(".");
      setWeightGram(parseFloat(gram[1]).toFixed(2));
      setWeightInLb(parseFloat(e.target.value * 2.2046).toFixed(2));
    } else {
      if (weightType === "lb") {
        setWeightInLb(e.target.value);
        setWeight(parseFloat(e.target.value / 2.2046).toFixed(2));
        let gram = JSON.stringify(e.target.value).split(".");
        setWeightGram(parseFloat(gram[1]).toFixed(2));
      }
    }
  };
  const setHeightValue = (e) => {
    console.log("e.target.value is ", e.target.value);
    if (heightType === "ft:in") {
      setHeight(e.target.value);
      let inches = JSON.stringify(e.target.value).split(".");
      setHeightInches(inches[1]);
      //console.log("inches is :::", heightInches);
    } else {
      if (heightType === "cm") {
        setHeightInCm(e.target.value);
        setHeight(e.target.value / 30);
        let inches = JSON.stringify(e.target.value).split(".");
        setHeightInches(inches[1]);
      }
    }
  };
  const setTargetWeightValue = (e) => {
    if (weightType === "kg") {
      setTargetWeight(e.target.value);
      let gram = JSON.stringify(e.target.value).split(".");
      setTargetWeightGram(parseFloat(gram[1]).toFixed(2));
      setTargetWeightInLb(parseFloat(e.target.value * 2.2406).toFixed(2));
    } else {
      if (weightType === "lb") {
        setTargetWeightInLb(e.target.value);
        setTargetWeight(parseFloat(e.target.value / 2.2046).toFixed(2));
        let gram = JSON.stringify(e.target.value).split(".");
        setTargetWeightGram(parseFloat(gram[1]).toFixed(2));
      }
    }
  };
  const setGenderValue = (e) => {
    setGender(e.target.value);
  };
  const checkTargetWeight = (e) => {
    console.log("weight addition is :::", parseInt(weight) + 5);
    if (weightType === "kg") {
      if (
        e.target.value < parseInt(weight) - 5 ||
        e.target.value > parseInt(weight) + 5.0
      ) {
        setError(
          `Enter Your Target Weight Between ${parseInt(weight) - 5.0} and ${
            parseInt(weight) + 5
          }`
        );
        setDisable(true);
      } else {
        setError("");
        setDisable(false);
      }
    } else {
      setError("");
      if (
        e.target.value < parseInt(weightInLb) - 5 ||
        e.target.value > parseInt(weightInLb) + 5.0
      ) {
        setError(
          `Enter Your Target Weight Between ${parseInt(weightInLb) - 5.0} and ${
            parseInt(weightInLb) + 5
          }`
        );
        setDisable(true);
      } else {
        setError("");
        setDisable(false);
      }
    }
  };

  console.log("height is :::", height);

  const menu = (
    <Menu>
      <Menu.Item onClick={(e) => setWeightTypeValue(e)} key="kg">
        Kg
      </Menu.Item>
      <Menu.Item onClick={(e) => setWeightTypeValue(e)} key="lb">
        Lb
      </Menu.Item>
    </Menu>
  );
  const height_menu = (
    <Menu>
      <Menu.Item onClick={setHeightTypeValue} key="ft:in">
        ft:in
      </Menu.Item>
      <Menu.Item onClick={setHeightTypeValue} key="cm">
        cm
      </Menu.Item>
    </Menu>
  );

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100vh",
        background: "black",
      }}
    >
      <Modal
        className="NutritionAboutYouModal"
        title="Personal Information"
        visible={isModalOpen}
        closable
        onCancel={closeModal}
        footer={false}
      >
        <div className="NutritionAboutYou">
          <div style={{ display: "flex", flexDirection: "column" }}>
            <p style={{ margin: "0", color: "#ffffff" }}>Gender</p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Radio.Group
                onChange={(e) => {
                  setGenderValue(e);
                }}
                value={gender}
              >
                <Radio
                  style={{ color: "white", marginBottom: "6px" }}
                  value={"male"}
                >
                  Male
                </Radio>
                <Radio
                  style={{ color: "white", marginBottom: "6px" }}
                  value={"female"}
                >
                  Female
                </Radio>
                <Radio
                  style={{ color: "white", marginBottom: "6px" }}
                  value={"other"}
                >
                  Others
                </Radio>
              </Radio.Group>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              width: "100%",
            }}
          >
            <p style={{ color: "#ffffff" }}>Date Of Birth</p>
            <DatePicker
              suffixIcon={false}
              value={moment(dob)}
              style={{
                background: "linear-gradient(180deg, #383838 0%, #2B2B2B 100%)",
                borderRadius: "10px",
                padding: "10px",
                border: "0",
                width: "70%",
              }}
              onChange={(e) => setDateOfBirth(e)}
              format={"DD-MM-YYYY"}
              // value={}
              allowClear={false}
            />
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <p style={{ margin: "5px", color: "#FFFFFF" }}>Height</p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "40%",
                justifyContent: "space-between",
              }}
            >
              <Input
                placeholder={heightType === "cm" ? heightInCm : height}
                onChange={(e) => {
                  setHeightValue(e);
                }}
                style={{
                  background:
                    "linear-gradient(180deg, #383838 0%, #2B2B2B 100%)",
                  borderRadius: "10px 0px 0px 10px",
                  padding: "10px",
                  border: "0",
                  width: "40%",
                  color: "white",
                }}
                inputMode="numeric"
              />
              <Dropdown overlay={height_menu}>
                <a
                  onClick={(e) => e.preventDefault()}
                  style={{
                    width: "30%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    background:
                      "linear-gradient(180deg, #383838 0%, #2B2B2B 100%)",
                    color: "white",
                    borderRadius: "0px 10px 10px 0px",
                    marginRight: "50px",
                    padding: "10px",
                  }}
                >
                  {heightType}
                </a>
              </Dropdown>
            </div>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <p style={{ margin: "5px", color: "#FFFFFF" }}>Weight</p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "40%",
                justifyContent: "space-between",
              }}
            >
              <Input
                value={weightType === "kg" ? weight : weightInLb}
                onChange={(e) => {
                  setWeightValue(e);
                }}
                style={{
                  background:
                    "linear-gradient(180deg, #383838 0%, #2B2B2B 100%)",
                  borderRadius: "10px 0px 0px 10px",
                  padding: "10px",
                  border: "0",
                  width: "40%",
                  color: "white",
                }}
              />
              <Dropdown overlay={menu}>
                <a
                  onClick={(e) => e.preventDefault()}
                  style={{
                    width: "30%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    background:
                      "linear-gradient(180deg, #383838 0%, #2B2B2B 100%)",
                    color: "white",
                    borderRadius: "0px 10px 10px 0px",
                    marginRight: "50px",
                    padding: "10px",
                  }}
                >
                  {weightType}
                </a>
              </Dropdown>
            </div>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <p style={{ margin: "5px", color: "#FFFFFF" }}>Target Weight</p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Input
                value={weightType === "kg" ? targetWeight : targetWeightInLb}
                onKeyUp={(e) => {
                  checkTargetWeight(e);
                }}
                onChange={(e) => {
                  setTargetWeightValue(e);
                }}
                style={{
                  background:
                    "linear-gradient(180deg, #383838 0%, #2B2B2B 100%)",
                  borderRadius: "10px 0px 0px 10px",
                  padding: "10px",
                  border: "0",
                  width: "18%",
                  color: "white",
                  marginRight: "1.4%",
                }}
              />
              <Dropdown overlay={menu}>
                <a
                  onClick={(e) => e.preventDefault()}
                  style={{
                    width: "14%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    background:
                      "linear-gradient(180deg, #383838 0%, #2B2B2B 100%)",
                    color: "white",
                    borderRadius: "0px 10px 10px 0px",
                    marginRight: "50px",
                    padding: "10px",
                  }}
                >
                  {weightType}
                </a>
              </Dropdown>
              <p style={{ color: "red", width: "70%" }}>{error}</p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              alignItems: "center",
              width: "100%",
              padding: "15px",
            }}
          >
            <Button
              style={{
                width: "40%",
                borderRadius: "10px",
                background: "#282828",
                color: "white",
                border: "0",
                height: "7vh",
              }}
              onClick={closeModal}
            >
              Cancel
            </Button>
            <Button
              style={{
                width: "40%",
                borderRadius: "10px",
                background: "linear-gradient(180deg, #FF5858 0%, #FF5B37 100%)",
                color: "white",
                border: "0",
                height: "7vh",
              }}
              onClick={openMethod}
              disabled={disable}
            >
              Confirm
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default NutritionAboutYou;
