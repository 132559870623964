import React, { useState } from "react";
import Check from "../assets/images/check.svg";
import "../styles/marketCss.css";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import "../styles/homescreen.css";
import { DownCircleFilled, UpCircleFilled } from "@ant-design/icons";

const SetPlan = (props) => {
  const { t, i18n } = useTranslation();
  const [collapse, setCollapse] = useState(true);
  let { operator, country } = useParams();

  console.log("rate is :::", props.rate);

  console.log("I18n: ", i18n.language);

  return (
    <div className="Bgcard">
      <p className="type">{props.type}</p>
      <span className="Stype">
        <p className="country">
          {country === "pk" ? (
            <p>Only for {operator} Users</p>
          ) : country === "iq" ? (
            <p>
              {i18n.language === "ar"
                ? "أدخل رقم زين هاتفك المحمول"
                : "Enter your Zain mobile number"}
            </p>
          ) : country === "id" ? (
            <p>{operator} Indonesia</p>
          ) : (
            <p>{operator}</p>
          )}
        </p>
      </span>

      {country === "pk" && (
        <p className="content-text1">
          {props.rate} <p className="Stype">{props.duration}</p>
        </p>
      )}

      {(props.countryName !== undefined && props.countryName === "QA") ||
        props.countryName === "pk" ||
        props.countryName === "KSA" ||
        (props.countryName === "SA" && (
          <p className="content-text1">
            {props.rate}{" "}
            {props.countryName !== "SA" && (
              <p className="Stype">{props.duration}</p>
            )}
          </p>
        ))}

      {props.countryName !== "SA" && (
        <p className="paragraph">
          {country === "iq"
            ? i18n.language === "ar"
              ? "لا يوجد استقطاع اثناء الفترة المجانية"
              : "There is no deduction during the free period"
            : props.description}
        </p>
      )}
      <span
        onClick={() => setCollapse(!collapse)}
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: 5,
        }}
      >
        <p className="Stype">{t("terms_and_conditions")}</p>
        {collapse ? (
          <UpCircleFilled className="dropdown-arrow" />
        ) : (
          <DownCircleFilled className="dropdown-arrow" />
        )}
      </span>
      {props.term.map((item, index) => {
        return (
          <div
            key={index}
            className="check-list"
            style={{
              display: collapse && "flex",
              flexDirection: i18n.language === "ar" ? "row-reverse" : "row",
            }}
          >
            <img
              src={Check}
              style={{ verticalAlign: "middle", width: 18, height: 18 }}
            />
            <p
              style={{
                color: "white",
                width: i18n.language === "ar" ? "auto" : "95%",
              }}
            >
              {item}
            </p>
          </div>
        );
      })}
      {country !== undefined
        ? (country === "iq" || country === "id") && (
            <p
              style={{
                textTransform: "capitalize",
                fontWeight: "600",
                fontSize: 8,
              }}
            >
              ** {props.rate} {props.duration}
            </p>
          )
        : props.countryName !== undefined &&
          (props.countryName === "iq" || props.countryName === "id") && (
            <p
              style={{
                textTransform: "capitalize",
                fontWeight: "600",
                fontSize: 8,
              }}
            >
              ** {props.rate} {props.duration}
            </p>
          )}
    </div>
  );
};

export default SetPlan;
