import React from "react";
import "../styles/homescreen.css";
import SiteHeader from "../components/header";
import MiniHeader from "../components/miniHeader";
import Footer from "../components/footer";

const DeletionPolicy = () => {
  return (
    <div className="container-div">
      <div style={{ marginBottom: "50px" }}>
        <SiteHeader />
      </div>
      <MiniHeader name={"Deletion Policy"} />
      <div
        style={{
          justifyContent: "flex-start",
          alignItems: "flex-start",
          display: "flex",
          flexDirection: "column",
          padding: 10,
          color: "#000",
          marginTop: "50px",
          marginBottom: "50px",
          width: "90%",
          justifySelf: "center",
          alignSelf: "center",
          borderBottom: "1px solid #888888",
        }}
      >
        {/* <p style={{ textAlign: "left" }}> Terms of Use</p> */}
        <p style={{ textAlign: "left", fontWeight: 900 }}>
          {" "}
          Account Deletion Policy{" "}
        </p>
        {/* <p style={{ textAlign: "left" }}> We have no refund policy</p> */}
        <p style={{ textAlign: "left" }}>
          At FITFLEX we understand that sometimes users may wish to delete their
          accounts for various reasons. This policy outlines the steps and
          procedures for users to delete their accounts on our platform. Please
          note, It is not possible to delete your account partially.
        </p>
        {/* <h4 style={{ textAlign: "left", fontWeight: "700" }}>
          1. Terms of Use
        </h4> */}
        <ul>
          <li style={{ textAlign: "left", fontFamily: "Inter" }}>
            Data Collection and Usage
          </li>
          <li style={{ textAlign: "left", fontFamily: "Inter" }}>
            To provide and maintain our Service
          </li>
          <li style={{ textAlign: "left", fontFamily: "Inter" }}>
            To allow you to participate in interactive features of our Service
            when you choose to do so
          </li>
          <li style={{ textAlign: "left", fontFamily: "Inter" }}>
            To provide customer support
          </li>
          <li style={{ textAlign: "left", fontFamily: "Inter" }}>
            To gather analysis or valuable information so that we can improve
            our Service
          </li>
          <li style={{ textAlign: "left", fontFamily: "Inter" }}>
            To monitor the usage of our Service
          </li>
          <li style={{ textAlign: "left", fontFamily: "Inter" }}>
            To notify you about changes to our Service
          </li>
          <li style={{ textAlign: "left", fontFamily: "Inter" }}>
            To detect, prevent and address technical issues
          </li>
          <li style={{ textAlign: "left", fontFamily: "Inter" }}>
            To provide you with news, offers and general information about other
            goods, services and events which we offer that are similar to those
            that you have already purchased or enquired about unless you have
            opted not to receive such information
          </li>
          <li style={{ textAlign: "left", fontFamily: "Inter" }}>
            If you do not comply with these requirements you cannot register for
            or use FITFLEX. You may only register for and hold one account.
          </li>
        </ul>
        <h4 style={{ textAlign: "left", fontWeight: "700" }}>
          2. Account Deletion Request
        </h4>
        <ul style={{ listStyleType: "lower-alpha" }}>
          <li style={{ textAlign: "left", fontFamily: "Inter" }}>
            Users can initiate an account deletion request by following these
            steps:
          </li>
          <li style={{ textAlign: "left", fontFamily: "Inter" }}>
            Log in to your FITFLEX account.
          </li>
          <li style={{ textAlign: "left", fontFamily: "Inter" }}>
            Navigate to the settings and in the bottom section user will be able
            to see “Delete Profile” option.
          </li>
          <li style={{ textAlign: "left", fontFamily: "Inter" }}>
            Follow the prompts to initiate the deletion process.
          </li>{" "}
        </ul>
        <h4 style={{ textAlign: "left", fontWeight: "700" }}>
          3. Data Removal
        </h4>
        <p style={{ textAlign: "left" }}>
          Upon successful deletion of the account, all personal information,
          including user data, activity logs, and other identifiable information
          associated with the account, will be permanently removed from our
          system.
        </p>
        <ul>
          <li style={{ textAlign: "left", fontFamily: "Inter" }}>
            Personal details (mobile number, DOB, height, weight, gender etc.)
          </li>{" "}
          <li style={{ textAlign: "left", fontFamily: "Inter" }}>
            Activity logs, workout history, and fitness data
          </li>
          <li style={{ textAlign: "left", fontFamily: "Inter" }}>
            Any user-generated content (posts, comments, etc.)
          </li>{" "}
          <li style={{ textAlign: "left", fontFamily: "Inter" }}>
            Preferences and settings{" "}
          </li>
          <li style={{ textAlign: "left", fontFamily: "Inter" }}>
            Payment related Data{" "}
          </li>
        </ul>
        <p style={{ textAlign: "left" }}>
          However, please note that aggregated and anonymized data may be
          retained for analytical purposes in compliance with our privacy
          policy.
        </p>
        <h4 style={{ textAlign: "left", fontWeight: "700" }}>
          4. Cancellation of Subscriptions
        </h4>
        <p style={{ textAlign: "left" }}>
          For users with active subscriptions or premium memberships, the
          deletion of the account will also include the cancellation of any
          recurring payments or subscriptions linked to the account. Users will
          receive a confirmation of subscription cancellation along with the
          account deletion confirmation.
        </p>
        <h4 style={{ textAlign: "left", fontWeight: "700" }}>
          5. Contacting Support
        </h4>
        <p style={{ textAlign: "left" }}>
          If users encounter any issues or have questions regarding the account
          deletion process, they can reach out to our customer support team at
          customer.support@fitflexapp.com. Our support team will assist in
          addressing any concerns related to the deletion of accounts.
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default DeletionPolicy;
