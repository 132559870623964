import React, { useState, useEffect } from "react";
import "../styles/nutrition.css";
import SiteHeader from "../components/header";
import { Spin, notification } from "antd";
import { LoadingOutlined, HeartFilled, HeartOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getSingleNutrition,
  getAllMeals,
  addFavNutrition,
  removeFavNutrition,
} from "../api/requests";
const NutritionDetails = () => {
  const location = useLocation();
  const [nutritionData, setNutritionData] = useState([]);
  const [allMealsData, setAllMealsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [favourite, setFavourite] = useState(null);
  // console.log("location data is :::", location.state.data);
  console.log("location meal name is :::", location.state.band);
  console.log("location title is :::", location.state.title);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    setNutritionData([]);
    let payload = {
      band: location.state.band,
      meal: location.state.title,
    };
    const jwt = localStorage.getItem("jwt");
    const token = JSON.parse(jwt);
    getSingleNutrition(location.state.id, token).then((response) => {
      console.log("response is :::", response);
      if (response.status === 200) {
        setNutritionData(response.data.dishes[0]);
        setFavourite(response.data.isfavourite);
      }
    });
    getAllMeals(token, payload).then((response) => {
      console.log("all meal response is :::", response);
      if (response.status === 200) {
        setAllMealsData(response.data);
        setLoading(false);
      }
    });
  }, [location.state.id]);
  // const text = nutritionData.instruction.replace("\n,<br/>");
  // console.log("text is :::", text);
  const antIcon = (
    <LoadingOutlined
      style={{ fontSize: 45, color: "#FF1E1E", marginBottom: 20 }}
      spin
    />
  );
  if (loading) {
    return (
      <div class="loader">
        <Spin className="spinner" indicator={antIcon} tip="Loading" />
      </div>
    );
  }

  const nutritionFavourite = (meal_name) => {
    let payload = {
      meal_name: meal_name,
      meal_id: location.state.id,
    };
    let jwt = localStorage.getItem("jwt");
    let token = JSON.parse(jwt);
    // console.log("payload is :::", payload);
    if (favourite === null) {
      addFavNutrition(token, payload).then((response) => {
        console.log("fav response is :::", response);
        setFavourite(true);
        if (response.status === 200) {
          notification.success({
            message: "Success",
            style: {
              zndex: 1,
            },
            description: "Meal added from Favourites",
            onClick: () => {
              console.log("Notification Clicked!");
            },
          });
        }
      });
    } else {
      removeFavNutrition(token, payload).then((response) => {
        console.log("remove response is :::", response);
        if (response.status === 200) {
          setFavourite(null);
          notification.success({
            message: "Success",
            style: {
              zndex: 1,
            },
            description: "Meal remove from Favourites",
            onClick: () => {
              console.log("Notification Clicked!");
            },
          });
        }
      });
    }
  };

  return (
    <div className="nutritionContainerDiv">
      <div style={{ marginBottom: "50px" }}>
        <SiteHeader />
      </div>
      <div style={{ background: "black" }}>
        {/* <img src={blog_img} className="banner-image" /> */}
        <p className="MealNameText">
          {" "}
          {location.state.title} {">"} {nutritionData.name}
        </p>
      </div>
      <div className="nutritionDiv">
        <div className="nutritionDetailsDiv">
          <div className="DishDetailsDiv">
            <div className="DishImageAndNameDiv">
              {/* <div>
                <h1
                  style={{
                    color: "white",
                    // fontFamily: "'SF Pro Display', sans-serif",
                  }}
                >
                  {location.state.title}
                </h1>
              </div> */}
              <img
                className="dishImage"
                src={nutritionData.dish_landscape_image}
                // style={{ width: "60%" }}
                alt="dishImage"
              />
              {/* <div className="DishNameAndDescriptionDiv">
                <p
                  style={{
                    color: "white",
                    fontSize: "25px",
                    fontWeight: "700",
                    marginBottom: "5px",
                  }}
                >
                  {nutritionData.name}
                </p>
                
                </div>
              </div> */}
              <div className="IngredientsDescriptionDiv">
                <div className="caloriesDiv">
                  <div className="aminoAcidsDiv">
                    <div
                      style={{
                        border: "2px solid red",
                        width: "20%",
                        marginBottom: "10px",
                      }}
                    ></div>
                    <p className="AminoAcidsText">Calories</p>
                    <h1 className="AminoAcidsNumberText">
                      {nutritionData.calories}
                    </h1>
                  </div>
                  <div className="aminoAcidsDiv">
                    <div
                      style={{
                        border: "2px solid red",
                        width: "20%",
                        marginBottom: "10px",
                      }}
                    ></div>
                    <p className="AminoAcidsText">Proteins</p>
                    <h1 className="AminoAcidsNumberText">
                      {nutritionData.proteins}g
                    </h1>
                  </div>
                  <div className="aminoAcidsDiv">
                    <div
                      style={{
                        border: "2px solid red",
                        width: "20%",
                        marginBottom: "10px",
                      }}
                    ></div>
                    <p className="AminoAcidsText">Carbs</p>
                    <h1 className="AminoAcidsNumberText">
                      {nutritionData.carbs}g
                    </h1>
                  </div>
                  <div className="aminoAcidsDiv">
                    <div
                      style={{
                        border: "2px solid red",
                        width: "20%",
                        marginBottom: "10px",
                      }}
                    ></div>
                    <p className="AminoAcidsText">Fat</p>
                    <h1 className="AminoAcidsNumberText">
                      {nutritionData.fats}g
                    </h1>
                  </div>
                  <div className="circular-btns-div">
                    <div
                      className={
                        favourite !== null
                          ? "circular-div-colored"
                          : "circular-div"
                      }
                      style={{ background: favourite === null && "#000" }}
                      onClick={() => {
                        // console.log(location.state.meal);
                        nutritionFavourite(location.state.title);
                      }}
                    >
                      <HeartFilled
                        style={{
                          fontSize: "20px",
                          marginTop: "17px",
                          color: "#fff",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <h1 className="IngredientHeadingText">Ingredients</h1>
                <div className="verticalLineDiv"></div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    overflowY: "scroll",
                    height: "200px",
                  }}
                >
                  {nutritionData.ingredients?.map((item, id) => {
                    return (
                      <div className="IngredientsNameAndQuantityDiv">
                        <p className="IngredientNameText">{item.name}</p>
                        <p className="IngredientQuantityText">
                          {item.pivot.quantity + " " + item.pivot.unit}
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className="InstructionsDiv">
              <h1 className="InstructionHeadingText">Instructions</h1>

              <p className="InstructionDetailsText">
                {nutritionData.instruction}
              </p>
            </div>
            <div className="InstructionsDiv">
              <h1 className="InstructionHeadingText">
                Volumetric Measurements
              </h1>

              <p className="InstructionDetailsText">
                For better results measure the ingredients carefully.
                <ul>
                  {" "}
                  <li>1 Cup, Glass, Mug or Can = 250 gram or 240 mL</li>
                  <li> 1 Teaspoon (tsp) = 5 gram or 5 mL </li>{" "}
                  <li> 1 Tablespoon (tbsp) = 15 gram or 15 mL </li>{" "}
                  <li>1 Cube = 10 gram 1 Piece = Medium Sized Entity</li>{" "}
                  <li> 1 Slice = 28 gram 1 Scoop = 29 gram</li>
                </ul>
              </p>
            </div>
            <div className="IngredientsDiv">
              <div className="MoreMealsDiv">
                <h1 className="ViewMoreMealText">View More Meals</h1>
                <div className="MoreMeals">
                  {allMealsData?.slice(0, 4).map((item, index) => {
                    return (
                      <div
                        className="mealsImgAndNameDiv"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          console.log("clicked");
                          navigate("/nutritiondetails", {
                            state: {
                              title: item.meal,
                              id: item.id,
                              band: item.band_first,
                            },
                          });
                        }}
                      >
                        <div
                          style={{
                            width: "200px",
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                        >
                          <img
                            src={item.dishes[0].dish_landscape_image}
                            className="mealsImg"
                            alt="mealsImg"
                            // style={{ width: "280px", marginBottom: "20px" }}
                          />
                        </div>
                        <p
                          style={{
                            color: "white",
                            textAlign: "left",
                            width: "70%",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",

                            // fontFamily: "'SF Pro Display', sans-serif",
                          }}
                        >
                          {item.dishes[0].name}
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default NutritionDetails;
