import React, { useEffect, useState } from "react";
import moment from "moment";
import SiteHeader from "../components/header";
import { Spin, notification } from "antd";
import {
  LoadingOutlined,
  DoubleRightOutlined,
  HeartFilled,
} from "@ant-design/icons";
import {
  addFavNutrition,
  nutritionPlan,
  removeFavNutrition,
} from "../api/requests";
import "../styles/NewNutrition.css";
import { useNavigate } from "react-router-dom";
import CalIcon from "../assets/images/calIcon.svg";
import CalendarIcon from "../assets/images/calendarIcon.png";
import FoodIcon from "../assets/images/foodIcon.svg";
import { useTranslation } from "react-i18next";

const NutritionScreen = () => {
  const [nutritionData, setNutritionData] = useState();
  const [dateIndex, setDateIndex] = useState(0);
  const [toggleDate, setToggleDate] = useState(true);
  const [selectedDate, setSelectedDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [loading, setLoading] = useState(true);
  const [favourite, setFavourite] = useState(false);
  const [filter, setFilter] = useState([]);
  const navigate = useNavigate();
  const [favindex, setFavIndex] = useState();
  const [markedDate, setMarkedDate] = useState();
  const { t } = useTranslation();

  const filterMeals = (date, index) => {
    // console.log("date is :::", date, index);
    setSelectedDate(date);
    setDateIndex(index);

    console.log("nutrition data is :::", nutritionData);

    const filteredData = nutritionData.meals.reduce((acc, meal) => {
      const top = meal.content.Top;
      if (top && top.length > 0) {
        top.forEach((t) => {
          if (t.date === date) {
            // Replace 'desired_date' with the date you want to filter by
            const filteredObj = {
              data: t.data,
              Viewmore: meal.content.view_more,
              isFav: false,
            };
            if (
              markedDates(nutritionData.meals).find((d) => d.date === t.date)
            ) {
              filteredObj.isFav = true;
            }
            acc.push(filteredObj);
          }
        });
      }
      return acc;
    }, []);
    setFilter(filteredData);
  };

  useEffect(() => {
    const getNutritionData = async () => {
      const token = JSON.parse(localStorage.getItem("jwt"));
      await nutritionPlan(token).then((response) => {
        if (response.status === 200) {
          setNutritionData(response.data);
          let x = markedDates(response.data.meals[0].content.Top);
          setMarkedDate(x);
          console.log("response is :::", response);
          setLoading(false);
        }
      });
    };
    getNutritionData();
  }, [favourite]);

  useEffect(() => {
    const today = moment(new Date()).format("YYYY-MM-DD");
    if (nutritionData && selectedDate === today) {
      filterMeals(today, 0);
    } else {
      if (nutritionData && dateIndex !== undefined) {
        filterMeals(selectedDate, dateIndex);
      }
    }
  }, [nutritionData]);

  function markedDates(array) {
    const obj = array.map((x) => ({
      ["date"]: moment(x.date),
    }));

    return obj;
  }

  // const dates = [];
  // for (let date = startDate; date <= endDate; date.add(1, "days")) {
  //   dates.push(moment(new Date(date)));
  // }
  // console.log("dates are :::", dates);

  console.log("marked dates are ::::", markedDate);

  const nutritionFavourite = (meal_name, id, fav) => {
    let payload = {
      meal_name: meal_name,
      meal_id: id,
    };
    let jwt = localStorage.getItem("jwt");
    let token = JSON.parse(jwt);
    // console.log("payload is :::", payload);
    if (!fav) {
      setFavIndex(id);
      addFavNutrition(token, payload).then((response) => {
        console.log("fav response is :::", response);
        if (response.status === 200) {
          setFavourite(!favourite);

          notification.success({
            message: "Success",
            style: {
              zndex: 1,
            },
            description: "Meal added from Favourites",
            onClick: () => {
              console.log("Notification Clicked!");
            },
          });

          // setFavourite(true);
        }
      });
    } else {
      setFavIndex(false);
      const data = {
        meal_id: id,
      };
      removeFavNutrition(token, data).then((response) => {
        console.log("remove response is :::", response);
        if (response.status === 200) {
          setFavourite(!favourite);
          notification.success({
            message: "Success",
            style: {
              zndex: 1,
            },
            description: "Meal remove from Favourites",
            onClick: () => {
              console.log("Notification Clicked!");
            },
          });

          // setFavourite(false);
        }
      });
    }
  };

  const antIcon = (
    <LoadingOutlined
      style={{ fontSize: 45, color: "#FF1E1E", marginBottom: 20 }}
      spin
    />
  );
  if (loading) {
    return (
      <div class="loader">
        <Spin className="spinner" indicator={antIcon} tip="Loading" />
      </div>
    );
  }

  console.log("filters are :::", filter);

  return (
    <div className="mainContainerDiv">
      <div style={{ marginBottom: "60px" }}>
        <SiteHeader />
      </div>
      <div className="calenderAndMealsDiv">
        <div className="calenderDiv">
          <div className="CaloriesAndDateDiv">
            <img
              src={CalIcon}
              // style={{ width: "3%", marginRight: "15px" }}
              className="calorieImg"
              alt="calIcon"
            />{" "}
            <div className="Calories-Div">
              <p style={{ color: "white", margin: 0 }}>{t("daily_cal")}</p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p
                  style={{
                    color: "white",
                    fontWeight: "700",
                    marginBottom: "7px",
                    fontSize: "32px",
                  }}
                >
                  {nutritionData.total_calories}
                </p>
                <p
                  style={{
                    color: "white",
                    marginLeft: "5px",
                    fontSize: "15px",
                    marginBottom: "0px",
                    fontWeight: "500",
                  }}
                >
                  KCAL
                </p>
              </div>
            </div>
            <div className="DateDiv">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  justifyContent: "flex-start",
                  alignItems: "flex-end",
                  marginLeft: "20px",
                }}
              >
                <p style={{ color: "white", margin: 0 }}>{t("today")}</p>
                <h1 style={{ color: "white", margin: 2 }}>
                  {moment(new Date()).format("MMMM Do YYYY")}
                </h1>
              </div>
              <div
                style={{
                  borderRadius: "50%",
                  width: "7%",
                  padding: "20px",
                  background:
                    "linear-gradient(63.69deg, #eb3d30 28.36%, #ec6736 80.63%)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginLeft: "20px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setToggleDate(!toggleDate);
                }}
              >
                <img
                  src={CalendarIcon}
                  style={{
                    width: "100%",
                    filter:
                      "invert(100%) sepia(26%) saturate(940%) hue-rotate(195deg) brightness(109%) contrast(102%)",
                  }}
                  alt="calendarIcon"
                />
              </div>
            </div>
          </div>
          {toggleDate && (
            <div
              style={{
                display: "flex",
                width: "100%",
              }}
            >
              <hr className="horizontalLine"></hr>
            </div>
          )}
          {toggleDate && (
            <div className="weekListDiv">
              {markedDate?.map((item, index) => {
                return (
                  <div
                    className="singleDateDiv"
                    onClick={() =>
                      filterMeals(item.date.format("YYYY-MM-DD"), index)
                    }
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <p
                      style={{
                        color:
                          selectedDate === item.date.format("YYYY-MM-DD")
                            ? "white"
                            : "grey",
                        fontWeight: "800",
                        fontSize: "15px",
                      }}
                    >
                      {item.date.format("dddd").substring(0, 1)}
                    </p>
                    <p
                      style={{
                        color:
                          selectedDate === item.date.format("YYYY-MM-DD")
                            ? "white"
                            : "grey",
                        fontWeight: "800",
                        fontSize: "15px",
                        marginBottom: "2px",
                      }}
                    >
                      {item.date.format("D")}
                    </p>
                    {moment().format("YYYY-MM-DD") ===
                    item.date.format("YYYY-MM-DD") ? (
                      <div
                        style={{
                          height: 8,
                          width: 8,
                          borderRadius: 50,
                          backgroundColor: "#ff1e1e",
                          display: "flex",
                          marginTop: "5px",
                          // marginRight: "10px",
                        }}
                      ></div>
                    ) : null}
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <h1 className="recommendedMealsText">{t("recommended_meals")}</h1>
        <div className="MealsDiv">
          {filter.length !== 0 ? (
            filter?.map((item, index) => {
              return (
                <div className="mealCardDiv" index={index}>
                  <div className="titleAndViewMoreDiv">
                    <p
                      style={{
                        color: "white",
                        textAlign: "center",
                        margin: 0,
                        fontSize: "20px",
                      }}
                    >
                      {item.data.meal}
                    </p>
                    <p
                      onClick={() => {
                        navigate("/nutritionCategory", {
                          state: {
                            viewmore: item.Viewmore,
                            title: item.data.meal,
                          },
                        });
                      }}
                      style={{
                        color: "red",
                        marginBottom: "0px",
                        cursor: "pointer",
                      }}
                    >
                      {t("view_more")}
                      <DoubleRightOutlined />
                    </p>
                  </div>
                  <div
                    className="dishImageAndContentDiv"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      console.log("clicked");
                      navigate("/nutritiondetails", {
                        state: {
                          title: item.data.meal,
                          id: item.data.id,
                          band: item.data.band_first,
                        },
                      });
                    }}
                  >
                    <div>
                      <img
                        className="mealImg"
                        src={item.data.dishes[0].dish_landscape_image}
                        alt="breakfast"
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "90%",
                        padding: "10px 0px 10px 0px",
                      }}
                    >
                      <p
                        style={{
                          color: "white",
                          textAlign: "left",
                          cursor: "pointer",
                        }}
                        className="nutritionNameText"
                      >
                        {item.data.dishes[0].name}
                      </p>
                      <div
                        style={{ justifyContent: "flex-end", width: "25%" }}
                        className="circular-btns-div"
                      >
                        <div
                          // style={{ zIndex: 1 }}
                          className={
                            item.data.is_favourite !== null
                              ? "circular-div-colored"
                              : "circular-div"
                          }
                          style={{
                            background:
                              item.data.is_favourite === null && "#000",
                          }}
                          onClick={(event) => {
                            event.stopPropagation();
                            nutritionFavourite(
                              item.data.dishes[0].name,
                              item.data.id,
                              item.data.is_favourite
                            );
                          }}
                        >
                          <HeartFilled
                            style={{
                              fontSize: "20px",
                              marginTop: "17px",
                              color: "#fff",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="macroNutrientsDiv">
                      <div className="nameAndGramDiv">
                        <div
                          style={{
                            border: "2px solid red",
                            width: "20%",
                            marginBottom: "10px",
                          }}
                        ></div>
                        <p
                          style={{ color: "grey", margin: 0, fontSize: "10px" }}
                        >
                          Calories
                        </p>
                        <p style={{ color: "white", margin: 0 }}>
                          {item.data.dishes[0].calories} Kcal
                        </p>
                      </div>
                      <div className="nameAndGramDiv">
                        <div
                          style={{
                            border: "2px solid red",
                            width: "20%",
                            marginBottom: "10px",
                          }}
                        ></div>
                        <p
                          style={{ color: "grey", margin: 0, fontSize: "10px" }}
                        >
                          Proteins
                        </p>
                        <p style={{ color: "white", margin: 0 }}>
                          {item.data.dishes[0].proteins}g
                        </p>
                      </div>
                      <div className="nameAndGramDiv">
                        <div
                          style={{
                            border: "2px solid red",
                            width: "20%",
                            marginBottom: "10px",
                          }}
                        ></div>
                        <p
                          style={{ color: "grey", margin: 0, fontSize: "10px" }}
                        >
                          Carbs
                        </p>
                        <p style={{ color: "white", margin: 0 }}>
                          {item.data.dishes[0].carbs}g
                        </p>
                      </div>
                      <div className="nameAndGramDiv">
                        <div
                          style={{
                            border: "2px solid red",
                            width: "20%",
                            marginBottom: "10px",
                          }}
                        ></div>
                        <p
                          style={{ color: "grey", margin: 0, fontSize: "10px" }}
                        >
                          Fats
                        </p>
                        <p style={{ color: "white", margin: 0 }}>
                          {item.data.dishes[0].fats}g
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                // paddingBottom: "20vh",
              }}
            >
              <img
                src={FoodIcon}
                alt="foodIcon"
                style={{
                  width: "15%",
                  marginBottom: "20px",
                  filter:
                    "invert(100%) sepia(26%) saturate(940%) hue-rotate(195deg) brightness(109%) contrast(102%)",
                }}
              />
              <h1 style={{ color: "white" }}>No Meals on this Date</h1>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NutritionScreen;
