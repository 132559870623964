import "./App.css";
import "antd/dist/antd.css";
import { HashRouter, BrowserRouter } from "react-router-dom";
import MainRouter from "./routes/MainRouter";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";

function App() {
  const { i18n } = useTranslation();
  return (
    <BrowserRouter basename="/">
      <div
        className="App"
        style={{
          fontFamily:
            i18n.language === "ar"
              ? "'adobe-arabic', sans-serif"
              : "'Montserrat', sans-serif",
        }}
      >
        <MainRouter />
      </div>
    </BrowserRouter>
  );
}

// export default App;
export default Sentry.withProfiler(App);
