import React from "react";
import "../styles/homescreen.css";
import TrainingScroll from "../components/trainingPlansScroll";
import Carousel from "../components/carousel";
import HomeWorkout from "../components/homeWorkout";
import FavouritesScreen from "../components/favourites";
import FooterDark from "../components/darkFooter";
import ExerciseFavourites from "../components/ExerciseFavourites";
import FavouriteNutrition from "../components/FavouriteNutrition";

// import Career from "../components/career";

const MainHomeScreen = () => {
  return (
    <div style={{ background: "black", color: "white" }}>
      <Carousel />
      {/* <div className="training-plan-scroll-div"> */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "95%",
          justifyContent: "center",
          marginTop: "30px",
          marginLeft: "2.5%",
        }}
      >
        <TrainingScroll />

        <HomeWorkout />
        <FavouritesScreen />
        <ExerciseFavourites />
        <FavouriteNutrition />
      </div>
      <FooterDark />
    </div>
  );
};

export default MainHomeScreen;
