import React, { useState } from "react";
import "../styles/exercise.css";
import arrowUp from "../assets/images/arrowUp.svg";

const ExerciseCircuitCard = (props) => {
  const [show, setShow] = useState({ data: "", detail: "" });
  console.log("Exercise circuit data is", props.data);
  console.log(
    "exercise object keys etc blah blah blah ::",
    Object.keys(props.data)
  );
  return (
    <div
      className="circuits"
      style={{
        paddingBottom: show.data !== "" && show.detail !== null ? "40%" : null,
      }}
    >
      {Object.keys(props.data).map((item, index) => {
        return (
          <>
            <p className="circuit-phase-name">{item}</p>
            {props.data[item].map((item1, index1) => {
              return (
                <div className="circuit-content" key={index}>
                  {/* {item.detail.map((detail, index1) => { */}
                  <div className="circuitCard">
                    <div
                      className="circuit-img-and-time-details"
                      style={{
                        height: "100px",
                        //   borderRightColor: "red",
                        borderRadius: "5px",
                        borderLeft: "5px solid red",
                        padding: 0,
                        justifyContent: "space-around",
                      }}
                      key={index1}
                      onClick={() =>
                        setShow({
                          data: index,
                          detail:
                            show.data === index && show.detail === index1
                              ? null
                              : index1,
                        })
                      }
                    >
                      <img
                        src={item1.image_small}
                        width={"20%"}
                        alt="circuit"
                      />
                      <div className="circuit-number-and-rounds-div">
                        <p style={{ fontWeight: "800" }}>
                          {item1.circuit_name}
                        </p>
                        <p style={{ textTransform: "uppercase" }}>
                          {item1.duration_in_seconds} seconds with{" "}
                          {item1.rounds} round
                        </p>
                      </div>
                      <img
                        src={arrowUp}
                        style={{
                          transform:
                            show.data === index && show.detail === index1
                              ? "rotate(270deg)"
                              : "rotate(90deg)",
                        }}
                        alt="arrow-icon"
                      />
                    </div>
                    <div className="subCircuits">
                      {item1.circuit_detail.map((val, index2) => {
                        return (
                          <div
                            className="SubCircuits-img-and-time-details"
                            style={{
                              display:
                                show.data === index && show.detail === index1
                                  ? "flex"
                                  : "none",
                            }}
                            key={index2}
                          >
                            <img src={val.image_small} width={"20%"} />
                            <div
                              className="circuit-number-and-rounds-div"
                              // style={{ width: "50%", margin: 0 }}
                            >
                              <p
                                style={{
                                  fontWeight: "800",
                                  textAlign: "left",
                                  fontSize: "0.7rem",
                                }}
                              >
                                {val.name}
                              </p>
                              <p
                                style={{
                                  fontSize: "0.8rem",
                                }}
                              >
                                {item1.duration_in_seconds} secs with
                                {item1.rest_after_exercise_in_seconds} secs REST
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  {/* ); */}
                  {/* // })} */}
                </div>
              );
            })}
          </>
        );
      })}
    </div>
  );
};

export default ExerciseCircuitCard;
