import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, signInWithPhoneNumber } from "firebase/auth";
import { getMessaging, getToken } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD_zqklGsaYl3prCXmBZcRL-4E2rxonZ1U",
  authDomain: "fitflex-app-8e248.firebaseapp.com",
  projectId: "fitflex-app-8e248",
  storageBucket: "fitflex-app-8e248.appspot.com",
  messagingSenderId: "956554590353",
  appId: "1:956554590353:web:cb3965580b1aa338c16730",
  measurementId: "G-70YR8N74TT",
};

let app = "";
let analytics = "";
let auth = "";
let messaging = "";

let routes = [
  "http://fitflexapp.com/ff/daily-pk-jazz",
  "http://fitflexapp.com/ff/weekly-pk-jazz",
  "http://fitflexapp.com/ff/monthly-pk-jazz",
  "http://fitflexapp.com/ff/daily-iq-zain",
  "http://fitflexapp.com/ff/weekly-iq-zain",
  "http://fitflexapp.com/ff/monthly-iq-zain",
  "http://fitflexapp.com/daily-pk-jazz",
  "http://fitflexapp.com/weekly-pk-jazz",
  "http://fitflexapp.com/monthly-pk-jazz",
  "http://fitflexapp.com/daily-iq-zain",
  "http://fitflexapp.com/weekly-iq-zain",
  "http://fitflexapp.com/monthly-iq-zain",
];

let test = routes.find((element) => {
  return element === window.location.href;
});

if (test === undefined) {
  if (window.location.protocol !== "http:") {
    console.log("Start Firebase");
    // Initialize Firebase
    app = initializeApp(firebaseConfig);
    analytics = getAnalytics(app);
    auth = getAuth(app);
    messaging = getMessaging(app);
  }
}

export { analytics, auth, messaging };
