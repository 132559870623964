import React, { useState } from "react";
import "../styles/myCss.css";
import { useTranslation } from "react-i18next";

import Scroll from "./ScrollBar";

const TrainingScroll = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="bgImg">
        <div className="training-plans">
          <h1 className="tp-heading-small">{t("training_plans")}</h1>
          {/* <h1
            style={{
              fontSize: "24px",
              color: " #FF5858",
              fontWeight: "600",
              padding: "0",
              marginLeft: "-100px",
            }}
          >
            Training Plans
          </h1> */}
          <p className="tp-heading-big">{t("training_everyone")}</p>
        </div>

        {/* <div
        className="trainCardDiv"
        style={{
          overflow: "hidden",
          width: "100%",
        }}
      > */}

        {/* </div> */}
      </div>
      <Scroll />
    </>
  );
};

export default TrainingScroll;
