import React from "react";
import "../styles/about.css";
const miniHeader = (props) => {
  return (
    <div>
      <div className="mini-header">
        <h1 className="mini-header-text">{props.name}</h1>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
        className="hide-on-small-size"
      >
        <div
          style={{ width: "18%", background: "#ff1e1e", height: "15px" }}
        ></div>
        <div
          style={{ width: "40%", background: "#ff1e1e", height: "15px" }}
        ></div>
      </div>
    </div>
  );
};
export default miniHeader;
