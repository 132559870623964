import React, { useState, useEffect } from "react";
import "../styles/loginMarketingDirect.css";
import flag from "../assets/images/flag.png";
import apple from "../assets/images/app_store_opt.png";
import android from "../assets/images/play_store_opt.png";
import { Button, Input, Spin, Dropdown, Menu, Modal } from "antd";
import {
  MobilePackage,
  checkNetwork,
  getmsisdn,
  indonesiaMarketingLogs,
  iraqLandingCharge,
  isAuthenticated,
  userSignup,
} from "../api/requests";
import OTPInput, { ResendOTP } from "otp-input-react";
import { LoadingOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import MarketPlan from "../components/marketingPlan";
import { useParams, useSearchParams } from "react-router-dom";
import { GlobalOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Helmet } from "react-helmet";
import { CloseOutline } from "react-ionicons";
import ZongLogo from "../assets/images/zongLogo.svg";
import Arrow from "../assets/images/arrow-n.svg";
import Fitflex from "../assets/images/fitflex_logo.png";

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "#FF1E1E" }} spin />
);
const buttonAntIcon = (
  <LoadingOutlined style={{ fontSize: 25, color: "white" }} spin />
);

const ZongLp = () => {
  let { bundle, country, operator, hash } = useParams();

  const [x, setX] = useState(0);
  const [y, setY] = useState(1);
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [isOtp, setIsOtp] = useState(false);
  const [error, setError] = useState("");
  const [number, setNumber] = useState("");
  const [Otp, setOtp] = useState("");
  const [redirectTo, setRedirectTo] = useState(false);
  const [payments, setPayments] = useState([]);
  const [packageValue, setPackageValue] = useState(38);
  const [resendStatus, setResendStatus] = useState(false);
  const [resendTimer, setResendTimer] = useState(180);
  const [redirectLoading, setRedirectLoading] = useState(true);
  const [secret, setSecret] = useState("");
  const [packageName, setPackageName] = useState("daily");
  const [packagePrice, setPackagePrice] = useState("5");
  const [packageDescription, setPackageDescription] = useState("/day + tax");
  const [terms, setTerms] = useState([
    "No Additional cellular data charges will apply.",
    "Fair Usage for content streaming is 100 minutes.",
    "Offer is for Zong Prepaid Customers only.",
  ]);
  const [open, setOpen] = useState(false);

  console.log("bundle is :::", bundle);
  // const [searchParams] = useSearchParams();

  // console.log("Search Params: ", searchParams.get("msisdn").substring(3));

  // let headerEnrichmentNumber = searchParams.get("msisdn");

  let tokenCheck = isAuthenticated();

  useEffect(() => {
    let payload = {
      country_id: 162,
      operator: "zong",
      language_id: 7,
    };

    // if (headerEnrichmentNumber !== null) {
    //   setNumber(searchParams.get("msisdn").substring(3));
    // }

    getmsisdn()
      .then((res) => {
        console.log("ge response is: ", res);
        if (res !== undefined) {
          if (res.status === 200) {
            if (res.data !== null) {
              let str = res.data;
              str = str.substring(1);
              setNumber(str);
              LoginRedirect(str);
            } else {
              setRedirectLoading(false);
            }
          } else {
            setRedirectLoading(false);
          }
        } else {
          setRedirectLoading(false);
        }
      })
      .catch((err) => setRedirectLoading(false));

    MobilePackage(payload)
      .then((data) => {
        if (data.status === 200) {
          console.log('dadadadadad"""" :::', data.data);
          // setPayments(data.data);
          setLoading(false);
          setPackageName(
            bundle === "daily"
              ? data.data[0].package_name
              : bundle === "weekly"
              ? data.data[1].package_name
              : data.data[2].package_name
          );
          setPackagePrice(
            bundle === "daily"
              ? data.data[0].package_rate
              : bundle === "weekly"
              ? data.data[1].package_rate
              : data.data[2].package_rate
          );
          setPackageDescription(
            bundle === "daily"
              ? data.data[0].package_description
              : bundle === "weekly"
              ? data.data[1].package_description
              : data.data[2].package_description
          );
          setTerms(
            bundle === "daily"
              ? data.data[0].terms_and_conditions
              : bundle === "weekly"
              ? data.data[1].terms_and_conditions
              : data.data[2].terms_and_conditions
          );
          setPackageValue(
            bundle === "daily"
              ? data.data[0].package_id
              : bundle === "weekly"
              ? data.data[1].package_id
              : data.data[2].package_id
          );
        }
      })
      .catch((err) => console.log(err));

    // if (bundle === "daily") {
    //   setX(0);
    //   setY(1);
    //   console.log("bundle x is :::", bundle);
    // } else if (bundle === "weekly") {
    //   setX(1);
    //   setY(2);
    //   console.log("bundle x is :::", bundle);
    // } else if (bundle === "monthly") {
    //   setX(2);
    //   setY(3);
    //   console.log("bundle x is :::", bundle);
    // }
  }, []);

  const LoginRedirect = (number) => {
    // if (country == "pk") {
    if (number == "") {
      setError(`${t("is_empty")}`);
    } else if (number.substr(0, 2) == "03" && number.length == "11") {
      setError("Invalid entry. Required Pattern : +92300-0000000");
    } else if (
      number.substr(0, 1) == "3" &&
      number.length == "10" &&
      number.match(/^[0-9]+$/) &&
      number.match(/^31[0-9]{7}$/)
    ) {
      setLoading(false);
      setError("");

      let netCheck = {
        msisdn: number,
      };

      checkNetwork(tokenCheck, netCheck).then((data) => {
        if (data !== undefined) {
          if (data.status === 200) {
            if (data.data.network_type === "postpaid") {
              setLoading(false);
              Modal.confirm({
                title: "Information",
                icon: <ExclamationCircleOutlined />,
                content: "This package is only for prepaid customers",
                okText: "Ok",
                centered: true,
                cancelText: () => null,
                closable: true,
              });
              setRedirectLoading(false);
              return;
            } else {
              let payload = {
                msisdn: "92" + number,
                package_id: packageValue,
                country_id: 162,
              };
              console.log("payload is :::", payload);
              iraqLandingCharge(payload)
                .then((data) => {
                  console.log("My Payloadss xxx", data);
                  if (data.status === 200) {
                    if (data.data.status === 200) {
                      setResendStatus(true);
                      setLoading(false);
                      setError("");
                      setIsOtp(true);
                      setRedirectLoading(false);
                    } else {
                      console.log("hello");
                      setLoading(false);
                      setRedirectLoading(false);
                      setError(data.data.msg);
                    }
                  } else {
                    setLoading(false);
                    setRedirectLoading(false);
                    setError(
                      "Could not login at the moment, please try again later."
                    );
                  }
                })
                .catch((err) =>
                  setError(
                    "Could not login at the moment, please try again later."
                  )
                );
            }
          }
        }
      });
      // } else {
      //   setError(`${t("invalid_pattern")}`);
      // }
    } else {
      setError("Invalid Number, Please Enter a Zong Number.");
      setRedirectLoading(false);
    }
  };

  useEffect(() => {
    if (resendStatus) {
      resendTimer > 0 &&
        setTimeout(() => setResendTimer(resendTimer - 1), 1000);
    }
  }, [resendTimer, resendStatus]);

  const renderButton = () => {
    return resendTimer <= 0 ? (
      <button
        style={{ cursor: "pointer" }}
        className="resend-text"
        onClick={() => {
          LoginFunction();
          setResendTimer(60);
          setResendStatus(true);
        }}
      >
        Resend OTP
      </button>
    ) : (
      <button className="resend-text">
        OTP will Expire in{" "}
        <span
          style={{
            color: "#1E1E1E",
            fontFamily: "Manrope",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: "600",
            lineHeight: "normal",
          }}
        >
          {resendTimer} seconds
        </span>
      </button>
    );
  };
  const renderTime = () => React.Fragment;

  function checkFirstTwoCharacters(str) {
    const forbiddenPatterns = /^(50|55|53)/; // Regular expression pattern

    // Check if the string matches any of the forbidden patterns
    return forbiddenPatterns.test(str);
  }

  const LoginFunction = () => {
    console.log("package value is :::", packageValue);
    setError("");
    if (number == "") {
      console.log("Empty Check");
      setError("Please Enter A Valid Number to Continue");
      return;
    } else if (number.substr(0, 2) == "03" && number.length == "11") {
      setError("Invalid entry. Required Pattern : +92300-0000000");
    } else if (
      number.substr(0, 1) == "3" &&
      number.length == "10" &&
      number.match(/^[0-9]+$/) &&
      number.match(/^31[0-9]{7}$/)
    ) {
      console.log("Test Clear");
      setLoading(true);
      console.log("package value is ::::", packageValue);
      let payload = {
        msisdn: "92" + number,
        package_id: packageValue,
        country_id: 162,
        // operator: "zong",
      };
      console.log("payload is :::", payload);
      iraqLandingCharge(payload)
        .then((data) => {
          console.log("My Payloadss", data);
          if (data.status === 200) {
            if (data.data.status === 200) {
              setResendStatus(true);
              setLoading(false);
              setError("");
              setIsOtp(true);
            } else {
              setLoading(false);
              setError(data.data.msg);
            }
          } else {
            setLoading(false);
            setError("Could not login at the moment, please try again later.");
          }
        })
        .catch((err) =>
          setError("Could not login at the moment, please try again later.")
        );
    } else {
      setError("Invalid Number. Please Enter a Zong Number");
    }
  };

  const onSubmit = () => {
    if (Otp.length !== 4) {
      setError(`OTP must be of 4 digits`);
    } else {
      setLoading(true);
      console.log("package id :::", packageValue);
      let otpPayload = {
        msisdn: "92" + number,
        package_id: packageValue,
        country_id: 162,
        otp: Otp,
        // operator: "zong",
        marketPartner: "web",
      };

      console.log("on submit otp payload is :::", otpPayload);

      iraqLandingCharge(otpPayload)
        .then((data) => {
          console.log("response data: ", data);
          if (data.status === 200) {
            if (data.data.status === 200) {
              let marketingLogPayload = {
                msisdn: "92" + number,
                package_id: packageValue,
                country_id: 162,
                otp: Otp,
                MarketingUrl: window.location.href,
              };
              indonesiaMarketingLogs(marketingLogPayload).then((response) => {
                setLoading(false);
                setError("Package is subscribed succesfully");
                setTimeout(() => {
                  setRedirectTo(true);
                }, 1000);
              });
            } else {
              setLoading(false);
              setError(data.data.msg);
            }
          } else {
            setLoading(false);
            setError("Invalid OTP");
          }
        })
        .catch((err) => {
          setError("Invalid OTP");
          setLoading(false);
        });
    }
  };

  if (redirectTo) {
    window.location.replace("https://fitflexapp.com/checkuserlp");
  }

  if (redirectLoading) {
    return (
      <div className="loader">
        <Spin
          className="spinner"
          indicator={antIcon}
          tip="Fetching Details..."
        />
      </div>
    );
  }

  return (
    // <div className="Login-page-containerMk">
    //   <div className="containerLeftMk">
    //     <div className="termsMk">
    //       {payments.length > 0 &&
    //         payments
    //           .map((item, index) => {
    //             return (
    //               <div className="marketCardMk" key={index}>
    //                 <MarketPlan
    //                   type={`${t("subscription_plan")}`}
    //                   rate={t(item.package_name) + ` PKR ` + item.package_rate}
    //                   duration={item.package_description}
    //                   description={""}
    //                   term={item.terms_and_conditions}
    //                   countryName={"pk"}
    //                 />
    //               </div>
    //             );
    //           })
    //           .slice(x, y)}
    //     </div>
    //   </div>

    //   <div className="containerRightMk">
    //     <div className="titleDivMk">
    //       <div style={{ display: "inline-block" }}>
    //         <span className="titletextMk" style={{ color: "#ff1e1e" }}>
    //           FIT
    //         </span>
    //         <span className="titletextMk" style={{ color: "#ff1e1e" }}>
    //           FLEX
    //         </span>
    //       </div>
    //     </div>

    //     {isOtp ? (
    //       <>
    //         <h1 className={"welcomeMk"}>{t("enter_otp")}</h1>
    //         <p className={"ttextMk"}>
    //           <ResendOTP
    //             renderButton={renderButton}
    //             renderTime={renderTime}
    //             onResendClick={() => console.log("Resend clicked")}
    //           />
    //         </p>
    //         <OTPInput
    //           value={OTP}
    //           onChange={setOTP}
    //           autoFocus={false}
    //           OTPLength={4}
    //           otpType="any"
    //           disabled={false}
    //           inputClassName="otpinputMk"
    //           className="otpMk"
    //         />
    //         <p
    //           className="ttextMk"
    //           style={{
    //             color: "red",
    //             marginTop: 20,
    //             marginBottom: 0,
    //             display: error != "" ? "flex" : "none",
    //           }}
    //         >
    //           {error}
    //         </p>
    //         {loading ? (
    //           <div className="loading-div">
    //             <Spin indicator={antIcon} tip="Verifying OTP..." />
    //           </div>
    //         ) : (
    //           <Button className={"btn-loginMk"} onClick={onSubmit}>
    //             {t("Click to continue")}
    //           </Button>
    //         )}
    //       </>
    //     ) : (
    //       <>
    //         <h1 className={"welcomeMk"}>Start Your Fitness Journey Today</h1>

    //         <p className={"ttextMk"}>Enter your Zong mobile number</p>
    //         <div className={"inputdivMk"}>
    //           <img src={flag} className="flagstyle" />
    //           <Input
    //             className="inputfeildMk"
    //             type="number"
    //             value={number}
    //             onChange={(e) => setNumber(e.target.value)}
    //             placeholder="XXXXXXXXXX"
    //             bordered={false}
    //             prefix={
    //               <span
    //                 style={{
    //                   fontSize: 14,
    //                 }}
    //               >
    //                 +92
    //               </span>
    //             }
    //           />
    //         </div>
    //         <p
    //           className="ttextMk"
    //           style={{
    //             color: "red",
    //             marginTop: 20,
    //             marginBottom: 0,
    //             display: error != "" ? "flex" : "none",
    //           }}
    //         >
    //           {error}
    //         </p>
    //         {loading ? (
    //           <div className="loading-div">
    //             <Spin indicator={antIcon} tip="Loading..." />
    //           </div>
    //         ) : (
    //           <Button className={"btn-loginMk"} onClick={LoginFunction}>
    //             {t("Click to continue")}
    //           </Button>
    //         )}
    //       </>
    //     )}

    //     <div className="margin-top-minus-on-smallMk">
    //       <a
    //         href="https://play.google.com/store/apps/details?id=fitflex.workout.fitness.weight.gym.fat.training"
    //         target="_blank"
    //       >
    //         <img
    //           src={android}
    //           style={{
    //             marginRight: "5px",
    //           }}
    //           alt="playstore"
    //           className="storeimg"
    //         />
    //       </a>
    //       <a
    //         href="https://apps.apple.com/us/app/fitflex/id1613514782"
    //         target="_blank"
    //       >
    //         <img
    //           src={apple}
    //           style={{
    //             border: "1px solid grey",
    //             borderRadius: "4px",
    //           }}
    //           alt="appstore"
    //           className="storeimg"
    //         />
    //       </a>
    //     </div>
    //   </div>
    // </div>
    <>
      <Helmet>{/* <script>{TikTokIntegrationScript}</script> */}</Helmet>
      <Modal
        // title="Basic Modal"
        closable
        visible={open}
        closeIcon={<CloseOutline />}
        // onOk={handleOk}
        onCancel={() => setOpen(false)}
        footer={null}
        style={{
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          borderRadius: "20px",
          padding: "20px",
        }}
        bodyStyle={{
          borderRadius: "20px",
          padding: "30px",
        }}
      >
        <h1
          style={{
            color: "#000",
            textAlign: "center",
            fontFamily: " Manrope",
            fontSize: "20px",
            fontStyle: "normal",
            fontWeight: "600",
            lineHeight: "92.6%",
            marginBottom: "20px",
            marginTop: "15px",
          }}
        >
          Terms And Conditions
        </h1>
        {terms?.map((item, index) => {
          return (
            <React.Fragment key={index}>
              <ul>
                <li
                  style={{
                    color: " #434343",
                    fontFamily: "Manrope",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "normal",
                  }}
                >
                  {item}
                </li>
              </ul>
            </React.Fragment>
          );
        })}
        {/* // <p>Some contents...</p>
        // <p>Some contents...</p> */}
      </Modal>
      <div className="main-container-div">
        <div className="main-left-container-div">
          {/* <p className="main-left-heading-one">FITFLEX</p> */}
          {/* <Dropdown overlay={menu_pk}>
            <a className="dropdown-ln" onClick={handleMenuClick}>
              {lang.toUpperCase()}
              <GlobalOutlined
                style={{ color: "#ff1e1e", marginLeft: "20px" }}
              />
            </a>
          </Dropdown> */}

          <img src={Fitflex} alt="fitflexLogo" />
          <p className="main-left-heading-two">World Class Fitness App</p>
          <p className="main-left-heading-three">
            Start Your Fitness Journey with 400+ HD Quality Videos
          </p>
          {isOtp ? (
            <>
              <p className="enter-number-and-otp-text">
                Enter Your 4 digit pin here
              </p>
              <OTPInput
                value={Otp}
                onChange={setOtp}
                autoFocus={false}
                OTPLength={4}
                otpType="any"
                disabled={false}
                // secure
                style={{
                  justifyContent: "center",
                }}
                inputClassName="otpinputDiv"
                className="otp-box"
              />
              <ResendOTP
                renderButton={renderButton}
                renderTime={renderTime}
                onResendClick={() => console.log("Resend clicked")}
              />
              <p className="error-text">{error}</p>
              <button className="continueButton-new" onClick={() => onSubmit()}>
                {loading ? <Spin indicator={buttonAntIcon} /> : "Continue"}
              </button>
            </>
          ) : (
            <>
              <p className="enter-number-and-otp-text">
                Enter Your Mobile Number Below
              </p>
              <div className="input-div">
                <img
                  src={flag}
                  alt="flagIcon"
                  className="flagstyle"
                  style={{
                    marginLeft: "5px",
                    width: "10%",
                    border: "2px solid black",
                  }}
                />
                <Input
                  className="inputfield"
                  type="number"
                  value={number}
                  onChange={(e) => setNumber(e.target.value)}
                  placeholder="XXXXXXXXXX"
                  bordered={false}
                  prefix={
                    <>
                      <div
                        style={{
                          borderLeft: "1px solid #6f6f6f",
                          height: "30px",
                        }}
                      ></div>
                      <span
                        style={{
                          fontSize: 16,
                          color: "#6f6f6f",
                          // borderLeft: "1px solid black",
                          paddingLeft: "10px",
                          // height: "25px",
                        }}
                      >
                        +92
                      </span>
                    </>
                  }
                  suffix={
                    <>
                      <img src={ZongLogo} />
                    </>
                  }
                />
              </div>
              <p className="package-text">
                *Rs {packagePrice}
                {packageDescription}
              </p>
              <p className="error-text">{error}</p>

              <button
                className="continueButton-new"
                onClick={() => LoginFunction()}
              >
                {loading ? <Spin indicator={buttonAntIcon} /> : "continue"}
              </button>
            </>
          )}
          <button
            className="termsAndConditionBtn"
            onClick={() => {
              setOpen(true);
            }}
          >
            <img src={Arrow} alt="arrow" />
            <div
              style={{ height: "15px", border: "0.5px solid rgba(0, 0, 0, 1)" }}
            ></div>
            Terms and Conditions
          </button>
        </div>
        <div className="main-right-container-div">
          {/* <img
          src={GirlDancingImg}
          style={{ width: "66%", height: "100%" }}
          alt="girlDancing"
        /> */}
        </div>
      </div>
    </>
  );
};

export default ZongLp;
