import React from "react";
import "../styles/about.css";

const TeamCard = (props) => {
  return (
    <div className="member-card">
      <div className="profile-pic-div">
        <img
          src={props.pic}
          width={"100%"}
          height={"100%"}
          className="hide-on-small"
        />
      </div>
      <div className="member-info-div">
        <p className="name-text">{props.name}</p>
        <p className="member-description">{props.desc1}</p>
        <p className="member-description2">{props.desc2}</p>
      </div>
    </div>
  );
};
export default TeamCard;
