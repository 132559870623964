// const url = "https://fitflex.switchsolutions.com.pk";
// const url = "https://fitflexapp.com";
const url = "https://prod.fitflexapp.com";
// const url = "https://qa.fitflexapp.com";
// const url = "https://localhost:7172";
// const url = "https://dev.fitflexapp.com";

export const userSignup = (user) => {
  return fetch(`${url}/api/users/signupV1`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
    },
    body: JSON.stringify(user),
  })
    .then((response) => {
      console.log(response);
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const marketingSignup = (user) => {
  return fetch(`http://fitflexapp.com/api/marketingSignup`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
    },
    body: JSON.stringify(user),
  })
    .then((response) => {
      console.log(response);
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const logUser = (user) => {
  return fetch(`${url}/api/log_user`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
    },
    body: JSON.stringify(user),
  })
    .then((response) => {
      console.log(response);
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const logUserV2 = (user) => {
  return fetch(`https://fitflexapp.com/checkuser/loging_iraq_insertion.php`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
    },
    body: JSON.stringify(user),
  })
    .then((response) => {
      console.log(response);
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const HeVodacomSouthAfrica = (user) => {
  return fetch(`${url}/api/heVodacom`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
      // Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(user),
  })
    .then((response) => {
      console.log("response xxx", response);
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const HeVodacomSouthAfricaACR = (user) => {
  return fetch(`${url}/api/heVodacomACR`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
      // Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(user),
  })
    .then((response) => {
      console.log("response xxx", response);
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const VodacomSouthAfrica = (user) => {
  return fetch(`${url}/api/Emsisdnslogs`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
    },
    body: JSON.stringify(user),
  })
    .then((response) => {
      console.log("API RESPONSE: ", response);
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const marketingLogs = (user) => {
  return fetch(`${url}/api/marketingCallback`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
    },
    body: JSON.stringify(user),
  })
    .then((response) => {
      console.log(response);
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const iraqLandingCharge = (user) => {
  return fetch(`${url}/api/IraqLandingCharge`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
    },
    body: JSON.stringify(user),
  })
    .then((response) => {
      console.log("iraq landing charge response is ::", response);
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const indonesiaMarketingLogs = (user) => {
  return fetch(`${url}/api/MarketingLogs`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
    },
    body: JSON.stringify(user),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getTokenUserSegmentation = (user) => {
  console.log("IN API: : :", user);
  return fetch(`${url}/api/gettokken`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
    },
    body: JSON.stringify(user),
  })
    .then((response) => {
      console.log("RESPONSE: ", response);
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const iraqHECharge = (user) => {
  return fetch(`${url}/api/heMediaWorld`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
    },
    body: JSON.stringify(user),
  })
    .then((response) => {
      console.log(response);
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const iraqHE = () => {
  return fetch(`http://fitflexapp.com/he_iraq/`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response) => {
      console.log("Response: ", response);
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getEffort = (token) => {
  return fetch(`${url}/api/geteffort`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getTrendingVideo = (token, param) => {
  return fetch(
    `${url}/api/videos/listmob/?trending=1&page=&paginate=&maturity_level=`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(param),
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};
export const otpVerify = (user) => {
  return fetch(`${url}/api/users/signinV1`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
    },
    body: JSON.stringify(user),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const jazzCashAccount = (file, token) => {
  return fetch(`${url}/api/v2/payments/charge`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      // "Content-type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
    body: file,
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const addMobilePackage = (payload, token) => {
  return fetch(`${url}/api/mobilepackageV2`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const sharePost = (payload, token) => {
  return fetch(`${url}/api/users/sharepost`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const MobilePackage = (payload) => {
  return fetch(`${url}/api/mobilepackage`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
    },
    body: JSON.stringify(payload),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const GetMobilePackage = (token, payload) => {
  return fetch(`${url}/api/mobilepackage`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const authenticate = (jwt, num, country, next) => {
  if (typeof window !== "undefined") {
    localStorage.setItem("jwt", JSON.stringify(jwt));
    localStorage.setItem("number", JSON.stringify(num));
    localStorage.setItem("country", country);
    next();
  }
};

export const authenticateSaUser = (jwt, num, country, next) => {
  if (typeof window !== "undefined") {
    localStorage.setItem("jwt", JSON.stringify(jwt));
    localStorage.setItem("number", JSON.stringify(num));
    localStorage.setItem("country", country);
    next();
  }
};

export const getFavouriteWorkouts = (token, param) => {
  return fetch(`${url}/api/videos/myList`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(param),
  }).then((response) => {
    return response.json();
  });
};

export const getTrainingPlans = (param) => {
  return fetch(`${url}/api/series/list/web`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
      // Authorization:`Bearer ${token}`,
    },
    body: JSON.stringify(param),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getmsisdn = () => {
  return fetch("http://fitflexapp.com/api/get_head_enrichment.php", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getOTP = (num) => {
  return fetch(`https://fitflexapp.com/api/createWithOtpFitflex/`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
    },
    body: JSON.stringify(num),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const verifyOTP = (num) => {
  return fetch(`https://fitflexapp.com/api/verifyotpFitflex/`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
    },
    body: JSON.stringify(num),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const checkNetwork = (token, num) => {
  return fetch(`${url}/api/checknetwork`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
      // Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(num),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};
export const checkMarketingkNetwork = (num) => {
  return fetch(
    `http://mediation.fitflexapp.com/networkCheck.php?msisdn=${num}`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const isAuthenticated = () => {
  if (typeof window == "undefined") {
    return false;
  } else if (localStorage.getItem("jwt")) {
    return JSON.parse(localStorage.getItem("jwt"));
  } else {
    return false;
  }
};

export const getNumber = () => {
  if (typeof window == "undefined") {
    return false;
  } else if (localStorage.getItem("number")) {
    return JSON.parse(localStorage.getItem("number"));
  } else {
    return false;
  }
};
// export const getAllWorkoutVideos = (token) => {
//   return fetch(`${url}/api/videos/listmob/`, {
//     method: "GET",
//     headers: {
//       Accept: "application/json",
//       "Content-type": "application/json",
//     },
//   })
//     .then((response) => {
//       return response.json();
//     })
//     .catch((err) => {
//       console.log(err);
//     });
// };
export const getAllWorkoutVideos = (token) => {
  return fetch(`${url}/api/videos/listmob/`, {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain",
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};
export const getRecommenderWorkouts = (token, param) => {
  return fetch(
    `${url}/api/videos/listmob/?recommend=1&page=&paginate=&maturity_level=`,
    {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain",
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(param),
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};
export const getVideosWithCat = (token, param) => {
  return fetch(`${url}/api/getvideowithcat`, {
    method: "POST",
    // credentials: "omit",
    headers: {
      Accept: "application/json, text/plain",
      "Access-Control-Allow-Origin": "*",
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(param),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getToken = (token) => {
  return fetch(`${url}/api/token_conversion`, {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain",
      "Content-type": "application/json",
    },
    body: JSON.stringify(token),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};
export const getPlayable = (param, video_id, token) => {
  return fetch(`${url}/api/v2/videos/${video_id}/playable`, {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain",
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(param),
  }).then((response) => {
    return response.json();
  });
};
export const setTrainingPlans = (parameters, token) => {
  return fetch(`${url}/api/settrainingplan`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(parameters),
  }).then((response) => {
    return response.json();
  });
};
export const UpdateTrainingPlan = (parameters, token) => {
  return fetch(`${url}/api/settrainingplanlist`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(parameters),
  }).then((response) => {
    return response.json();
  });
};

export const getMyTrainingPlans = (month, token) => {
  return fetch(`${url}/api/getactivitynewV3`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(month),
  }).then((response) => {
    return response.json();
  });
};

export const addToFavourites = (videoid, token) => {
  return fetch(`${url}/api/videos/addToFavourites`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(videoid),
  }).then((response) => {
    return response.json();
  });
};
export const setAboutFlag = (token, about_flag) => {
  return fetch(`${url}/api/setaboutflag`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(about_flag),
  }).then((response) => {
    return response.json();
  });
};
export const setGoalFlag = (token, goal_flag) => {
  return fetch(`${url}/api/setaboutflag`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(goal_flag),
  }).then((response) => {
    return response.json();
  });
};
export const getGoalFlag = (token) => {
  return fetch(`${url}/api/profile/getgoal`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).then((response) => {
    return response.json();
  });
};
export const removeFromFavourites = (videoid, token) => {
  return fetch(`${url}/api/videos/removeFavourite`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(videoid),
  }).then((response) => {
    return response.json();
  });
};

export const getFeaturedBlog = () => {
  return fetch(`${url}/api/featuredblogs`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};
export const getCategoryBlogs = (token) => {
  return fetch(`${url}/api/blog/categorywise`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};
export const getSingleBlog = (id) => {
  return fetch(`${url}/api/blogs/${id} `, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};
export const getSingleBlogsCategory = (id) => {
  return fetch(`${url}/api/blogs/category/${id} `, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};
export const getSingleTrainingPlan = (token, id, param) => {
  return fetch(`${url}/api/series/list/${id} `, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(param),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};
export const getSingleJob = (id) => {
  return fetch(`${url}/api/jobs/${id} `, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};
export const getAlljobs = () => {
  return fetch(`${url}/api/jobs `, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const unSubscribePackage = (id, token) => {
  return fetch(`${url}/api/users/unSubscribe`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(id),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};
export const getSearchedVideos = (params, token) => {
  return fetch(`${url}/api/search`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(params),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};
export const setActivity = (params, token) => {
  return fetch(`${url}/api/setactivity`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(params),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};
export const removeActivity = (params, token) => {
  return fetch(`${url}/api/delactivity`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(params),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};
// export const setAboutyou = (params, token) => {
//   return fetch(`${url}/api/aboutyou`, {
//     method: "POST",
//     headers: {
//       Accept: "application/json",
//       "Content-type": "application/json",
//       Authorization: `Bearer ${token}`,
//     },
//     body: JSON.stringify(params),
//   })
//     .then((response) => {
//       return response.json();
//     })
//     .catch((err) => {
//       console.log(err);
//     });
// };

export const setAboutyou = (params, token) => {
  return fetch(`${url}/api/v2/aboutyou`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(params),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};
export const getAboutyou = (token) => {
  return fetch(`${url}/api/v2/aboutyou`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};
export const setgoal = (params, token) => {
  return fetch(`${url}/api/profile/setgoal`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(params),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};
export const setEffort = (params, token) => {
  return fetch(`${url}/api/seteffort`, {
    method: "POST",
    keepalive: true,
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(params),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};
export const deleteAccount = (token) => {
  return fetch(`${url}/api/users/remove`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};
export const AllExerciseData = () => {
  return fetch(`${url}/api/fitness/training/schemas/workouts`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
      // Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};
export const getSingleExcercise = (id, token, param) => {
  return fetch(`${url}/api/fitness/training/schemas/workout/${id}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(param),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};
export const addFavouritesExercise = (payload, token) => {
  return fetch(`${url}/api/videos/addToFavourites/exercise`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};
export const removeFavouritesExercise = (payload, token) => {
  return fetch(`${url}/api/videos/removeFavourite/exercise`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};
export const getFavouriteExercise = (token) => {
  return fetch(`${url}/api/videos/myList/exercise`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    // body: JSON.stringify(payload),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};
export const getFilterExercises = (payload) => {
  return fetch(`${url}/api/fitness/training/schemas/searchWorkouts`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
      // Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};
export const getBodyTypes = (token, payload) => {
  return fetch(`${url}/api/bodytypes`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload),
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.log(error);
    });
};
export const addNutritionPlan = (token, payload) => {
  return fetch(`${url}/api/AddNutritionPlan`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload),
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.log(error);
    });
};
export const getSingleNutrition = (videoId, token) => {
  return fetch(`${url}/api/mealV1/${videoId}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    // body: JSON.stringify(payload),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getAllMeals = (token, payload) => {
  return fetch(`${url}/api/allMeals`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload),
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.log(error);
    });
};

export const addFavNutrition = (token, payload) => {
  return fetch(`${url}/api/nutrition/addfav`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload),
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.log(error);
    });
};
export const removeFavNutrition = (token, payload) => {
  return fetch(`${url}/api/nutrition/removefav`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(payload),
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.log(error);
    });
};

export const nutritionPlan = (token) => {
  return fetch(`${url}/api/GetNutritionPlanV3`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.log(error);
    });
};
// export const getQuotes = (data) => {
//   return fetch(`${url}/api/getquotes`, {
//     method: "POST",
//     headers: {
//       Accept: "application/json",
//       "Content-type": "application/json",
//     },
//     body: JSON.stringify(data),
//   })
//     .then((response) => {
//       return response.json();
//     })
//     .catch((err) => {
//       console.log(err);
//     });
// };

export const setAboutYouV3 = (params, token) => {
  return fetch(`${url}/api/v3/aboutyou`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(params),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getAboutYouV3 = (token) => {
  return fetch(`${url}/api/v3/aboutyou`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const setGoal = (params, token) => {
  return fetch(`${url}/api/profileV1/setgoal`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(params),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getGoal = (params, token) => {
  return fetch(`${url}/api/profileV1/getgoal`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(params),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getFavNutrition = (token) => {
  return fetch(`${url}/api/nutrition/getfav`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getQuotes = (data) => {
  return fetch(`${url}/api/getquotes`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      // "Content-type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

// export const setAboutYouV3 = (params, token) => {
//   return fetch(`${url}/api/v3/aboutyou`, {
//     method: "POST",
//     headers: {
//       Accept: "application/json",
//       "Content-type": "application/json",
//       Authorization: `Bearer ${token}`,
//     },
//     body: JSON.stringify(params),
//   })
//     .then((response) => {
//       return response.json();
//     })
//     .catch((err) => {
//       console.log(err);
//     });
// };

// export const getAboutYouV3 = (token) => {
//   return fetch(`${url}/api/v3/aboutyou`, {
//     method: "GET",
//     headers: {
//       Accept: "application/json",
//       "Content-type": "application/json",
//       Authorization: `Bearer ${token}`,
//     },
//   })
//     .then((response) => {
//       return response.json();
//     })
//     .catch((err) => {
//       console.log(err);
//     });
// };

// export const setGoal = (params, token) => {
//   return fetch(`${url}/api/profileV1/setgoal`, {
//     method: "POST",
//     headers: {
//       Accept: "application/json",
//       "Content-type": "application/json",
//       Authorization: `Bearer ${token}`,
//     },
//     body: JSON.stringify(params),
//   })
//     .then((response) => {
//       return response.json();
//     })
//     .catch((err) => {
//       console.log(err);
//     });
// };

// export const getGoal = (params, token) => {
//   return fetch(`${url}/api/profileV1/getgoal`, {
//     method: "POST",
//     headers: {
//       Accept: "application/json",
//       "Content-type": "application/json",
//       Authorization: `Bearer ${token}`,
//     },
//     body: JSON.stringify(params),
//   })
//     .then((response) => {
//       return response.json();
//     })
//     .catch((err) => {
//       console.log(err);
//     });
// };

export const checkPakUser = (msisdn) => {
  return fetch(
    `https://mediation.fitflexapp.com/checkuserpak.php?msisdn=${msisdn}`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.log("error is :::", error);
    });
};

export const getO2He = () => {
  return fetch(`https://qa.fitflexapp.com:7172/he-o2/index.php`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.log("error is :::", error);
    });
};

export const MzaSignatureVerify = (signature, msisdn) => {
  // console.log(
  //   `https://qa.fitflexapp.com:7172/he_zong/?msisdn=${msisdn}&signature=${signature}`
  // );
  return fetch(`${url}/api/he_zong/?msisdn=${msisdn}&signature=${signature}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.log("error is :::", error);
    });
};

export const iraqLandingChargeO2 = (body) => {
  return fetch(`${url}/api/IraqLandingChargeO2`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.log("error is :::", error);
    });
};

export const zongMzaSubscribe = (body) => {
  return fetch("https://zongbilling.fitflexapp.com/ussd/ussdsubunsub.php", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.log("error is :::", error);
    });
};

export const UfoneHe = (body) => {
  const username = "ufoneheget";
  const password = "Fitflex123@Ufone";
  return fetch(`http://ucloud.com.pk/ufonehe.php`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
      Authorization: "Basic " + btoa(username + ":" + password),
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.log("error is :::", error);
    });
};

export const CrossPromoApi = () => {
  return fetch(`${url}/api/crossProductPromo`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.log("error is :::", error);
    });
};

export const jazzCashUnsub = (body) => {
  let username = "JazzCashAPIs";
  let password = "j@zzC@sHusub123";
  return fetch("https://qa.fitflexapp.com/user_segmentation/jc_unsub.php", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Basic " + btoa(username + ":" + password),
    },
    body: JSON.stringify(body),
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.log("error is :::", error);
    });
};

export const getAllQuestionsApi = (token) => {
  return fetch(`${url}/api/getAllQuizQuestions`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.log("error is :::", error);
    });
};

export const skipQuestionApi = (token) => {
  return fetch(`${url}/api/getSkipQuestionsRandom`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    // body: JSON.stringify(body),
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.log("error is :::", error);
    });
};

export const updateQuestionApi = (body, token) => {
  return fetch(`${url}/api/updateQuizLevel`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.log("error is :::", error);
    });
};

export const indoSignInApi = (body) => {
  return fetch(`${url}/api/indoSignIn`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.log("error is :::", error);
    });
};
