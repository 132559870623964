import React, { useState, useEffect } from "react";
import SelectLevel from "../assets/game/selectLevel.png";
import SuccessImg from "../assets/game/successImg.png";
import FailureImg from "../assets/game/failureImg.png";
import Lock from "../assets/game/lock.png";
import StarImg from "../assets/game/startImg.png";
import { getAllQuestionsApi, updateQuestionApi } from "../api/requests";
import "../styles/Quiz.css";
import Question from "../components/Question";
import { useNavigate } from "react-router-dom";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Modal } from "antd";
const Quiz = () => {
  const [questionsData, setQuestionsData] = useState({});
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(null);
  const [showSuccess, setShowSuccess] = useState(null);
  const [quizCompleted, setQuizCompleted] = useState(false);
  const [startGame, setStartGame] = useState(false);
  const navigate = useNavigate();

  const handleSelectQuestion = (index) => {
    console.log("enter handle question");
    setShowSuccess(null);
    setCurrentQuestionIndex(index);
  };

  const handleAnswer = async (userOption, correctAnswer) => {
    console.table("params are :::", userOption, correctAnswer);
    if (userOption === correctAnswer) {
      console.log("answer is correct");
      try {
        let body = {
          level: currentQuestionIndex + 1,
        };

        let token = JSON.parse(localStorage.getItem("jwt"));
        const response = await updateQuestionApi(body, token);
        if (response.status === 200) {
          setShowSuccess(true);
        }
      } catch (error) {
        console.log("error is :::", error);
      }
      //   setShowSuccess(true);
    } else {
      console.log("answer is wrong");
      setShowSuccess(false);
    }
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex < Object.keys(questionsData).length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setShowSuccess(null);
    } else {
      setQuizCompleted(true);
      setShowSuccess(true);
    }
    // setShowCongrats(false);
  };

  useEffect(() => {
    const getAllQuestions = async () => {
      let token = JSON.parse(localStorage.getItem("jwt"));
      try {
        const response = await getAllQuestionsApi(token);
        if (response.status === 200) {
          setQuestionsData(response.data);
        } else {
          console.log("response errror is ::", response);
        }
      } catch (error) {
        console.log("error is :::", error);
      }
    };
    getAllQuestions();
  }, []);

  const CongratsBox = () => {
    return (
      <>
        <div className="congrats-div">
          <img
            src={SuccessImg}
            // style={{ marginTop: "30px" }}
            className="successImg"
          />
          <img src={StarImg} className="star-img" />
          {!quizCompleted && (
            <p className="congrats-primary-text">Correct Answer!</p>
          )}
          <p className="congrats-secondary-text">Congratulations!</p>
          {quizCompleted && (
            <p className="congrats-last-text">You have completed the quiz!</p>
          )}
          <p className="congrats-last-text">You are smart!</p>
          <p className="congrats-last-text"> most people dont know that</p>
        </div>
        <button
          className="button-container"
          onClick={() => {
            quizCompleted ? navigate("/home") : handleNextQuestion();
          }}
        >
          {quizCompleted ? "Back to Home" : "Next Question"}
        </button>
      </>
    );
  };

  const FailBox = () => {
    return (
      <>
        <div className="congrats-div">
          <img
            src={FailureImg}
            // style={{ marginTop: "30px" }}
            className="successImg"
          />
          {/* <img
            src={StarImg}
            style={{
              position: "absolute",
              left: "50%",
              top: 0,
              right: "50%",
              width: "15%",
              transform: "translate(-50%, -50%)",
            }}
          /> */}
          <p className="congrats-primary-text">Oops!</p>
          <p className="congrats-secondary-text">That's Incorrect</p>
          <p className="congrats-last-text">
            It’s ok, we learn with our mistakes.
          </p>
          <p className="congrats-last-text">
            {" "}
            You will get this the next time!
          </p>
        </div>
        <button
          className="button-container"
          onClick={() => setShowSuccess(null)}
        >
          Try Again
        </button>
      </>
    );
  };

  return (
    <>
      {startGame ? (
        <div className="mainContainer">
          {showSuccess === null ? (
            currentQuestionIndex === null ? (
              <>
                {" "}
                <img
                  src={SelectLevel}
                  //   style={{ width: "30%" }}
                  className="selectLevelImg"
                />
                <div className="levels-container">
                  {Object.entries(questionsData).map(
                    ([key, question], index) => {
                      // console.log("enter in loop");
                      return (
                        <>
                          <div
                            onClick={() => {
                              question.watched === false
                                ? Modal.confirm({
                                    title: "Information",
                                    icon: <ExclamationCircleOutlined />,
                                    content:
                                      "This level will be unlocked only if you clear previous levels",
                                    okText: "Ok",
                                    centered: true,
                                    cancelText: () => null,
                                    closable: true,
                                  })
                                : handleSelectQuestion(index);
                            }}
                            className="level-box"
                            style={{ color: "black" }}
                          >
                            {question.watched === false ? (
                              <img src={Lock} className="lockimg" />
                            ) : (
                              question.id
                            )}
                          </div>
                        </>
                      );
                    }
                  )}
                </div>
              </>
            ) : (
              <Question
                questionData={
                  Object.values(questionsData)[currentQuestionIndex]
                }
                handleAnswer={handleAnswer}
              />
            )
          ) : showSuccess === true ? (
            <CongratsBox />
          ) : (
            <FailBox />
          )}
        </div>
      ) : (
        <div
          className="startGamediv"
          onClick={() => {
            setStartGame(true);
          }}
          style={{ cursor: "pointer" }}
        ></div>
      )}
    </>
  );
};

export default Quiz;
