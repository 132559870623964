import React, { useState, useEffect } from "react";
import "../styles/LpAboutYou.css";
import { Input, Button, DatePicker, Dropdown, Menu, Spin, Select } from "antd";
import userIcon from "../assets/images/userIcon.png";
import emailIcon from "../assets/images/emailIcon.png";
import birthIcon from "../assets/images/birthIcon.png";
import genderIcon from "../assets/images/Gender.svg";
import heightIcon from "../assets/images/Height.svg";
import arrowIcon from "../assets/images/arrowIcon.png";
import weightIcon from "../assets/images/weightIcon.png";
import targetWeightIcon from "../assets/images/targetWeightIcon.png";
import {
  getAboutYouV3,
  getQuotes,
  setAboutYouV3,
  getTokenUserSegmentation,
} from "../api/requests";
import { LoadingOutlined } from "@ant-design/icons";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import moment from "moment";
import { useTranslation } from "react-i18next";

const LpAboutYou = () => {
  const [searchParams] = useSearchParams();
  const secretID = searchParams.get("secretID");

  console.log("Search Params: ", secretID);

  const [name, setName] = useState("");
  const navigate = useNavigate();
  const [height, setHeight] = useState(0);
  const [heightInch, setHeightInch] = useState();
  const [heightCm, setHeightCm] = useState(0);
  const [weight, setWeight] = useState(0);
  const [weightPounds, setWeightPounds] = useState(0);
  const [weightGram, setWeightGram] = useState(0);
  const [target, setTarget] = useState(0);
  const [targetPounds, setTargetPounds] = useState(0);
  const [gender, setGender] = useState("male");
  const [weightUnit, setWeightUnit] = useState("kg");
  const [heightUnit, setHeightUnit] = useState("ft:in");
  const [targetUnit, setTargetUnit] = useState("kg");
  const [quote, setQuote] = useState("");
  const [author, setAuthor] = useState("");
  const [dob, setDob] = useState(moment().format("YYYY-MM-DD"));
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    const getData = async () => {
      let param = {
        language_id: 7,
      };

      const token = JSON.parse(localStorage.getItem("jwt"));
      //   console.log("token is ::", token);
      await getQuotes(param).then((response) => {
        if (response.status === 200) {
          //   console.log("quote api response is :::", response.data);
          setQuote(response.data.Quote);
          setAuthor(response.data.Author);
        }
      });

      await getAboutYouV3(token).then((response) => {
        // console.log("the response is :::", response);
        if (response.status === 200) {
          console.log("set about you response is :::", response);
          setName(response.data.name);
          setGender(response.data.sex);
          if (response.data.dob === "0000-00-00") {
            setDob(moment().format("YYYY-MM-DD"));
          } else {
            setDob(response.data.dob);
          }
          setWeightUnit(response.data.weight_unit);
          if (
            response.data.height_unit === "feet" ||
            response.data.height_unit === "inches"
          ) {
            setHeightUnit("ft:in");
          } else {
            setHeightUnit(response.data.height_unit);
          }
          setWeight(response.data.weight_kilo);
          if (
            response.data.target_weight === null ||
            response.data.target_weight === undefined
          ) {
            setTarget(response.data.weight_kilo);
          } else {
            setTarget(response.data.target_weight);
          }

          setWeightPounds(parseInt(response.data.weight_kilo * 2.208));
          setTargetPounds(parseInt(response.data.target_weight * 2.208));
          setHeight(response.data.height_feet);
          setHeightInch(response.data.height_inch);
          setHeightCm(
            parseInt(
              parseInt(response.data.height_feet) * 30.48 +
                parseInt(response.data.height_inch) * 2.54
            )
          );
          setLoading(false);
        }
      });
      let user = {
        secret_key: secretID === null ? "" : secretID,
      };

      getTokenUserSegmentation(user)
        .then(async (data) => {
          console.log("Token response: ", data);
          if (data.status === 200) {
            const token = data.data.token;
            console.log("MSISDN: ", data.data.msisdn);
            console.log("country: ", data.data.country);
            // localStorage.setItem("jwt", JSON.stringify(token));
            // localStorage.setItem("number", JSON.stringify(data.data.msisdn));
            // localStorage.setItem("country", data.data.country);
            //   console.log("token is ::", token);
            await getQuotes(param).then((response) => {
              if (response.status === 200) {
                //   console.log("quote api response is :::", response.data);
                setQuote(response.data.Quote);
                setAuthor(response.data.Author);
              }
            });

            await getAboutYouV3(token).then((response) => {
              // console.log("the response is :::", response);
              if (response.status === 200) {
                // console.log("set about you response is :::", response);
                setName(response.data.name);
                setGender(response.data.sex);
                if (response.data.dob === "0000-00-00") {
                  setDob(moment().format("YYYY-MM-DD"));
                } else {
                  setDob(response.data.dob);
                }
                setWeightUnit(response.data.weight_unit);
                if (
                  response.data.height_unit === "feet" ||
                  response.data.height_unit === "inches"
                ) {
                  setHeightUnit("ft:in");
                } else {
                  setHeightUnit(response.data.height_unit);
                }
                setWeight(response.data.weight_kilo);
                if (
                  response.data.target_weight === null ||
                  response.data.target_weight === undefined
                ) {
                  setTarget(response.data.weight_kilo);
                } else {
                  setTarget(response.data.target_weight);
                }

                setWeightPounds(parseInt(response.data.weight_kilo * 2.208));
                setTargetPounds(parseInt(response.data.target_weight * 2.208));
                setHeight(response.data.height_feet);
                setHeightInch(response.data.height_inch);
                setHeightCm(response.data.height_feet * 30.8);
                setLoading(false);
              }
            });
          } else {
            // window.location.replace("https://fitflexapp.com/checkuserlp");
          }
        })
        .catch((err) => {
          console.log(err);
          // window.location.replace("https://fitflexapp.com/checkuserlp");
        });
    };
    getData();
  }, []);

  const genderSet = (e) => {
    setGender(e.key);
  };

  const heightSet = (e) => {
    // console.log("e is :::", e);
    // console.log("inches are :::", e.split("'")[1]);
    // console.log("height is :::", e);
    if (heightUnit === "cm") {
      setHeightCm(Number(e));
      let x = Number(Number(e) / 2.54);
      let y = parseInt(x / 12);
      let z = x - y * 12;
      setHeight(y);
      setHeightInch(parseInt(z));
    } else {
      let a = e.split("'");
      setHeight(Number(a[0]));
      setHeightInch(Number(a[1]));
      let x = parseInt(parseInt(a[0]) * 30.48 + parseInt(a[1]) * 2.54);
      console.log("x is :::", x);
      setHeightCm(parseInt(x));
    }
  };

  // console.log("height in cm is :::", heightCm);
  // console.log("height in ft:in is :::", height);
  // console.log("height in inches is :::", heightInch);
  // console.log("dob is :::", dob);

  const weightSet = (e) => {
    // console.log("weight is :::", e);
    if (weightUnit === "Kg") {
      setWeight(parseInt(e));
      setTarget(parseInt(e));
      setWeightGram(0);
      setWeightPounds(parseInt(e * 2.204));
      setTargetPounds(parseInt(e * 2.204));
    } else {
      setWeightPounds(parseInt(e));
      setTargetPounds(parseInt(e));
      setWeightGram(0);
      setWeight(parseInt(e / 2.204));
      setTarget(parseInt(e / 2.204));
    }
    // const regex = /^[0-9]*(\.[0-9]*)?$/;
    // const inputValue = e.target.value.trim();
    // if (regex.test(inputValue) || inputValue === "") {
    //     console.log("weight value is ::::", Number(inputValue));
    //   let value = Number(inputValue);

    //   if (isNaN(value) || inputValue === "") {
    //     setWeight(0);
    //     setWeightPounds(0);
    //     setWeightGram(0);
    //   } else {
    //     if (weightUnit === "Kg") {
    //       setWeight(value);
    //       setWeightGram(Number(value.toString().split(".")[1]));
    //       //   console.log("weight gram is :::", weightGram);
    //       setWeightPounds(parseFloat(value * 2.204).toFixed(2));
    //     } else {
    //       setWeight(parseFloat(value / 2.204).toFixed(2));
    //       setWeightPounds(value);
    //     }
    //   }
    // }
  };

  // console.log("weight is :::", height);
  // console.log("weight pounds is ;::", heightCm);
  const targetSet = (e) => {
    if (weightUnit === "Kg") {
      setTarget(parseInt(e));
      setWeightGram(0);
      setTargetPounds(parseInt(e * 2.204));
    } else {
      setTargetPounds(parseInt(e));
      setWeightGram(0);
      setTarget(parseInt(e / 2.204));
    }

    // const regex = /^[0-9]*(\.[0-9]*)?$/;
    // const inputValue = e.target.value.trim();
    // if (regex.test(inputValue) || inputValue === "") {
    //   //   console.log("weight value is ::::", Number(inputValue));
    //   let value = Number(inputValue);

    //   if (isNaN(value) || inputValue === "") {
    //     setTarget(0);
    //     setTargetPounds(0);
    //   } else {
    //     if (weightUnit === "Kg") {
    //       setTarget(value);
    //       setTargetPounds(parseFloat(value * 2.204).toFixed(2));
    //     } else {
    //       setTarget(parseFloat(value / 2.204).toFixed(2));
    //       setTargetPounds(value);
    //     }
    //   }
    // }
  };

  const heightUnitSet = (e) => {
    setHeightUnit(e.key);
  };
  const weightUnitSet = (e) => {
    setWeightUnit(e.key);
    setTargetUnit(e.key);
  };

  const heightOptionsInFt = [];

  for (let feet = 4; feet <= 9; feet++) {
    for (let inches = 0; inches <= 11; inches++) {
      heightOptionsInFt.push({
        label: `${feet}'${inches}"`,
        value: `${feet}'${inches}`,
      });
    }
  }

  const weightOptionsKg = [];
  for (let kg = 33; kg <= 225; kg++) {
    weightOptionsKg.push({
      label: `${kg}`,
      value: `${kg}`,
    });
  }
  const weightOptionsLbs = [];
  for (let lbs = 72; lbs <= 495; lbs++) {
    weightOptionsLbs.push({
      label: `${lbs}`,
      value: `${lbs}`,
    });
  }

  const targetOptionsKg = [];
  for (let kg = parseInt(weight) - 5; kg <= parseInt(weight) + 5; kg++) {
    targetOptionsKg.push({
      label: `${kg}`,
      value: `${kg}`,
    });
  }

  const targetOptionsLbs = [];
  for (
    let lbs = parseInt(weightPounds) - 5;
    lbs <= parseInt(weightPounds) + 5;
    lbs++
  ) {
    targetOptionsLbs.push({
      label: `${lbs}`,
      value: `${lbs}`,
    });
  }

  //   console.log("heights in feet are :::", heightOptionsInFt);

  const heightOptionsInCm = [];
  for (let i = 120; i <= 302; i++) {
    heightOptionsInCm.push({ label: `${i} cm`, value: `${i}` });
  }

  const heightOptions =
    heightUnit === "cm" ? heightOptionsInCm : heightOptionsInFt;

  const weightOptions =
    weightUnit === "Kg" ? weightOptionsKg : weightOptionsLbs;

  const targetOptions =
    weightUnit === "Kg" ? targetOptionsKg : targetOptionsLbs;

  const heightUnit_menu = (
    <Menu>
      <Menu.Item key="cm" onClick={heightUnitSet}>
        cm
      </Menu.Item>
      <Menu.Item key="ft:in" onClick={heightUnitSet}>
        ft:in
      </Menu.Item>
    </Menu>
  );
  const weightUnit_menu = (
    <Menu>
      <Menu.Item key="Kg" onClick={weightUnitSet}>
        kg
      </Menu.Item>
      <Menu.Item key="lbs" onClick={weightUnitSet}>
        lbs
      </Menu.Item>
    </Menu>
  );
  const gender_menu = (
    <Menu>
      <Menu.Item key="male" onClick={genderSet}>
        Male
      </Menu.Item>
      <Menu.Item key="female" onClick={genderSet}>
        Female
      </Menu.Item>
      <Menu.Item key="other" onClick={genderSet}>
        Other
      </Menu.Item>
    </Menu>
  );

  const antIcon = (
    <LoadingOutlined
      style={{ fontSize: 45, color: "#FF1E1E", marginBottom: 20 }}
      spin
    />
  );
  if (loading) {
    return (
      <div class="loader">
        <Spin className="spinner" indicator={antIcon} tip="Loading" />
      </div>
    );
  }

  const aboutYouSet = async () => {
    const data = {
      dob: dob,
      name: name,
      sex: gender,
      weight_kilo: weight,
      weight_gram: weightGram,
      unit_weight: weightUnit,
      height_feet: height,
      height_inch: heightInch,
      unit_height: heightUnit,
      flag: "1",
      target_weight: target,
    };
    const token = JSON.parse(localStorage.getItem("jwt"));
    await setAboutYouV3(data, token).then((response) => {
      if (response.status === 200) {
        // console.log("process is working yayyyy", response);
        localStorage.setItem("user", JSON.stringify(data));
        localStorage.setItem("heightCm", heightCm);
        navigate("/LpGoal");
      }
    });
  };

  return (
    <div className="AboutYouContainer">
      <div className="leftImageContainer">
        <p
          style={{
            color: "white",
            fontSize: "30px",
            fontWeight: "bold",
            textAlign: "center",
            width: "50%",
          }}
        >
          " {quote} "
        </p>
        <p style={{ color: "#979797", fontWeight: "bold", fontSize: "20px" }}>
          {author}
        </p>
      </div>
      <div className="RightDiv">
        <div className="RightDivHeadings">
          <div className="RightDivTitle">
            <p className="RightDivTitleText">{t("get_started")}</p>
          </div>
          <div className="RightDivSc">
            <p className="RightDivScText">{t("provide_your_info")}</p>
          </div>
        </div>
        <div className="InputsDiv">
          <div className="nameDiv">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "80%",
              }}
            >
              <div className="imgAndInputDiv">
                <img src={userIcon} alt="userIcon" />
                <div className="titleAndInputDiv">
                  <p
                    style={{
                      color: "white",
                      fontSize: "12px",
                      margin: "0",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {t("name")}
                    <p
                      style={{
                        fontSize: "14px",
                        color: "red",
                        display: "inline",
                        marginLeft: "5px",
                        marginBottom: "0px",
                      }}
                    >
                      *
                    </p>
                  </p>
                </div>
              </div>
              <Input
                id="name"
                // placeholder="Name"
                label="Name"
                value={name}
                className="nameInput"
                onChange={(e) => {
                  setName(e.target.value);
                }}
                // prefix={<img src={userIcon} alt="userIcon" />}
              />
            </div>
          </div>
          <div className="genderAndBirthDiv">
            <Dropdown overlay={gender_menu} trigger={["click"]}>
              <div className="genderInput">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div className="imgAndInputDiv">
                    <img src={genderIcon} alt="genderIcon" />
                    <div className="titleAndInputDiv">
                      <p
                        style={{
                          color: "white",
                          fontSize: "14px",
                          margin: "0",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {t("gender")}
                        <p
                          style={{
                            fontSize: "14px",
                            color: "red",
                            display: "inline",
                            marginLeft: "5px",
                            marginBottom: "0px",
                          }}
                        >
                          *
                        </p>
                      </p>
                    </div>
                  </div>

                  <p
                    style={{
                      fontSize: "14px",
                      color: "#fff",
                      marginBottom: 0,
                      marginLeft: 10,
                      width: "100%",
                      marginTop: "10px",
                      textAlign: "left",
                      background: "rgb(22, 22, 22)",
                      padding: "6px  8px 6px 8px",
                      borderRadius: "8px",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      cursor: "pointer",
                      textTransform: "capitalize",
                    }}
                  >
                    {gender}
                    <img
                      src={arrowIcon}
                      alt="arrow icon"
                      style={{ width: "5%" }}
                    />
                  </p>
                </div>
              </div>
            </Dropdown>
            <div className="birthDiv">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div className="imgAndInputDiv">
                  <img src={birthIcon} alt="birthdayIcon" />
                  <div className="titleAndInputDiv">
                    <p
                      style={{
                        color: "white",
                        fontSize: "14px",
                        margin: "0",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {t("date_of_birth")}
                      <p
                        style={{
                          fontSize: "14px",
                          color: "red",
                          display: "inline",
                          marginLeft: "5px",
                          marginBottom: "0px",
                        }}
                      >
                        *
                      </p>
                    </p>
                  </div>
                </div>
                <DatePicker
                  placeholder="Date of Birth"
                  allowClear={false}
                  suffixIcon={false}
                  onChange={(e) => {
                    setDob(e.format("YYYY-MM-DD"));
                  }}
                  className="birthInput"
                  value={moment(dob)}
                  style={{ fontSize: "12px" }}
                />
              </div>
            </div>
          </div>
          <div className="heightInput">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  flexDirection: "row",
                }}
              >
                <img src={heightIcon} alt="heightIcon" />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    width: "80%",
                    marginLeft: "5px",
                  }}
                >
                  <p
                    style={{
                      color: "white",
                      fontSize: "10px",
                      margin: "0",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {t("height")}
                    <p
                      style={{
                        fontSize: "14px",
                        color: "red",
                        display: "inline",
                        marginLeft: "5px",
                        marginBottom: "0px",
                      }}
                    >
                      *
                    </p>
                  </p>
                  <Dropdown overlay={heightUnit_menu}>
                    <p style={{ color: "white", fontSize: "10px" }}>
                      {heightUnit} <img src={arrowIcon} alt="arrowIcon" />
                    </p>
                  </Dropdown>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <Select
                  value={
                    heightUnit === "ft:in"
                      ? `${height}'${heightInch}"`
                      : heightCm
                  }
                  style={{
                    width: "100%",
                    color: "#fff",
                    border: "none",
                    backgroundColor: "#161616",
                    borderRadius: "8px",
                    fontSize: "14px",
                    fontWeight: "500",
                    textAlign: "left",
                  }}
                  bordered={false}
                  defaultValue="Select Height"
                  options={heightOptions}
                  onChange={heightSet}
                  suffixIcon={
                    <img
                      src={arrowIcon}
                      alt="arrow icon"
                      style={{ width: "100%" }}
                    />
                  }
                ></Select>
              </div>
            </div>
          </div>
          <div className="floating-label-input">
            <div className="genderAndBirthDiv">
              <div className="weightInput">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      flexDirection: "row",
                    }}
                  >
                    <img src={weightIcon} alt="weightIcon" />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        width: "80%",
                        marginLeft: "5px",
                      }}
                    >
                      <p
                        style={{
                          color: "white",
                          fontSize: "10px",
                          margin: "0",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {t("weight")}
                        <p
                          style={{
                            fontSize: "14px",
                            color: "red",
                            display: "inline",
                            marginLeft: "5px",
                            marginBottom: "0px",
                          }}
                        >
                          *
                        </p>
                      </p>
                      <Dropdown overlay={weightUnit_menu}>
                        <p style={{ color: "white", fontSize: "10px" }}>
                          {weightUnit} <img src={arrowIcon} alt="arrowIcon" />
                        </p>
                      </Dropdown>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    <Select
                      value={
                        weightUnit === "Kg"
                          ? weight.toString()
                          : weightPounds.toString()
                      }
                      style={{
                        width: "100%",
                        color: "#fff",
                        border: "none",
                        backgroundColor: "#161616",
                        borderRadius: "8px",
                        fontSize: "14px",
                        fontWeight: "500",
                        textAlign: "left",
                      }}
                      bordered={false}
                      defaultValue="Select Weight"
                      options={weightOptions}
                      onChange={weightSet}
                      suffixIcon={
                        <img
                          src={arrowIcon}
                          alt="arrow icon"
                          style={{ width: "100%" }}
                        />
                      }
                    ></Select>
                  </div>
                </div>
                {/* <div className="heightSlider" style={{ width: "30%" }}>
                  <div
                    onClick={() => {
                      weightUnit === "Kg"
                        ? setWeight(weight - 1)
                        : setWeightPounds(weightPounds - 1);
                    }}
                  >
                    <img src={arrowIcon} alt={"arrowIcon"} />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <p
                      style={{
                        color: "white",
                        margin: "2px",
                        fontSize: "10px",
                        display:
                          weight - 1 === -1 || weightPounds - 1 === -1
                            ? "none"
                            : "flex",
                      }}
                    >
                      {weightUnit === "Kg" ? weight - 1 : weightPounds - 1}
                    </p>
                    <p
                      style={{
                        color: "white",
                        fontSize: "15px",
                        margin: "2px",
                      }}
                    >
                      {weightUnit === "Kg" ? weight : weightPounds}
                    </p>
                    <p
                      style={{
                        color: "white",
                        margin: "2px",
                        fontSize: "10px",
                      }}
                    >
                      {weightUnit === "Kg"
                        ? parseInt(weight) + 1
                        : parseInt(weightPounds) + 1}
                    </p>
                  </div>
                  <div
                    onClick={() => {
                      weightUnit === "Kg"
                        ? setWeight(parseInt(weight) + 1)
                        : setWeightPounds(parseInt(weightPounds) + 1);
                    }}
                  >
                    <img
                      src={arrowIcon}
                      alt="arrowIcon"
                      style={{ transform: "rotate(180deg)" }}
                    />
                  </div>
                </div> */}
              </div>
              <div className="weightInput">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      flexDirection: "row",
                    }}
                  >
                    <img src={targetWeightIcon} alt="targetWeightIcon" />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        width: "80%",
                        marginLeft: "5px",
                      }}
                    >
                      <p
                        style={{
                          color: "white",
                          fontSize: "10px",
                          margin: "0",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        Target
                        <p
                          style={{
                            fontSize: "14px",
                            color: "red",
                            display: "inline",
                            marginLeft: "5px",
                            marginBottom: "0px",
                          }}
                        >
                          *
                        </p>
                      </p>
                      <Dropdown overlay={weightUnit_menu}>
                        <p style={{ color: "white", fontSize: "10px" }}>
                          {targetUnit} <img src={arrowIcon} alt="arrowIcon" />
                        </p>
                      </Dropdown>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      flexDirection: "row",
                      width: "100%",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                  >
                    <Select
                      value={
                        weightUnit === "Kg"
                          ? target.toString()
                          : targetPounds.toString()
                      }
                      style={{
                        width: "100%",
                        color: "#fff",
                        border: "none",
                        backgroundColor: "#161616",
                        borderRadius: "8px",
                        fontSize: "14px",
                        fontWeight: "500",
                        textAlign: "left",
                      }}
                      bordered={false}
                      defaultValue="Select Weight"
                      options={targetOptions}
                      onChange={targetSet}
                      suffixIcon={
                        <img
                          src={arrowIcon}
                          alt="arrow icon"
                          style={{ width: "100%" }}
                        />
                      }
                    ></Select>
                  </div>
                </div>
                {/* <div className="heightSlider" style={{ width: "30%" }}>
                  <div
                    onClick={() => {
                      weightUnit === "Kg"
                        ? setTarget(target - 1)
                        : setTargetPounds(targetPounds - 1);
                    }}
                  >
                    <img src={arrowIcon} alt={"arrowIcon"} />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <p
                      style={{
                        color: "white",
                        margin: "2px",
                        fontSize: "10px",
                        display:
                          target - 1 === -1 || targetPounds - 1 === -1
                            ? "none"
                            : "flex",
                      }}
                    >
                      {weightUnit === "Kg" ? target - 1 : targetPounds - 1}
                    </p>
                    <p
                      style={{
                        color: "white",
                        fontSize: "15px",
                        margin: "2px",
                      }}
                    >
                      {weightUnit === "Kg" ? target : targetPounds}
                    </p>
                    <p
                      style={{
                        color: "white",
                        margin: "2px",
                        fontSize: "10px",
                      }}
                    >
                      {weightUnit === "Kg"
                        ? parseInt(target) + 1
                        : parseInt(targetPounds) + 1}
                    </p>
                  </div>
                  <div
                    onClick={() => {
                      weightUnit === "Kg"
                        ? setTarget(parseInt(target) + 1)
                        : setTargetPounds(parseInt(targetPounds) + 1);
                    }}
                  >
                    <img
                      src={arrowIcon}
                      alt="arrowIcon"
                      style={{ transform: "rotate(180deg)" }}
                    />
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>

        <Button className="continueButton" onClick={aboutYouSet}>
          {t("continue")}
        </Button>
      </div>
    </div>
  );
};

export default LpAboutYou;
