import React, { useEffect, useState } from "react";
import "../styles/southafricalp.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import apple from "../assets/images/apple.png";
import android from "../assets/images/android.png";
import { Spin } from "antd";
import {
  HeVodacomSouthAfrica,
  HeVodacomSouthAfricaACR,
  VodacomSouthAfrica,
  authenticate,
  authenticateSaUser,
  indonesiaMarketingLogs,
} from "../api/requests";
import { LoadingOutlined } from "@ant-design/icons";
import { ReactComponent as MySVG } from "../assets/images/Img.svg";
import { ReactComponent as ErrorSvg } from "../assets/images/errorImg.svg";
import { isAuthenticated } from "../api/requests";
const SouthAfricaLp = () => {
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [text, setText] = useState({
    content: "",
    secondary: "",
    status: false,
  });
  const navigate = useNavigate();
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: "#FF1E1E" }} spin />
  );

  let sub_status = searchParams.get("sub_status");
  let msisdn = searchParams.get("msisdn");
  let operater = searchParams.get("o");
  let error_code = searchParams.get("ec");
  let error_description = searchParams.get("ed");
  let operater_id = searchParams.get("op_id");
  let charge_status = searchParams.get("charge_status");
  let reason_code = searchParams.get("reason_code");
  let reason_desc = searchParams.get("reason_desc");

  console.log("sub_status ", sub_status);
  console.log("msisdn ", msisdn);
  console.log("operater: ", operater);
  console.log("error_code: ", error_code);
  console.log("error_description: ", error_description);
  console.log("op ID: ", operater_id);
  console.log("charge_status: ", charge_status);
  console.log("reason_code: ", reason_code);
  console.log("reason_desc: ", reason_desc);

  let payload = {
    package_id: 33,
    country_id: 197,
    msisdn: msisdn,
    // package_id: package_id,
    error_code: parseInt(error_code),
    error_description: error_description,
    opid: operater_id,
    sub_status: sub_status,
    charge_status: charge_status,
    reason_code: reason_code,
    reason_desc: reason_desc,
  };

  useEffect(() => {
    console.log("RUN AN EFFECT: ");

    switch (reason_code) {
      case "1000":
        setText({
          content: reason_desc,
          secondary: "Download The App Now & Start Your Fitness Journey",
          status: true,
        });
        break;
      case "1001":
        setText({
          content: "You are already a subscriber",
          secondary: "Download The App Now & Start Your Fitness Journey",
          status: false,
        });
        break;
      case "1002":
        setText({
          content: "Your subscription has failed due to insufficient balance",
          secondary: "To subscribe the service",
          status: false,
        });
        break;
      case "1005":
        setText({
          content: reason_desc,
          secondary: "To subscribe the service",
          status: false,
        });
        break;
      // case "1000":
      //   setText({
      //     content: reason_desc,
      //     secondary: "Please wait for confirmation message",
      //     status: false,
      //   });
      //   break;
      default:
        setText({
          content: reason_desc,
          secondary: "To subscribe the service",
          status: false,
        });
    }

    VodacomSouthAfrica(payload)
      .then((data) => {
        console.log("vodacom response: ", data);
        if (data.status === 200) {
          setLoading(true);
          // if (isAuthenticated !== false) {
          if (reason_code === "1000" || reason_code === "1001") {
            if (reason_code === "1001") {
              setLoading(false);
              console.log("hello sir how are you");
              let marketingLogPayload = {
                msisdn: msisdn,
                package_id: 33,
                country_id: 197,
                otp: "",
                MarketingUrl: window.location.href,
              };
              // indonesiaMarketingLogs(marketingLogPayload).then((response) => {
              //   // setLoading(false);
              //   // setError("Package is subscribed succesfully");
              //   // setTimeout(() => {
              //   //   setRedirectTo(true);
              //   // }, 1000);
              //   console.log("marketing logs are :::", response);
              // });

              let ncmsisdn = JSON.parse(localStorage.getItem("number"));
              console.log("ncmsisdn is :::", ncmsisdn);
              if (ncmsisdn) {
                console.log("hello sir how are you");
                // return;
                let payload = {
                  package_id: 33,
                  enc_msisdn: msisdn,
                  country_id: 197,
                  msisdn: ncmsisdn.slice(1, 12),
                };
                // console.log("he vodacom payload is :::: ", payload);
                console.log("hello warrior");
                HeVodacomSouthAfrica(payload)
                  .then((response) => {
                    console.log("he vodacom southafrica is :::", response);
                    if (response.status === 200) {
                      console.log("response is :::", response);
                      localStorage.setItem("encryptedMsisdn", msisdn);
                      // localStorage.setItem("jwt", response.data.token);

                      setTimeout(() => {
                        navigate("/home");
                      }, 2000);
                    }
                  })
                  .catch((error) => {
                    console.log("error is :::", error);
                  });
              } else {
                let payload = {
                  package_id: 33,
                  enc_msisdn: msisdn,
                  country_id: 197,
                  // msisdn: ncmsisdn.slice(1, 12),
                };
                // console.log("he vodacom payload is :::: ", payload);
                console.log("hello hello hello");
                HeVodacomSouthAfricaACR(payload)
                  .then((response) => {
                    console.log("he vodacom southafrica is :::", response);
                    if (response.status === 200) {
                      console.log("response is :::", response);
                      // localStorage.setItem("encryptedMsisdn", msisdn);
                      // localStorage.setItem("jwt", response.data.token);
                      setLoading(false);
                      authenticateSaUser(
                        response.data.token,
                        msisdn,
                        "ZA",
                        setTimeout(() => {
                          navigate("/home");
                        }, 2000)
                      );

                      // window.location.href = "https://fitflexapp.com/plans";
                    }
                  })
                  .catch((error) => {
                    console.log("error is :::", error);
                  });
              }
            } else {
              if (reason_code === "1000") {
                setLoading(false);
                let marketingLogPayload = {
                  msisdn: msisdn,
                  package_id: 33,
                  country_id: 197,
                  otp: "1",
                  MarketingUrl: window.location.href,
                };
                indonesiaMarketingLogs(marketingLogPayload).then((response) => {
                  // setLoading(false);
                  // setError("Package is subscribed succesfully");
                  // setTimeout(() => {
                  //   setRedirectTo(true);
                  // }, 1000);
                  console.log("marketing logs are :::", response);
                });

                // let ncmsisdn = JSON.parse(localStorage.getItem("number"));
                // console.log("msisdn is :::", ncmsisdn.slice(1, 12));
                let payload = {
                  package_id: 33,
                  enc_msisdn: msisdn,
                  country_id: 197,
                  // msisdn: ncmsisdn.slice(1, 12),
                };
                // console.log("he vodacom payload is :::: ", payload);
                // console.log("hello hello hello");
                HeVodacomSouthAfricaACR(payload)
                  .then((response) => {
                    console.log("he vodacom southafrica ACR is :::", response);
                    if (response.status === 200) {
                      console.log("response is :::", response);
                      localStorage.setItem("encryptedMsisdn", msisdn);
                      // localStorage.setItem("jwt", response.data.token);
                      setLoading(false);
                      authenticateSaUser(
                        response.data.token,
                        msisdn,
                        "ZA",
                        setTimeout(() => {
                          navigate("/home");
                        }, 2000)
                      );

                      // window.location.href = "http://qa.fitflexapp.com/plans";
                    }
                  })
                  .catch((error) => {
                    console.log("error is :::", error);
                  });
              }
            }
          } else {
            setLoading(false);
            setSuccess(false);
            setTimeout(() => {
              navigate("/salp");
            }, 2000);
            // navigate("/salp");
          }
        }
        // setLoading(false);
        // setSuccess(true);
        else {
          console.log("in else condition");
          // setSuccess(false);
          // setLoading(false);
        }
      })
      .catch((err) => {
        // setSuccess(false);
        // setLoading(false);
      });
  }, []);

  const buttonAntIcon = (
    <LoadingOutlined style={{ fontSize: 25, color: "white" }} spin />
  );

  function redirectionFunction() {
    setLoading(true);
    if (text.status === true) {
      window.location.replace("https://fitflexapp.com/checkuserlp");
    } else {
      // window.location.replace(
      //   "https://qa.fitflexapp.com:7172/sa_callback?package=daily"
      // );

      window.location.replace("http://fitflexapp.com/salp");
    }
  }

  return (
    <div className="Login-page-containerSA">
      <div className="containerLeftSA"></div>

      <div className="containerRightSA">
        <div className="title-label">
          <span className="titletext" style={{ color: "#ff1e1e" }}>
            FIT
          </span>
          <span className="titletext" style={{ color: "#ff1e1e" }}>
            FLEX
          </span>
          <div
            style={{
              display: "flex",
              with: "100%",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "5vh",
            }}
          >
            {text.status === true ? (
              <MySVG className="mysvg" />
            ) : (
              <ErrorSvg className="mysvg" />
            )}
            {loading ? (
              <Spin
                indicator={antIcon}
                tip="Please wait..."
                style={{
                  marginTop: 20,
                }}
              />
            ) : (
              <>
                <h1 className={"welcome-text-sa"}>{text.content}</h1>
                <h1 className={"secondary-text-sa"}>{text.secondary}</h1>
              </>
            )}
            {!loading && (
              <Spin
                indicator={antIcon}
                tip="Redirecting you to home page. Please Wait....."
                style={{
                  marginTop: 20,
                }}
              />
            )}
          </div>
          {!loading && <div class="foreground-div"></div>}
        </div>
        <div
          style={{
            marginTop: "50px",
            justifyContent: "center",
            display: "flex",
          }}
          className="margin-top-minus-on-small"
        >
          <a
            href="https://play.google.com/store/apps/details?id=fitflex.workout.fitness.weight.gym.fat.training"
            target="_blank"
          >
            <img
              src={android}
              style={{
                // height: "auto",
                marginRight: "5px",
              }}
              alt="playstore"
              className="storeimg"
            />
          </a>
          <a
            href="https://apps.apple.com/us/app/fitflex/id1613514782"
            target="_blank"
          >
            <img
              src={apple}
              style={{
                // height: "auto",
                border: "1px solid grey",
                borderRadius: "4px",
              }}
              alt="appstore"
              className="storeimg"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default SouthAfricaLp;
