import React, { useEffect, useState } from "react";
import { Modal, Slider, Button, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import MesoM from "../assets/Nutriton/MesoM.svg";
import moment from "moment";
import { useNavigate, useLocation } from "react-router-dom";
import { getBodyTypes, addNutritionPlan } from "../api/requests";
import EctoF from "../assets/Nutriton/EctoF.svg";
import EndoF from "../assets/Nutriton/EndoF.svg";
import EctoM from "../assets/Nutriton/EctoM.svg";
import EndoM from "../assets/Nutriton/EndoM.svg";
import MesoF from "../assets/Nutriton/MesoF.svg";
import { InformationCircleOutline } from "react-ionicons";
const NutritionActivity = () => {
  const [sliderValue, setSliderValue] = useState(0);
  const [calories, setCalories] = useState();
  const [isSubModalOpen, setIsSubModalOpen] = useState(false);
  const [isSubxModalOpen, setIsSubxModalOpen] = useState(false);
  const [bodyTypeIndex, setBodyTypeIndex] = useState();
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [loading, setLoading] = useState(true);
  const [rmr, setRmr] = useState();
  const location = useLocation();
  const [bodyType, setBodyType] = useState("");
  const navigate = useNavigate();
  console.log("the data is :::", location.state.data);
  const [bands, setBands] = useState({
    breafast: "",
    dinner: "",
    lunch: "",
    snack1: "",
    snack2: "",
    dinner: "",
    name: "",
  });

  console.log("slider value is :::", sliderValue);
  // console.log("data is :::", props.data);
  const marks = {
    0: "Very Light",
    2: "Light",
    4: "Moderate",
    6: "High",
    8: {
      style: {
        color: "#f50",
      },
      label: <strong>Extreme</strong>,
    },
  };

  const calculateRmr = () => {
    let RMR = "";
    let dob = moment(location.state.data.dob);
    console.log("dob is :::", dob);
    let currentday = moment(new Date());
    let age = currentday.diff(dob, "years");
    console.log("age is :::", age);
    if (location.state.data.gender === "female") {
      RMR =
        655.1 +
        9.56 * location.state.data.targetWeight.toString() +
        1.85 * location.state.data.heightInCm -
        4.68 * age;
    } else {
      RMR =
        66.47 +
        13.75 * location.state.data.targetWeight +
        5 * location.state.data.heightInCm -
        6.76 * age;
    }
    return RMR;
  };
  useEffect(() => {
    let value = calculateRmr();
    setRmr(value);
    setCalories(value * 1.3);
    // console.log("gender is :::", location.state.data.gender);

    const setGender = {
      gender:
        location.state.data.gender === "male"
          ? "Men"
          : location.state.data.gender === "female"
          ? "Women"
          : "Other",
    };
    const jwt = localStorage.getItem("jwt");
    // console.log("set gender is :::", setGender);
    const token = JSON.parse(jwt);
    getBodyTypes(token, setGender).then((response) => {
      console.log("body type response is :::", response);
      if (response.status === 200) {
        setBodyType(response.data);
        setLoading(false);
      }
    });
  }, []);
  const antIcon = (
    <LoadingOutlined
      style={{ fontSize: 45, color: "#FF1E1E", marginBottom: 20 }}
      spin
    />
  );

  if (loading) {
    return (
      <div class="loader">
        <Spin className="spinner" indicator={antIcon} tip="Loading" />
      </div>
    );
  }
  const bodyTypeSelection = (
    id,
    breakfast,
    dinner,
    snack1,
    snack2,
    lunch,
    name
  ) => {
    setBodyTypeIndex(id);
    console.log("breakfast are ::", breakfast);
    console.log("dinner is :::", dinner);
    console.log("snack1 is :::", snack1);
    console.log("snack2 is :::", snack2);
    console.log("lunch is ::::", lunch);
    let breakfastBand = (calories * breakfast) / 100;
    let dinnerBand = (calories * dinner) / 100;
    let snack1Band = (calories * snack1) / 100;
    let snack2Band = (calories * snack2) / 100;
    let lunchBand = (calories * lunch) / 100;
    setBands({
      breakfast: breakfastBand,
      dinner: dinnerBand,
      lunch: lunchBand,
      snack1: snack1Band,
      snack2: snack2Band,
      name: name,
    });
  };

  const calorieCalculations = (e) => {
    setSliderValue(e);
    if (e === 0) {
      setCalories(rmr * bodyType.physical_factor[0].Very_Light_Activity);
    } else if (e === 2) {
      setCalories(rmr * bodyType.physical_factor[0].Light_Activity);
    } else if (e === 4) {
      setCalories(rmr * bodyType.physical_factor[0].Moderate_Activity);
    } else if (e === 6) {
      setCalories(rmr * bodyType.physical_factor[0].Heavy_Activity);
    } else if (e === 8) {
      setCalories(rmr * bodyType.physical_factor[0].Extreme_Activity);
    } else {
      setCalories(rmr * 2.1);
    }
  };
  const nutritionPlan = () => {
    // console.log("props from about your are :::", location.state.data);
    // console.log("calories are :::", calories);
    if (bodyTypeIndex !== null) {
      const jwt = localStorage.getItem("jwt");
      const token = JSON.parse(jwt);
      console.log("bands are :::", bands);
      let payload = {
        target_weight: location.state.data.targetWeight,
        sex: location.state.data.gender,
        height_feet: location.state.data.height,
        height_inch: location.state.data.heightinInches,
        weight_kilo: location.state.data.weight,
        weight_gram: location.state.data.weightInGram,
        breakfast: bands.breakfast,
        lunch: bands.lunch,
        snack1: bands.snack1,
        snack2: bands.snack2,
        dinner: bands.dinner,
        body_type: bands.name,
        dob: location.state.data.dob,
        unit_weight: location.state.data.weighttype,
        unit_height: location.state.data.heightType,
        data: moment().format("YYYY-MM-DD"),
        calories_per_day: calories,
      };

      console.log("activity payload is :::", payload);

      addNutritionPlan(token, payload).then((response) => {
        console.log("nutrition response is :::", response);
        if (response.status === 200) {
          navigate("/mytrainingplan");
        }
      });
    }
  };
  // console.log("calories are :::", calories);
  // console.log("rmr is :::", rmr);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100vh",
        background: "black",
      }}
    >
      <Modal
        className="subModal"
        title="Information"
        visible={isSubModalOpen}
        onCancel={() => {
          setIsSubModalOpen(false);
        }}
        footer={false}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          {bodyType.body_types?.map((item, index) => {
            return (
              <>
                <h1
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    fontSize: "15px",
                    color: "#fff",
                    textAlign: "left",
                    width: "100%",
                  }}
                >
                  {item.name}
                </h1>
                <p
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    fontSize: "12px",
                    color: "#fff",
                  }}
                >
                  {item.decription}
                </p>
              </>
            );
          })}

          {/* <h1
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              fontSize: "15px",
              color: "#fff",
              textAlign: "left",
              width: "100%",
            }}
          >
            Mesomorph
          </h1>
          <p
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              fontSize: "12px",
              color: "#fff",
            }}
          >
            Having a husky muscular body build. Balanced nutrition is important
            both for keeping their health and most of all for those who want to
            gain results in sports activities.
          </p>
          <h1
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              fontSize: "15px",
              color: "#fff",
              textAlign: "left",
              width: "100%",
            }}
          >
            Endomorph
          </h1>
          <p
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              fontSize: "12px",
              color: "#fff",
            }}
          >
            Having a heavy rounded body build often with a marked tendency to
            become fat. An endomorph must watch what they eat and they must
            prove more discipline as regards to their diet, because calrie
            excess is rapidly turned into excess weight.
          </p> */}
        </div>
      </Modal>
      <Modal
        className="subModal"
        title="Information"
        visible={isSubxModalOpen}
        onCancel={() => {
          setIsSubxModalOpen(false);
        }}
        footer={false}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <p style={{ color: "white" }}>
            Any bodily movement produced by skeletal muscles that require energy
            expenditure. Physical activity refers to all movement including
            during leisure time, for transport to get to and from places,or as
            part of person's work.
          </p>
        </div>
      </Modal>
      <Modal
        className="NutritionActivityModal"
        title="Physical Acitivity"
        visible={isModalOpen}
        onCancel={() => {
          setIsModalOpen(false);
          navigate("/home");
        }}
        // onCancel={}
        // onOk={props.close}
        closable
        footer={false}
        style={{
          top: "30px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <h1 style={{ color: "white" }}>What's Your Activity level?</h1>
          <InformationCircleOutline
            style={{
              marginRight: "10px",
              marginBottom: 0,
            }}
            onClick={() => {
              setIsSubxModalOpen(true);
            }}
            color={"#fff"}
            height="30px"
            width="30px"
          />
        </div>
        <p style={{ color: "white" }}>
          To get the Nutrition Plan With Right Level Of Activity,Select the
          Option That Most Closely Matches You.
        </p>
        <div
          style={{
            width: "90%",
            margin: "20px",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <p
            style={{
              alignSelf: "center",
              color: "white",
              fontSize: "18px",
              width: "90%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Slide to Choose Physical Activity Level
          </p>
          <Slider
            onChange={(val) => {
              calorieCalculations(val);
            }}
            value={sliderValue}
            className="slider"
            marks={marks}
            step={10}
            defaultValue={0}
            max={8}
            min={0}
            draggableTrack={false}
            dots={true}
            trackStyle={{
              background: "linear-gradient(180deg, #FF5858 0%, #FF5B37 100%)",
              height: "9px",
            }}
            handleStyle={{
              height: "20px",
              width: "20px",
              background: "linear-gradient(180deg, #FF5858 0%, #FF5B37 100%)",
              border: 0,
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "row",
            width: "100%",
            background: "#282828",
            padding: "7px",
            borderRadius: "10px",
          }}
        >
          <p
            style={{
              color: "white",
              fontSize: "18px",
              width: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              marginLeft: "10px",
              marginBottom: 0,
            }}
          >
            Body Type
          </p>
          <InformationCircleOutline
            style={{
              marginRight: "10px",
              marginBottom: 0,
            }}
            onClick={() => {
              setIsSubModalOpen(true);
            }}
            color={"#fff"}
            height="20px"
            width="20px"
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
            width: "100%",
            padding: "20px",
          }}
        >
          {bodyType.body_types?.map((item, key) => {
            return (
              <div
                key={key}
                style={{
                  display: "flex",
                  alignContent: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  width: "30%",
                  padding: "10px",
                  borderRadius: "14px",
                  background: "#282828",
                  border: bodyTypeIndex === key ? "2px solid #FF5858" : null,
                }}
                onClick={() => {
                  bodyTypeSelection(
                    key,
                    item.Breakfast,
                    item.Dinner,
                    item.Snack1,
                    item.Snack2,
                    item.Lunch,
                    item.name
                  );
                }}
              >
                <p style={{ alignSelf: "center", color: "white" }}>
                  {item.name}
                </p>
                <img
                  src={
                    item.id === 4
                      ? location.state.data.gender === "female"
                        ? EctoF
                        : EctoM
                      : item.id === 5
                      ? location.state.data.gender === "female"
                        ? MesoF
                        : MesoM
                      : item.id === 6
                      ? location.state.data.gender === "female"
                        ? EndoF
                        : EndoM
                      : EndoM
                  }
                  style={{
                    width: "62%",
                    height: "20vh",
                    alignSelf: "center",
                  }}
                />
              </div>
            );
          })}
          {/* <div
            style={{
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
              flexDirection: "column",
              width: "30%",
              padding: "10px",
              borderRadius: "14px",
              background: "#282828",
            }}
          >
            <p style={{ alignSelf: "center", color: "white" }}>Mesomorph</p>
            <img
              src={MesoM}
              style={{ width: "50%", height: "50%", alignSelf: "center" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
              flexDirection: "column",
              width: "30%",
              padding: "10px",
              borderRadius: "14px",
              background: "#282828",
            }}
          >
            <p style={{ alignSelf: "center", color: "white" }}>Mesomorph</p>
            <img
              src={MesoM}
              style={{ width: "50%", height: "50%", alignSelf: "center" }}
            />
          </div> */}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Button
            onClick={() => {
              setIsModalOpen(false);
              navigate("/home");
            }}
            style={{
              width: "40%",
              borderRadius: "10px",
              background: "#282828",
              color: "white",
              border: "0",
              height: "7vh",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={nutritionPlan}
            style={{
              width: "40%",
              borderRadius: "10px",
              background: "linear-gradient(180deg, #FF5858 0%, #FF5B37 100%)",
              color: "white",
              border: "0",
              height: "7vh",
            }}
          >
            Confirm
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default NutritionActivity;
