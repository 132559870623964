import React from "react";
import { Link } from "react-router-dom";
import Header from "./header";
import { Layout } from "antd";

const NotFound = (props) => (
  <Layout className="layout">
    <Header />
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        backgroundColor: "#171717",
        justifyContent: "center",
      }}
    >
      <h1 style={{ color: "white" }}>Error 404: Not found</h1>
      <h1 style={{ color: "white" }}>Please Check Your Internet Connection</h1>
      <Link style={{ color: "#FF5858" }} to="/">
        Go Home
      </Link>
    </div>
  </Layout>
);

export default NotFound;
