import React from "react";
import ipad from "../assets/images/ipad.png";
import apple from "../assets/images/apple.png";
import android from "../assets/images/android.png";
import "../styles/myCss.css";
import { useTranslation } from "react-i18next";

const Workout = () => {
  const { t } = useTranslation();
  return (
    <div style={container}>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          flexDirection: "row",
          transform: `skew(0deg,8deg)`,
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            height: "5vh",
            background:
              "linear-gradient(276.14deg, #ff6f1e -32.94%, #ff1e1e 100%)",
            width: "50vw",
            color: "#fff",
          }}
        ></div>
      </div>
      <div className="getworkout-div">
        <div
          style={{ transform: `skew(0deg,-8deg)` }}
          className="set-on-responsive"
        >
          <p
            style={{
              // width: 400,
              fontWeight: "700",
              fontSize: "3vh",
              textAlign: "left",
              marginLeft: "15px",
            }}
            className="small-remove-text"
          >
            {t("workout_ho_title")}
          </p>

          <p
            style={{
              // width: "95%",
              fontWeight: "400",
              fontSize: "2vh",
              textAlign: "left",
              marginLeft: "15px",
            }}
            className="small-remove-text minus-margin"
          >
            {t("workout_ho_desc")}
          </p>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              // marginLeft: "15px",
            }}
            className="flexDir playstore-class"
          >
            <a
              href="https://play.google.com/store/apps/details?id=fitflex.workout.fitness.weight.gym.fat.training"
              target="_blank"
              className="small-remove"
            >
              <img
                src={android}
                style={{
                  width: 150,
                  // maxWidth: "100%",
                  height: "auto",
                  // marginRight: "4px",
                }}
                // className="small-div"
                alt="playstore"
              />
            </a>
            <a
              href="https://apps.apple.com/us/app/fitflex/id1613514782"
              target="_blank"
            >
              <img
                src={apple}
                style={{
                  width: 150,
                  // maxWidth: "100%",
                  height: "auto",
                  border: "1px solid grey",
                  borderRadius: "4px",
                  marginLeft: "10px",
                }}
                // className="small-div"

                alt="appstore"
              />
            </a>
          </div>
        </div>
        <div className="ipad-div">
          <img src={ipad} alt="ipad" className="ipad-image" />
        </div>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          transform: `skew(0deg,8deg)`,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            height: "5vh",
            background:
              "linear-gradient(276.14deg, #ff6f1e -32.94%, #ff1e1e 100%)",
            marginTop: "-1px",
            width: "50vw",
            color: "#fff",
            float: "left",
          }}
        ></div>
      </div>
    </div>
  );
};
export default Workout;
const container = {
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  marginTop: 100,
  marginBottom: 40,
  // paddingLeft: "30px",
};
