import React, { useState } from "react";
import { Button, Modal, Input, Spin } from "antd";
import "../styles/settings.css";
import { getNumber } from "../api/requests";
import flag from "../assets/images/flag.png";
import iraq from "../assets/images/iraq.png";
import indonesia from "../assets/images/indonesia.png";
import { deleteAccount } from "../api/requests";
import { useNavigate } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const DeactivateModal = (props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [number, setNumber] = useState("");
  const [error, setError] = useState("");
  const [color, setColor] = useState("red");
  const [loading, setLoading] = useState(false);
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: "#FF1E1E" }} spin />
  );
  const navigate = useNavigate();
  const token = JSON.parse(localStorage.getItem("jwt"));
  const country = localStorage.getItem("country");

  let loginNumber = getNumber();
  let numberLength = country === "IND" ? 11 : 10;

  console.log("Country: ", country);
  console.log("Number: ", loginNumber);

  const deleteProcess = () => {
    setLoading(true);
    if (number === "") {
      setColor("red");
      setError("Please enter the number for confirmation");
      setLoading(false);
      return;
    }
    let numberEntered;
    if (country === "PK") {
      numberEntered = "0" + number;
    } else if (country === "IQ") {
      numberEntered = "" + number;
    } else {
      numberEntered = "" + number;
    }

    if (numberEntered !== loginNumber) {
      setLoading(false);
      setColor("red");
      setError("Enter Your Own Mobile Number");
    } else {
      deleteAccount(token).then((res) => {
        console.log("the server is responding with status", res.status);
        if (res.status === 200) {
          setLoading(false);
          localStorage.clear();
          navigate("/login");
        }
      });
    }
  };

  if (loading) {
    return (
      <div className="loader">
        <Spin
          className="spinner"
          indicator={antIcon}
          tip="Deactivating Your Account..."
        />
      </div>
    );
  }

  return (
    <div className="modal-div-deactive">
      <Button onClick={() => setOpen(true)} className="deactivate-Button">
        {t("deactivate")}
      </Button>

      <Modal
        className="modal-class"
        title={t("delete")}
        visible={open}
        onOk={deleteProcess}
        onCancel={() => {
          setOpen(false);
          setError("");
          setNumber("");
        }}
        bodyStyle={{
          color: "white",
          backgroundColor: "#171717",
        }}
        okText={t("delete_btn")}
        cancelText={t("cancel")}
      >
        <p
          style={{
            fontWeight: "600",
            fontSize: "15px",
            marginBottom: 5,
          }}
        >
          {t("deleting_msg_title")}
        </p>
        <ul>
          <li>{t("deleting_msg_1")}</li>
          <li>{t("deleting_msg_2")}</li>
          <li>{t("deleting_msg_3")}</li>
        </ul>
        <p
          style={{
            fontWeight: "600",
            fontSize: "15px",
            marginBottom: 5,
          }}
        >
          {t("delete_confirm_msg")}
        </p>
        <p
          className="ttext"
          style={{
            color: color,
            marginTop: 20,
            marginBottom: 0,
            display: error != "" ? "flex" : "none",
          }}
        >
          {error}
        </p>
        <div className="inputdivSettings">
          <span className="myDropdown">
            <img src={flag} className="flagstyle" />
          </span>
          <Input
            className="inputfeild"
            value={number}
            maxLength={numberLength}
            // onKeyDown={_handleKeyDown}
            // onChange={(e) => setNumber(e.target.value)}
            onChange={(e) => {
              const re = /^[0-9\b]+$/;
              // if value is not blank, then test the regex
              if (e.target.value === "" || re.test(e.target.value)) {
                setNumber(e.target.value);
              }
            }}
            placeholder={"XXXXXXXXXX"}
            prefix={
              <span
                style={{
                  fontSize: 14,
                }}
              >
                {country === "IQ" ? "+964" : country === "IND" ? "+62" : "+92"}
              </span>
            }
          />
        </div>
      </Modal>
    </div>
  );
};

export default DeactivateModal;
