import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "../Skeleton/Styles/ExerciseSkeleton.css";

export const ExerciseCircuitSkeleton = () => {
  return (
    <div className="ExCircuitSkeleton">
      <Skeleton
        containerClassName="skeleton"
        baseColor="#1a1a1a"
        highlightColor="#fff"
        height={"15px"}
        width={"25%"}
      />
      <Skeleton
        containerClassName="skeleton"
        baseColor="#1a1a1a"
        highlightColor="#fff"
        height={"80px"}
        width={"55%"}
      />
    </div>
  );
};
export const ExerciseSkeletonScreen = () => {
  return (
    <div className="Eximg-and-details-div">
      <div className="Eximg-div">
        <Skeleton
          containerClassName="skeleton-1"
          baseColor="#1a1a1a"
          highlightColor="#808080"
          borderRadius={"15px"}
          //   height={"350px"}
          width={"100%"}
        />
      </div>
      <div className="Exdetails-div">
        <Skeleton
          containerClaasName="skeleton2"
          baseColor="#1a1a1a"
          highlightColor="#808080"
        />
        <Skeleton
          containerClassName="skeleton"
          baseColor="#1a1a1a"
          highlightColor="#808080"
          width={"60%"}
          height={"20px"}
          style={{
            marginBottom: "20px",
          }}
        />

        <Skeleton
          containerClassName="skeleton"
          baseColor="#1a1a1a"
          highlightColor="#808080"
          width={"50%"}
          height={"20px"}
          style={{
            marginBottom: "20px",
          }}
        />
        <Skeleton
          containerClassName="skeleton"
          baseColor="#1a1a1a"
          highlightColor="#808080"
          width={"100%"}
          height={"20px"}
          style={{
            marginBottom: "20px",
          }}
        />
        <div
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Skeleton
            containerClassName="skeleton"
            baseColor="#1a1a1a"
            highlightColor="#fff"
            width={"60%"}
            height={"50px"}
          />
        </div>
      </div>
    </div>
  );
};

export const ExerciseVideosSkeleton = () => {
  return (
    <div className="ExerciseVideosContainer">
      <div className="SingleExerciseDiv">
        <div className="ExerciseImageDiv">
          <Skeleton
            containerClassName="ExerciseSkeleton"
            baseColor="#1a1a1a"
            highlightColor="#fff"
            // height={"250px"}
            width={"100%"}
          />
        </div>
        <div className="ExerciseDetailsDiv">
          <Skeleton
            containerClassName="skeleton"
            baseColor="#1a1a1a"
            highlightColor="#fff"
            height={"20px"}
            width={"100%"}
          />
          <Skeleton
            containerClassName="skeleton"
            baseColor="#1a1a1a"
            highlightColor="#fff"
            height={"20px"}
            width={"50%"}
          />
        </div>
      </div>
      <div className="SingleExerciseDiv">
        <div className="ExerciseImageDiv">
          <Skeleton
            containerClassName="ExerciseSkeleton"
            baseColor="#1a1a1a"
            highlightColor="#fff"
            // height={"250px"}
            width={"100%"}
          />
        </div>
        <div className="ExerciseDetailsDiv">
          <Skeleton
            containerClassName="skeleton"
            baseColor="#1a1a1a"
            highlightColor="#fff"
            height={"20px"}
            width={"100%"}
          />
          <Skeleton
            containerClassName="skeleton"
            baseColor="#1a1a1a"
            highlightColor="#fff"
            height={"20px"}
            width={"50%"}
          />
        </div>
      </div>
    </div>
  );
};
