import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "../Skeleton/Styles/TrainingPlanSkeleton.css";

export const TrainingVideosSkeleton = () => {
  return (
    <div className="TrVideosContainer">
      <div className="TrVideosAndDetails">
        <div className="TrVideoImage">
          <Skeleton
            containerClassName="skeleton"
            baseColor="#1a1a1a"
            highlightColor="#fff"
            height={"140px"}
            width={"100%"}
          />
        </div>
        <div className="TrDetails">
          <Skeleton
            containerClassName="skeleton"
            baseColor="#1a1a1a"
            highlightColor="#fff"
            height={"20px"}
            width={"100%"}
          />
          <Skeleton
            containerClassName="skeleton"
            baseColor="#1a1a1a"
            highlightColor="#fff"
            height={"20px"}
            width={"50%"}
          />
        </div>
      </div>
      <div className="TrVideosAndDetails">
        <div className="TrVideoImage">
          <Skeleton
            containerClassName="skeleton"
            baseColor="#1a1a1a"
            highlightColor="#fff"
            height={"140px"}
            width={"100%"}
          />
        </div>
        <div className="TrDetails">
          <Skeleton
            containerClassName="skeleton"
            baseColor="#1a1a1a"
            highlightColor="#fff"
            height={"20px"}
            width={"100%"}
          />
          <Skeleton
            containerClassName="skeleton"
            baseColor="#1a1a1a"
            highlightColor="#fff"
            height={"20px"}
            width={"50%"}
          />
        </div>
      </div>
      <div className="TrVideosAndDetails">
        <div className="TrVideoImage">
          <Skeleton
            containerClassName="skeleton"
            baseColor="#1a1a1a"
            highlightColor="#fff"
            height={"140px"}
            width={"100%"}
          />
        </div>
        <div className="TrDetails">
          <Skeleton
            containerClassName="skeleton"
            baseColor="#1a1a1a"
            highlightColor="#fff"
            height={"20px"}
            width={"100%"}
          />
          <Skeleton
            containerClassName="skeleton"
            baseColor="#1a1a1a"
            highlightColor="#fff"
            height={"20px"}
            width={"50%"}
          />
        </div>
      </div>
      <div className="TrVideosAndDetails">
        <div className="TrVideoImage">
          <Skeleton
            containerClassName="skeleton"
            baseColor="#1a1a1a"
            highlightColor="#fff"
            height={"140px"}
            width={"100%"}
          />
        </div>
        <div className="TrDetails">
          <Skeleton
            containerClassName="skeleton"
            baseColor="#1a1a1a"
            highlightColor="#fff"
            height={"20px"}
            width={"100%"}
          />
          <Skeleton
            containerClassName="skeleton"
            baseColor="#1a1a1a"
            highlightColor="#fff"
            height={"20px"}
            width={"50%"}
          />
        </div>
      </div>
      <div className="TrVideosAndDetails">
        <div className="TrVideoImage">
          <Skeleton
            containerClassName="skeleton"
            baseColor="#1a1a1a"
            highlightColor="#fff"
            height={"140px"}
            width={"100%"}
          />
        </div>
        <div className="TrDetails">
          <Skeleton
            containerClassName="skeleton"
            baseColor="#1a1a1a"
            highlightColor="#fff"
            height={"20px"}
            width={"100%"}
          />
          <Skeleton
            containerClassName="skeleton"
            baseColor="#1a1a1a"
            highlightColor="#fff"
            height={"20px"}
            width={"50%"}
          />
        </div>
      </div>
      <div className="TrVideosAndDetails">
        <div className="TrVideoImage">
          <Skeleton
            containerClassName="skeleton"
            baseColor="#1a1a1a"
            highlightColor="#fff"
            height={"140px"}
            width={"100%"}
          />
        </div>
        <div className="TrDetails">
          <Skeleton
            containerClassName="skeleton"
            baseColor="#1a1a1a"
            highlightColor="#fff"
            height={"20px"}
            width={"100%"}
          />
          <Skeleton
            containerClassName="skeleton"
            baseColor="#1a1a1a"
            highlightColor="#fff"
            height={"20px"}
            width={"50%"}
          />
        </div>
      </div>
      <div className="TrVideosAndDetails">
        <div className="TrVideoImage">
          <Skeleton
            containerClassName="skeleton"
            baseColor="#1a1a1a"
            highlightColor="#fff"
            height={"140px"}
            width={"100%"}
          />
        </div>
        <div className="TrDetails">
          <Skeleton
            containerClassName="skeleton"
            baseColor="#1a1a1a"
            highlightColor="#fff"
            height={"20px"}
            width={"100%"}
          />
          <Skeleton
            containerClassName="skeleton"
            baseColor="#1a1a1a"
            highlightColor="#fff"
            height={"20px"}
            width={"50%"}
          />
        </div>
      </div>
      <div className="TrVideosAndDetails">
        <div className="TrVideoImage">
          <Skeleton
            containerClassName="skeleton"
            baseColor="#1a1a1a"
            highlightColor="#fff"
            height={"140px"}
            width={"100%"}
          />
        </div>
        <div className="TrDetails">
          <Skeleton
            containerClassName="skeleton"
            baseColor="#1a1a1a"
            highlightColor="#fff"
            height={"20px"}
            width={"100%"}
          />
          <Skeleton
            containerClassName="skeleton"
            baseColor="#1a1a1a"
            highlightColor="#fff"
            height={"20px"}
            width={"50%"}
          />
        </div>
      </div>
    </div>
  );
};

export const TrainingSkeletonScreen = () => {
  return (
    <div className="Trimg-and-details-div">
      <div className="Trimg-div">
        <Skeleton
          containerClassName="skeleton-1"
          baseColor="#1a1a1a"
          highlightColor="#808080"
          borderRadius={"15px"}
          //   height={"350px"}
          width={"100%"}
        />
      </div>
      <div className="Trdetails-div">
        <Skeleton
          containerClaasName="skeleton2"
          baseColor="#1a1a1a"
          highlightColor="#808080"
        />
        <Skeleton
          containerClassName="skeleton"
          baseColor="#1a1a1a"
          highlightColor="#808080"
          width={"60%"}
          height={"20px"}
          style={{
            marginBottom: "20px",
          }}
        />

        <Skeleton
          containerClassName="skeleton"
          baseColor="#1a1a1a"
          highlightColor="#808080"
          width={"50%"}
          height={"20px"}
          style={{
            marginBottom: "20px",
          }}
        />
        <Skeleton
          containerClassName="skeleton"
          baseColor="#1a1a1a"
          highlightColor="#808080"
          width={"100%"}
          height={"20px"}
          style={{
            marginBottom: "20px",
          }}
        />
        <div
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Skeleton
            containerClassName="skeleton"
            baseColor="#1a1a1a"
            highlightColor="#fff"
            width={"60%"}
            height={"50px"}
          />
        </div>
      </div>
    </div>
  );
};

export const TrainingPlanCard = () => {
  return (
    <div className="trainingPlanCardDiv">
      <Skeleton
        baseColor="#1a1a1a"
        highlightColor="#fff"
        height={"80px"}
        width={"90%"}
        borderRadius={"15px"}
        style={{ marginTop: "20px" }}
        containerClassName="trainingPlanCardSkeleton"
      />
      <Skeleton
        baseColor="#1a1a1a"
        highlightColor="#fff"
        height={"80px"}
        width={"90%"}
        borderRadius={"15px"}
        style={{ marginTop: "20px" }}
        containerClassName="trainingPlanCardSkeleton"
      />
      <Skeleton
        baseColor="#1a1a1a"
        highlightColor="#fff"
        height={"75px"}
        width={"90%"}
        borderRadius={"15px"}
        style={{ marginTop: "20px" }}
        containerClassName="trainingPlanCardSkeleton"
      />
    </div>
  );
};
