import React, { useState, useEffect } from "react";
import "../styles/newIraqLp.css";
// import "../styles/loginMarketingDirect.css";
import flag from "../assets/images/saudi_arab.svg";
import apple from "../assets/images/app_store_opt.png";
import android from "../assets/images/play_store_opt.png";
import { Button, Input, Spin, Dropdown, Menu, Modal } from "antd";
import {
  MobilePackage,
  iraqLandingCharge,
  isAuthenticated,
} from "../api/requests";
import OTPInput, { ResendOTP } from "otp-input-react";
import { LoadingOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import MarketPlan from "../components/marketingPlan";
import { useSearchParams } from "react-router-dom";
import { GlobalOutlined } from "@ant-design/icons";
import Fitflex from "../assets/images/fitflex_logo.png";
import zainLogo from "../assets/images/zainLogo.svg";
import Arrow from "../assets/images/arrow-n.svg";
import { CloseOutline } from "react-ionicons";

// const antIcon = (
//   <LoadingOutlined style={{ fontSize: 24, color: "#FF1E1E" }} spin />
// );

const SaudiaArabiaLp = () => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [isOtp, setIsOtp] = useState(false);
  const [error, setError] = useState("");
  const [number, setNumber] = useState("");
  const [OTP, setOTP] = useState("");
  const [redirectTo, setRedirectTo] = useState(false);
  const [payments, setPayments] = useState([]);
  const [packageValue, setPackageValue] = useState("");
  const [resendStatus, setResendStatus] = useState(false);
  const [resendTimer, setResendTimer] = useState(180);
  const [redirectLoading, setRedirectLoading] = useState(false);
  const [packageName, setPackageName] = useState();
  const [packagePrice, setPackagePrice] = useState();
  const [packageDescription, setPackageDescription] = useState();
  const [terms, setTerms] = useState([]);
  const [lang, setLang] = useState(i18n.language);
  const [searchParams] = useSearchParams();
  const [open, setOpen] = useState(false);
  // console.log("Search Params: ", searchParams.get("msisdn").substring(3));

  let headerEnrichmentNumber = searchParams.get("msisdn");

  let tokenCheck = isAuthenticated();

  useEffect(() => {
    let payload = {
      country_id: 187,
      operator: "KSA-ZAIN",
      language_id: 9,
    };

    if (headerEnrichmentNumber !== null) {
      setNumber(searchParams.get("msisdn").substring(3));
    }

    MobilePackage(payload)
      .then((data) => {
        if (data.status === 200) {
          console.log("QATAR: ", data);
          // setPayments(data.data);
          setLoading(false);
          setPackageName(data.data[0].package_name);
          setPackagePrice(data.data[0].package_rate);
          setPackageDescription(data.data[0].package_description);
          setTerms(data.data[0].terms_and_conditions);
          setPackageValue(data.data[0].package_id);
        }
      })
      .catch((err) => console.log(err));

    i18n.changeLanguage("ar");
    setLang("ar");
  }, []);

  useEffect(() => {
    if (resendStatus) {
      resendTimer > 0 &&
        setTimeout(() => setResendTimer(resendTimer - 1), 1000);
    }
  }, [resendTimer, resendStatus]);

  const renderButton = () => {
    return resendTimer <= 0 ? (
      <button
        style={{ cursor: "pointer" }}
        className="resend-ar-text"
        onClick={() => {
          LoginFunction();
          setResendTimer(60);
          setResendStatus(true);
        }}
      >
        {t("resendotp")}
      </button>
    ) : (
      <button
        className="resend-ar-text"
        style={{ direction: lang === "ar" ? "rtl" : "ltr" }}
      >
        {t("otp_expire")}{" "}
        <span
          style={{
            color: "#1E1E1E",
            fontFamily: "Manrope",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: "600",
            lineHeight: "normal",
          }}
        >
          {resendTimer}{" "}
        </span>
        {t("seconds")}
      </button>
    );
  };
  const renderTime = () => React.Fragment;

  function checkFirstTwoCharacters(str) {
    const forbiddenPatterns = /^(58|59)/; // Regular expression pattern

    // Check if the string matches any of the forbidden patterns
    return forbiddenPatterns.test(str);
  }

  const LoginFunction = () => {
    setError("");
    if (number == "") {
      console.log("Empty Check");
      setError("enter_valid_number");
      return;
    } else if (checkFirstTwoCharacters(number) === false) {
      console.log("Invaalid Substring check");
      setError("enter_valid_number");
      return;
    } else {
      console.log("Test Clear");
      setLoading(true);
      let payload = {
        msisdn: "966" + number,
        package_id: packageValue,
        country_id: 187,
      };
      console.log("ksa payload is :::", payload);
      iraqLandingCharge(payload)
        .then((data) => {
          console.log("My Payloadss", data);
          if (data.status === 200) {
            if (data.data.status === 200) {
              setResendStatus(true);
              setLoading(false);
              setError("");
              setIsOtp(true);
            } else {
              setLoading(false);
              setError(data.data.msg);
            }
          } else {
            setLoading(false);
            setError(`${t("login_not")}`);
          }
        })
        .catch((err) => setError(`${t("login_not")}`));
    }
  };

  const onSubmit = () => {
    if (OTP.length !== 4) {
      setError(`${t("otp_digit")}`);
    } else {
      setLoading(true);
      let otpPayload = {
        msisdn: "966" + number,
        package_id: packageValue,
        country_id: 187,
        otp: OTP,
        marketPartner: "web",
      };

      console.log("otp payload is :::", otpPayload);

      iraqLandingCharge(otpPayload)
        .then((data) => {
          console.log("response data: ", data);
          if (data.status === 200) {
            if (data.data.status === 200) {
              setLoading(false);
              setError(`${t("package_subscribed")}`);
              setTimeout(() => {
                setRedirectTo(true);
              }, 1000);
            } else {
              setLoading(false);
              setError(data.data.msg);
            }
          } else {
            setLoading(false);
            setError(`${t("invalid_otp")}`);
          }
        })
        .catch((err) => {
          console.log("saudi arabia error is :::", error);
          setError(`${t("invalid_otp")}`);
          setLoading(false);
        });
    }
  };

  if (redirectTo) {
    window.location.replace("https://fitflexapp.com/checkuserlp");
  }

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: "#FF1E1E" }} spin />
  );

  if (redirectLoading) {
    return (
      <div className="loader">
        <Spin
          className="spinner"
          indicator={antIcon}
          tip="Fetching Details..."
        />
      </div>
    );
  }

  const buttonAntIcon = (
    <LoadingOutlined style={{ fontSize: 25, color: "white" }} spin />
  );

  function toggleClick(language) {
    setLoading(true);
    i18n.changeLanguage(language);
    setLang(language);
    let payload = {
      operator: "KSA-ZAIN",
      country_id: 187,
      language_id: language === "en" ? 7 : 9,
    };

    MobilePackage(payload)
      .then((data) => {
        if (data.status === 200) {
          console.log('dadadadadad"""" :::', data.data);
          // setPayments(data.data);
          setLoading(false);
          setPackageName(data.data[0].package_name);
          setPackagePrice(data.data[0].package_rate);
          setPackageDescription(data.data[0].package_description);
          setTerms(data.data[0].terms_and_conditions);
          setPackageValue(data.data[0].package_id);
        }
      })
      .catch((err) => console.log(err));
  }
  function handleMenuClick(e) {
    e.key === "1"
      ? toggleClick("ar")
      : e.key === "2"
      ? toggleClick("en")
      : e.key === "3"
      ? toggleClick("id")
      : toggleClick("en");
  }

  const menu_iq = (
    <Menu onClick={handleMenuClick} className="menu_item">
      <Menu.Item key="1">Arabic</Menu.Item>
      <Menu.Item key="2">English</Menu.Item>
    </Menu>
  );

  return (
    // <div className="Login-page-containerMk">
    //   <div className="containerLeftMk">
    //     <div className="termsMk">
    //       {payments.length > 0 &&
    //         payments.map((item, index) => {
    //           return (
    //             <div className="marketCardMk" key={index}>
    //               <MarketPlan
    //                 type={`${t("subscription_plan")}`}
    //                 rate={t(item.package_name) + ` SAR ` + item.package_rate}
    //                 duration={item.package_description}
    //                 description={""}
    //                 term={item.terms_and_conditions}
    //                 countryName={"KSA"}
    //               />
    //             </div>
    //           );
    //         })}
    //     </div>
    //   </div>

    //   <div className="containerRightMk">
    //     <div className="titleDivMk">
    //       <div style={{ display: "inline-block" }}>
    //         <span className="titletextMk" style={{ color: "#ff1e1e" }}>
    //           FIT
    //         </span>
    //         <span className="titletextMk" style={{ color: "#ff1e1e" }}>
    //           FLEX
    //         </span>
    //       </div>
    //     </div>

    //     {isOtp ? (
    //       <>
    //         <h1 className={"welcomeMk"}>{t("enter_otp")}</h1>
    //         <p className={"ttextMk"}>
    //           <ResendOTP
    //             renderButton={renderButton}
    //             renderTime={renderTime}
    //             onResendClick={() => console.log("Resend clicked")}
    //           />
    //         </p>
    //         <OTPInput
    //           value={OTP}
    //           onChange={setOTP}
    //           autoFocus={false}
    //           OTPLength={4}
    //           otpType="number"
    //           disabled={false}
    //           inputClassName="otpinputMk"
    //           className="otpMk"
    //         />
    //         <p
    //           className="ttextMk"
    //           style={{
    //             color: "red",
    //             marginTop: 20,
    //             marginBottom: 0,
    //             display: error != "" ? "flex" : "none",
    //           }}
    //         >
    //           {error}
    //         </p>
    //         {loading ? (
    //           <div className="loading-div">
    //             <Spin indicator={antIcon} tip="Verifying OTP..." />
    //           </div>
    //         ) : (
    //           <Button className={"btn-loginMk"} onClick={onSubmit}>
    //             {t("Click to continue")}
    //           </Button>
    //         )}
    //       </>
    //     ) : (
    //       <>
    //         <h1 className={"welcomeMk"}>Start Your Fitness Journey Today</h1>

    //         <p className={"ttextMk"}>Enter your Zain mobile number</p>
    //         <div className={"inputdivMk"}>
    //           <img src={flag} className="flagstyle" />
    //           <Input
    //             className="inputfeildMk"
    //             type="number"
    //             value={number}
    //             onChange={(e) => setNumber(e.target.value)}
    //             placeholder="XXXXXXXXXX"
    //             bordered={false}
    //             prefix={
    //               <span
    //                 style={{
    //                   fontSize: 14,
    //                 }}
    //               >
    //                 +966
    //               </span>
    //             }
    //           />
    //         </div>
    //         <p
    //           className="ttextMk"
    //           style={{
    //             color: "red",
    //             marginTop: 20,
    //             marginBottom: 0,
    //             display: error != "" ? "flex" : "none",
    //           }}
    //         >
    //           {error}
    //         </p>
    //         {loading ? (
    //           <div className="loading-div">
    //             <Spin indicator={antIcon} tip="Loading..." />
    //           </div>
    //         ) : (
    //           <Button className={"btn-loginMk"} onClick={LoginFunction}>
    //             {t("Click to continue")}
    //           </Button>
    //         )}
    //       </>
    //     )}

    //     <div className="margin-top-minus-on-smallMk">
    //       <a
    //         href="https://play.google.com/store/apps/details?id=fitflex.workout.fitness.weight.gym.fat.training"
    //         target="_blank"
    //       >
    //         <img
    //           src={android}
    //           style={{
    //             marginRight: "5px",
    //           }}
    //           alt="playstore"
    //           className="storeimg"
    //         />
    //       </a>
    //       <a
    //         href="https://apps.apple.com/us/app/fitflex/id1613514782"
    //         target="_blank"
    //       >
    //         <img
    //           src={apple}
    //           style={{
    //             border: "1px solid grey",
    //             borderRadius: "4px",
    //           }}
    //           alt="appstore"
    //           className="storeimg"
    //         />
    //       </a>
    //     </div>
    //   </div>
    // </div>

    <>
      <Modal
        // title="Basic Modal"
        closable
        visible={open}
        closeIcon={<CloseOutline />}
        // onOk={handleOk}
        onCancel={() => setOpen(false)}
        footer={null}
        style={{
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          borderRadius: "20px",
          padding: "20px",
        }}
        bodyStyle={{
          borderRadius: "20px",
          padding: "30px",
        }}
      >
        <h1
          style={{
            color: "#000",
            textAlign: "center",
            fontFamily: " Manrope",
            fontSize: "20px",
            fontStyle: "normal",
            fontWeight: "600",
            lineHeight: "92.6%",
            marginBottom: "20px",
            marginTop: "15px",
          }}
        >
          {t("terms_and_conditions")}
        </h1>
        {terms?.map((item, index) => {
          return (
            <React.Fragment key={index}>
              <ul style={{ direction: lang === "ar" ? "rtl" : "ltr" }}>
                <li
                  style={{
                    color: " #434343",
                    fontFamily: "Manrope",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "normal",
                  }}
                >
                  {item}
                </li>
              </ul>
            </React.Fragment>
          );
        })}
        {/* // <p>Some contents...</p>
      // <p>Some contents...</p> */}
      </Modal>
      <div className="main-container-ar-div">
        <div className="main-left-container-ar-div">
          {/* <p className="main-left-heading-one">FITFLEX</p> */}
          <Dropdown overlay={menu_iq}>
            <a
              className="dropdown-ar-ln"
              style={{ fontFamily: "Manrope" }}
              onClick={handleMenuClick}
            >
              {lang.toUpperCase()}
              <GlobalOutlined
                style={{ color: "#ff1e1e", marginLeft: "20px" }}
              />
            </a>
          </Dropdown>

          <img src={Fitflex} alt="fitflexLogo" />
          <p className="main-left-heading-ar-two">
            {t("world_class_fitness_app")}
          </p>
          <p className="main-left-heading-ar-three">{t("enjoy_videos")}</p>
          {isOtp ? (
            <>
              <p className="enter-number-and-otp-ar-text">
                Enter Your 4 digit pin here
              </p>
              <OTPInput
                value={OTP}
                onChange={setOTP}
                autoFocus={false}
                OTPLength={4}
                otpType="number"
                disabled={false}
                // secure
                style={{
                  justifyContent: "center",
                }}
                inputClassName="otpinputDiv-ar"
                className="otp-ar-box"
              />
              <ResendOTP
                renderButton={renderButton}
                renderTime={renderTime}
                onResendClick={() => console.log("Resend clicked")}
              />
              <p className="error-text">{error}</p>
              <button
                className="continueButton-ar-new"
                onClick={() => onSubmit()}
              >
                {loading ? <Spin indicator={buttonAntIcon} /> : t("continue_2")}
              </button>
            </>
          ) : (
            <>
              <p className="enter-number-and-otp-ar-text">
                {t("enter_number")}
              </p>
              <div className="input-ar-div">
                <img
                  src={flag}
                  alt="flagIcon"
                  className="flagstyle"
                  style={{ marginLeft: "5px", width: "10%" }}
                />
                <Input
                  className="inputfield-ar"
                  type="number"
                  value={number}
                  onChange={(e) => setNumber(e.target.value)}
                  placeholder="XXX XXXXXXX"
                  bordered={false}
                  prefix={
                    <>
                      <div
                        style={{
                          borderLeft: "1px solid #6f6f6f",
                          height: "30px",
                        }}
                      ></div>
                      <span
                        style={{
                          fontSize: 16,
                          color: "#6f6f6f",
                          // borderLeft: "1px solid black",
                          paddingLeft: "10px",
                          // height: "25px",
                        }}
                      >
                        +966
                      </span>
                    </>
                  }
                  suffix={
                    <>
                      <img src={zainLogo} alt="operatorlogo" />
                    </>
                  }
                />
              </div>

              <p className="error-ar-text">{error}</p>

              <button
                className="continueButton-ar-new"
                onClick={() => LoginFunction()}
              >
                {loading ? <Spin indicator={buttonAntIcon} /> : t("continue_2")}
              </button>
              {lang !== "ar" ? (
                <p className="package-ar-text">
                  {" "}
                  *SAR {packagePrice}
                  {packageDescription}
                </p>
              ) : (
                <p className="package-ar-text" style={{ direction: "rtl" }}>
                  *SAR {packagePrice}
                  {packageDescription}
                </p>
              )}
            </>
          )}
          <button
            className="termsAndConditionBtn-ar"
            style={{
              flexDirection: lang === "ar" ? "row-reverse" : "row",
              fontSize: lang === "ar" && "17px",
            }}
            onClick={() => {
              setOpen(true);
            }}
          >
            <img src={Arrow} alt="arrow" />
            <div
              style={{ height: "15px", border: "0.5px solid rgba(0, 0, 0, 1)" }}
            ></div>
            {t("terms_and_conditions")}
          </button>
        </div>
        <div className="main-right-container-ar-div">
          {/* <img
      src={GirlDancingImg}
      style={{ width: "66%", height: "100%" }}
      alt="girlDancing"
    /> */}
        </div>
      </div>
    </>
  );
};

export default SaudiaArabiaLp;
