import React, { useState } from "react";
import "../styles/Quiz.css";
import TrueSvg from "../assets/game/true.svg";
import FalseSvg from "../assets/game/false.svg";
import MultipleChoiceImg from "../assets/game/MultipleChoiceImg.png";
import TruefalseImg from "../assets/game/TruefalseImg.png";
import SkipImg from "../assets/game/Skip.svg";
import HintImg from "../assets/game/Hint.svg";
import { skipQuestionApi } from "../api/requests";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const Question = ({ questionData, handleAnswer }) => {
  const [question, setQuestion] = useState(questionData);
  const [isHint, setIsHint] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleHint = () => {
    setIsHint(true);
  };

  const skipQuestion = async () => {
    setLoading(true);
    try {
      let body = {
        level: question.id,
      };
      let token = JSON.parse(localStorage.getItem("jwt"));
      console.log("question token is ;::", token);
      const response = await skipQuestionApi(token);
      if (response.status === 200) {
        setLoading(false);
        setQuestion(response.data);
      }
    } catch (error) {
      console.log("error is :::", error);
    }
  };

  const Loader = () => {
    const buttonAntIcon = (
      <LoadingOutlined style={{ fontSize: 24, color: "#FF1E1E" }} spin />
    );
    return <Spin indicator={buttonAntIcon} />;
  };

  console.log("question is :::", question);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="questionContainer">
          <img
            src={
              question.options.length <= 2 ? TruefalseImg : MultipleChoiceImg
            }
            className="multipleChoiceImg"
          />
          <p className="question-text">
            {question.options.length <= 2
              ? "True or false"
              : "Choose the best Option?"}
          </p>
          <p className="question-text">{question.question}</p>
          <div className="answer-container">
            {question.options.map((item, index) => {
              return (
                <button
                  onClick={() => {
                    handleAnswer(
                      item.trim().toLowerCase(),
                      question.answer.trim().toLowerCase()
                    );
                  }}
                  className="answer-button"
                  style={{
                    display:
                      isHint === true
                        ? question.hint.trim().toLowerCase() !== "none" &&
                          question.hint.trim().toLowerCase() ===
                            item.trim().toLowerCase() &&
                          "none"
                        : "flex",
                  }}
                >
                  {question.options.length <= 2 && (
                    <img
                      src={
                        item.trim().toLowerCase() === "true"
                          ? TrueSvg
                          : FalseSvg
                      }
                      style={{ marginRight: "20px" }}
                    />
                  )}
                  {item}
                </button>
              );
            })}
          </div>
          <div className="skipAndHintButton-container">
            <button
              className="skipAndHintButton"
              onClick={handleHint}
              style={{ background: question.options.length <= 2 && "#737373" }}
              disabled={question.options.length <= 2 ? true : false}
            >
              <img
                src={HintImg}
                style={{
                  position: "absolute",
                  left: 0,
                  top: "-50%",
                  width: "90%",
                  transform: "translate(-10%,10%)",
                }}
              />
              Hint
            </button>
            <button
              onClick={() => {
                skipQuestion();
              }}
              className="skipAndHintButton"
            >
              <img
                src={SkipImg}
                style={{
                  position: "absolute",
                  left: 0,
                  top: "-50%",
                  width: "90%",
                  transform: "translate(-10%,35%)",
                }}
              />{" "}
              Skip
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Question;
