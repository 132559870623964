import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "../Skeleton/Styles/WorkoutSkeleton.css";
export const SkeletonScreen = () => {
  return (
    <div className="img-and-details-div">
      <div className="img-div">
        <Skeleton
          containerClassName="Singleskeleton"
          baseColor="#1a1a1a"
          highlightColor="#808080"
          //   height={"350px"}
          width={"100%"}
        />
      </div>
      <div className="details-div">
        <Skeleton
          containerClaasName="skeleton"
          baseColor="#1a1a1a"
          highlightColor="#808080"
        />
        <Skeleton
          containerClassName="skeleton"
          baseColor="#1a1a1a"
          highlightColor="#808080"
          width={"60%"}
          height={"20px"}
          style={{
            marginBottom: "10px",
          }}
        />

        <Skeleton
          containerClassName="skeleton"
          baseColor="#1a1a1a"
          highlightColor="#808080"
          width={"50%"}
          height={"20px"}
          style={{
            marginBottom: "10px",
          }}
        />
        <Skeleton
          containerClassName="skeleton"
          baseColor="#1a1a1a"
          highlightColor="#808080"
          width={"100%"}
          height={"20px"}
          style={{
            marginBottom: "10px",
          }}
        />
        <div
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Skeleton
            containerClassName="skeleton"
            baseColor="#1a1a1a"
            highlightColor="#fff"
            width={"60%"}
            height={"50px"}
          />
        </div>
      </div>
    </div>
  );
};

export const SkeletonParagraph = () => {
  return (
    <div className="paragraph-div">
      <Skeleton
        baseColor="#1a1a1a"
        highlightColor="#fff"
        containerClassName="skeleton"
        width={"100%"}
        height={"30px"}
      />
    </div>
  );
};

export const WorkoutVideosSkeleton = () => {
  return (
    <div className="workoutVideosContainer">
      <div className="singleWorkoutVideosDiv">
        <div className="WorkoutImgDiv">
          <Skeleton
            containerClassName="Workoutskeleton"
            baseColor="#1a1a1a"
            highlightColor="#fff"
            // height={"180px"}
            width={"100%"}
          />
        </div>
        <div className="WorkoutDetailsDiv">
          <Skeleton
            containerClassName="skeleton"
            baseColor="#1a1a1a"
            highlightColor="#fff"
            height={"20px"}
            width={"100%"}
          />
          <Skeleton
            containerClassName="skeleton"
            baseColor="#1a1a1a"
            highlightColor="#fff"
            height={"20px"}
            width={"50%"}
          />
        </div>
      </div>
      <div className="singleWorkoutVideosDiv">
        <div className="WorkoutImgDiv">
          <Skeleton
            containerClassName="Workoutskeleton"
            baseColor="#1a1a1a"
            highlightColor="#fff"
            // height={"180px"}
            width={"100%"}
          />
        </div>
        <div className="WorkoutDetailsDiv">
          <Skeleton
            containerClassName="skeleton"
            baseColor="#1a1a1a"
            highlightColor="#fff"
            height={"20px"}
            width={"100%"}
          />
          <Skeleton
            containerClassName="skeleton"
            baseColor="#1a1a1a"
            highlightColor="#fff"
            height={"20px"}
            width={"50%"}
          />
        </div>
      </div>
    </div>
  );
};
