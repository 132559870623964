import React, { useEffect, useState } from "react";
import SiteHeader from "../components/header";
import CalIcon from "../assets/images/calIcon.svg";
import { useNavigate } from "react-router-dom";
import { getFavNutrition } from "../api/requests";
import { Link } from "react-router-dom";

const FavouriteNutrition = () => {
  const [fav, setFav] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    const getFav = async () => {
      const token = JSON.parse(localStorage.getItem("jwt"));
      await getFavNutrition(token).then((response) => {
        if (response.status === 200) {
          console.log("get fav nutrition response is :::", response);
          setFav(response.data);
        }
      });
    };
    getFav();
  }, []);
  return (
    <div className="mainContainerDiv">
      <div style={{ marginBottom: "60px" }}>
        <SiteHeader />
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#000",
        }}
      >
        <h1
          style={{
            color: "#fff",
            width: "90%",
            textAlign: "left",
            fontSize: "22px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {fav !== null ? "Favourite Meals" : ""}
          <Link
            to={"/Nutritionfav"}
            onClick={() => {
              localStorage.removeItem("favNutrition");
              localStorage.setItem("favNutrition", JSON.stringify(fav));
            }}
            onFocus={() => {
              localStorage.removeItem("favNutrition");
              localStorage.setItem("favNutrition", JSON.stringify(fav));
            }}
          >
            <p
              style={{
                // color: "white",
                justifySelf: "flex-start",
                display: "flex",
                color: "#ff5858",
                fontSize: "20px",
                margin: "2px",
              }}
            >
              {fav !== null ? (fav.length > 4 ? "View more" : " ") : " "}
            </p>
          </Link>
        </h1>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "90%",
            justifyContent: "flex-start",
            alignItems: "center",
            flexWrap: "nowrap",
            marginTop: "20px",
            // overflowX: "scroll",
          }}
        >
          <div className="NutritionFavDiv">
            {fav !== null &&
              fav.slice(0, 4).map((item, index) => {
                return (
                  <div
                    className="mealCardDiv"
                    // style={{ margin: "5px", width: "100%", cursor: "pointer" }}
                    onClick={() => {
                      navigate("/nutritiondetails", {
                        state: {
                          id: item.id,
                          band: item.band_first,
                          title: item.meal,
                        },
                      });
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        width: " 300px",
                        background: "#1a1a1a",
                        padding: "20px",
                        borderRadius: "10px",
                      }}
                    >
                      <div>
                        <img
                          style={{ width: "100%", borderRadius: "10px" }}
                          src={item.dishes[0].dish_landscape_image}
                          alt="breakfast"
                        />
                      </div>
                      <div style={{ width: "100%" }}>
                        <p
                          style={{
                            color: "white",
                            textAlign: "left",
                            marginTop: "10px",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {item.dishes[0].name}
                        </p>
                      </div>
                      <div className="macroNutrientsDiv">
                        <div
                          className="nameAndGramDiv"
                          style={{ flexDirection: "row" }}
                        >
                          <img
                            src={CalIcon}
                            style={{ width: "20%", marginRight: "5px" }}
                            alt="calIcon"
                          />
                          <p style={{ color: "white", margin: 0 }}>
                            {item.dishes[0].calories}
                          </p>
                          <p style={{ color: "white", margin: 0 }}>Kcal</p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};
export default FavouriteNutrition;
