import React from "react";
import "../styles/fitness.css";
import { Steps, Icon } from "antd";
import dot from "../assets/images/dot.png";
import { useTranslation } from "react-i18next";

const { Step } = Steps;
const FitnessGoals = () => {
  const { t } = useTranslation();

  return (
    <div className="outer-container">
      <div className="left-div">
        <div className="left-align">
          <h2 className="small-heading" style={{ paddingTop: "30px" }}>
            {t("fitness_title")}
          </h2>
          <h1 className="big-heading">{t("fitness_sc_title")}</h1>
          {/* <h1 className="big-heading">
            Start your Fitness Journey with 400+ HD Quality videos, 2000+
            exercises and 100+ Audio guided experiences.
          </h1> */}
          <p className="para-text">{t("fitness_desc")}</p>
          <div className="steps">
            <Steps
              progressDot={dot}
              current={5}
              direction="vertical"
              responsive={true}
              // className="steps"
            >
              <Step title={t("fitness_bull_1")} style={{ fontWeight: "700" }} />
              <Step title={t("fitness_bull_2")} style={{ fontWeight: "700" }} />
              <Step title={t("fitness_bull_3")} style={{ fontWeight: "700" }} />
              {/* <Step
              title="Wide variety of workout categories"
              style={{ fontWeight: "700" }}
            /> */}
              <Step title={t("fitness_bull_4")} style={{ fontWeight: "700" }} />
            </Steps>
          </div>
        </div>
      </div>

      <div className="right-div">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {/* <img src={fitness} height="440px" width="400px" className="picture" /> */}
          <div
            style={
              {
                // width: "413px",
                // height: "526px",
              }
            }
          >
            <video
              src={"https://prod.fitflexapp.com/web/003.mp4"}
              className="mobile-vid"
              muted
              autoPlay
              loop
            ></video>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FitnessGoals;
