import React, { useEffect, useRef, useState } from "react";
import SiteHeader from "../components/header";
import { useLocation, useNavigate } from "react-router-dom";
import ReactPlayer from "react-player/lazy";
import Timer from "../assets/images/Timer.png";
import "../styles/exercise.css";
import { FlameOutline } from "react-ionicons";
import { StopwatchOutline } from "react-ionicons";
import { CloseOutline } from "react-ionicons";
import { Button } from "antd";
import { PauseOutline } from "react-ionicons";
import { PlaySharp } from "react-ionicons";
import { PlaySkipForwardSharp } from "react-ionicons";
import { PlaySkipBackSharp } from "react-ionicons";

const ExercisePlayer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const exerciseData = location.state.exercisePlayer;
  const phases = Object.keys(exerciseData);
  const [completed, setCompleted] = useState(false);
  const [rounds, setRounds] = useState(1);
  const [index, setIndex] = useState(0);
  const [secondIndex, setSecondIndex] = useState(0);
  const [check, setCheck] = useState(false);
  const [pause, setPause] = useState(false);
  const [rest, setRest] = useState(false);
  const [phaseIndex, setPhaseIndex] = useState(0);
  const [countDown, setCountDown] = useState(
    exerciseData[phases[phaseIndex]][index].duration_in_seconds
    // "00"
  );

  // const playerRef = useRef();
  // window.onbeforeunload = function () {
  //   console.log("window is reloading");
  //   setPause(false);
  //   setCheck(false);
  // };
  console.log("exrrcsda:", exerciseData[phases[0]]);

  let timer = "";

  useEffect(() => {
    if (!check) {
      timer =
        countDown > 0 && setInterval(() => setCountDown(countDown - 1), 1000);
    } else {
      timer = countDown > 0 && setInterval(() => setCountDown(countDown), 1000);
    }
    if (countDown === 0) {
      if (
        rounds < exerciseData[phases[phaseIndex]][index].rounds &&
        rest === false
      ) {
        setRounds(rounds + 1);
        setCountDown(
          exerciseData[phases[phaseIndex]][index].duration_in_seconds
        );
      } else {
        setRounds(1);
        nextExercise();
      }
    }
    return () => {
      clearInterval(timer);
    };
  }, [countDown, check]);

  const nextExercise = () => {
    setPause(false);
    setCheck(false);
    setRest(!rest);
    if (Array.isArray(exerciseData[phases[phaseIndex]])) {
      if (
        Array.isArray(exerciseData[phases[phaseIndex]][index].circuit_detail)
      ) {
        if (
          secondIndex ===
          exerciseData[phases[phaseIndex]][index].circuit_detail.length - 1
        ) {
          // setSecondIndex(0);
          console.log("second index full");
          if (
            index === exerciseData[phases[phaseIndex]].length - 1 &&
            rest === true
          ) {
            setIndex(index);
            setSecondIndex(secondIndex);
            setCountDown(
              exerciseData[phases[phaseIndex]][index]
                .rest_after_exercise_in_seconds
            );
          } else if (
            index === exerciseData[phases[phaseIndex]].length - 1 &&
            rest === false
          ) {
            if (phaseIndex === phases.length - 1) {
              setCompleted(true);
              setPhaseIndex(0);
              setIndex(0);
              setSecondIndex(0);
            } else {
              setPhaseIndex(phaseIndex + 1);
              setIndex(0);
              setSecondIndex(0);
              setCountDown(
                exerciseData[phases[phaseIndex]][index]
                  .rest_after_exercise_in_seconds
              );
            }
          } else {
            if (rest === true) {
              setIndex(index);
              setSecondIndex(secondIndex);
              setCountDown(
                exerciseData[phases[phaseIndex]][index]
                  .rest_after_exercise_in_seconds
              );
            } else {
              setIndex(index + 1);
              setSecondIndex(0);
              setCountDown(
                exerciseData[phases[phaseIndex]][index].duration_in_seconds
              );
            }
          }
        } else {
          if (
            !rest &&
            exerciseData[phases[phaseIndex]][index]
              .rest_after_exercise_in_seconds > 1
          ) {
            // setRest(false);
            console.log("hello rest is triggered");
            setCountDown(
              exerciseData[phases[phaseIndex]][index]
                .rest_after_exercise_in_seconds
            );
            setSecondIndex(secondIndex + 1);
            setRounds(1);
          } else {
            setRest(false);
            // setSecondIndex(secondIndex);
            setCountDown(
              exerciseData[phases[phaseIndex]][index].duration_in_seconds
            );
          }
        }
      }
      // else {
      //   if (!rest) {
      //     setCountDown(
      //       exerciseData[phases[phaseIndex]][index]
      //         .rest_after_exercise_in_seconds
      //     );
      //   } else {
      //     setCountDown(
      //       exerciseData[phases[phaseIndex]][index].duration_in_seconds
      //     );
      //   }
      // }
    }
  };
  console.table({
    phaseIndex: phaseIndex,
    index: index,
    secondIndex: secondIndex,
    countDown: countDown,
    rest: rest,
  });

  const backExercise = () => {
    if (!Array.isArray(exerciseData[phases[phaseIndex]])) {
      if (index === 0) {
        setIndex(0);
        setCountDown(30);
      } else {
        setIndex(index - 1);
        setCountDown(30);
      }
    } else {
      if (secondIndex === 0) {
        setSecondIndex(0);
        setCountDown(30);
      } else {
        setSecondIndex(secondIndex - 1);
        setCountDown(30);
      }
    }
  };
  const pauseExercise = () => {
    setPause(!pause);
    setCheck(!check);
  };

  console.log("exercise player data is :::", exerciseData);
  if (completed) {
    return (
      <div className="exercises-container-div">
        <div style={{ marginBottom: "50px" }}>
          <SiteHeader />
        </div>

        <div className="congratulationsAndContinueDiv">
          <div className="congratulationsDiv">
            <h1 style={{ color: "white", fontWeight: "800" }}>GREAT JOB!</h1>
            <p style={{ color: "white", fontWeight: "600" }}>
              You have completed {} exercises in {location.state.duration / 60}{" "}
              mins
            </p>
            <p style={{ color: "white", fontWeight: "600" }}>
              Level: {location.state.level}
            </p>
          </div>
          <div className="exerciseNameCongDiv">
            <p style={{ color: "white", fontWeight: "600" }}>
              {location.state.name} completed
            </p>
          </div>
          <div className="timeAndCaloriesDiv">
            <div className="timeDiv">
              <StopwatchOutline
                color={"#ffffff"}
                beat
                height="50px"
                width="250px"
              />
              <p style={{ color: "white", fontWeight: "600" }}>Total Time</p>
              <p style={{ color: "white", fontWeight: "600" }}>
                {location.state.duration / 60} mins
              </p>
            </div>
            <div className="CaloriesDiv">
              <FlameOutline
                color={"#ffffff"}
                beat
                height="50px"
                width="250px"
              />
              <p style={{ color: "white", fontWeight: "600" }}>
                Total Burned Calories
              </p>
              <p style={{ color: "white", fontWeight: "600" }}>
                {location.state.calories}
              </p>
            </div>
          </div>
          <Button
            className="continue-Button"
            onClick={() => navigate("/exercise")}
          >
            Continue
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="exercises-container-div">
      <div style={{ marginBottom: "50px" }}>
        <SiteHeader />
      </div>
      <div className="exerciseVideoDiv">
        {/*  {rest === false ? (
          <ReactPlayer
            className="reactPlayerDiv"
            url={
              Array.isArray(location.state.exercisePlayer[index])
                ? location.state.exercisePlayer[index][secondIndex].data.video
                : location.state.exercisePlayer[index].data.video
            }
            playing={!pause}
            onReady={(event) => onLoad(event)}
            // style={{
            //   background: "#F9F9F9",
            // }}
            width={"100%"}
            height={"680px"}
            config={{
              file: {
                attributes: {
                  style: {
                    width: "100%",
                    height: "100%",
                    padding: "0",
                    margin: "0",
                    display: "flex",
                  },
                },
              },
            }}
            // onPause={(event) => pauseExercise(event)}
            // onDuration={(duration) => {
            //   setDuration(duration);
            // }}
          />
        ) : ( */}
        <div className="videoGifDiv" style={{ opacity: !rest ? "1" : "0.7" }}>
          {!rest ? (
            <img
              className="videoGif"
              // src={
              //   !Array.isArray(location.state.exercisePlayer[index])
              //     ? Array.isArray(location.state.exercisePlayer[index + 1])
              //       ? location.state.exercisePlayer[index + 1][secondIndex]
              //           .data[!pause ? "gif-large" : "image-large"]
              //       : location.state.exercisePlayer[
              //           index + 1 != location.state.exercisePlayer.length
              //             ? index + 1
              //             : index
              //         ].data[!pause ? "gif-large" : "image-large"]
              //     : location.state.exercisePlayer[index][
              //         secondIndex + 1 ===
              //         location.state.exercisePlayer[index].length
              //           ? secondIndex
              //           : secondIndex + 1
              //       ].data[!pause ? "gif-large" : "image-large"]
              // }
              src={
                // !rest
                !pause
                  ? exerciseData[phases[phaseIndex]][index].circuit_detail[
                      secondIndex
                    ].gif_large
                  : exerciseData[phases[phaseIndex]][index].circuit_detail[
                      secondIndex
                    ].image_large
                // : exerciseData[phases[phaseIndex]][index].circuit_detail[
                //     secondIndex
                //   ].image_large
              }
              // width={"500px"}
              // height={"520px"}
              //   height={"100%"}
            />
          ) : (
            <img
              className="videoGif"
              // src={
              //   !Array.isArray(location.state.exercisePlayer[index])
              //     ? Array.isArray(location.state.exercisePlayer[index + 1])
              //       ? location.state.exercisePlayer[index + 1][secondIndex]
              //           .data[!pause ? "gif-large" : "image-large"]
              //       : location.state.exercisePlayer[
              //           index + 1 != location.state.exercisePlayer.length
              //             ? index + 1
              //             : index
              //         ].data[!pause ? "gif-large" : "image-large"]
              //     : location.state.exercisePlayer[index][
              //         secondIndex + 1 ===
              //         location.state.exercisePlayer[index].length
              //           ? secondIndex
              //           : secondIndex + 1
              //       ].data[!pause ? "gif-large" : "image-large"]
              // }
              src={
                !pause
                  ? exerciseData[phases[phaseIndex]][index].circuit_detail[
                      secondIndex
                    ].gif_large
                  : exerciseData[phases[phaseIndex]][index].circuit_detail[
                      secondIndex
                    ].image_large
                // : exerciseData[phases[phaseIndex]][index].circuit_detail[
                //     secondIndex
                //   ].image_large
              }
              // width={"500px"}
              // height={"520px"}
              //   height={"100%"}
            />
          )}
        </div>
        {/* )} */}

        <div className="exerciseNameDiv">
          {index !== exerciseData[phases[phaseIndex]].length && (
            <p
              style={{
                color: !rest ? "black" : "white",
                fontSize: "30px",
                fontWeight: "700",
              }}
              className="timerText"
            >
              {rest === false
                ? Array.isArray(exerciseData[phases[phaseIndex]])
                  ? exerciseData[phases[phaseIndex]][index].phase_name
                  : exerciseData[phases[phaseIndex]][index].phase_name
                : "Coming Up Next"}
            </p>
          )}
        </div>
        <div className="exerciseAndRoundsDiv">
          <p
            style={{
              color: !rest ? "black" : "white",
              fontSize: "30px",
              fontWeight: "600",
              display: Array.isArray(exerciseData[phases[phaseIndex]])
                ? "flex"
                : "none",
            }}
            className="timerText"
          >
            {Array.isArray(exerciseData[phases[phaseIndex]]) &&
              "Exercise " +
                (secondIndex + 1) +
                "/" +
                " " +
                exerciseData[phases[phaseIndex]][index].circuit_detail.length}
          </p>
          <p
            style={{
              color: !rest ? "black" : "white",
              fontSize: "30px",
              fontWeight: "600",
            }}
            className="timerText"
          >
            {"Round " +
              rounds +
              "/" +
              " " +
              exerciseData[phases[phaseIndex]][index].rounds}
          </p>
        </div>
        <div
          className="crossButton"
          onClick={() => {
            navigate(-1);
          }}
        >
          <CloseOutline
            color={!rest ? "#00000" : "#ffffff"}
            height="50px"
            width="50px"
          />
        </div>
        <div className="timerDiv">
          <p
            style={{
              color: !rest ? "black" : "white",
              fontSize: "30px",
              fontWeight: "600",
            }}
            className="timer1Text"
          >
            <img
              src={Timer}
              className="timerImage"
              style={{
                filter: !rest
                  ? "invert(0%) sepia(0%) saturate(7469%) hue-rotate(281deg) brightness(0%) contrast(106%)"
                  : "invert(0%) sepia(0%) saturate(7469%) hue-rotate(281deg) brightness(88%) contrast(106%)",
              }}
            />{" "}
            0:{countDown}
          </p>
        </div>
        <div className="ExerciseNameDiv">
          <p
            style={{
              color: !rest ? "black" : "white",
              fontSize: "25px",
              fontWeight: "600",
            }}
            className="timerText"
          >
            {!rest
              ? Array.isArray(exerciseData[phases[phaseIndex]])
                ? exerciseData[phases[phaseIndex]][index].circuit_detail[
                    secondIndex
                  ].name
                : "rest"
              : "Rest"}
          </p>
        </div>
        <div className="controlButtons">
          <button onClick={backExercise} className="backButton">
            <PlaySkipBackSharp
              color={"#ffffff"}
              // title={}
              height="20px"
              width="20px"
            />
          </button>

          <button
            onClick={() => {
              pauseExercise();
            }}
            style={{
              // color: "black",
              background: "gray",
              cursor: "pointer",
              borderRadius: "50%",
              width: "30%",
              padding: "30px",
              border: "none",
            }}
          >
            {!pause ? (
              <PauseOutline
                color={"#ffffff"}
                cssClasses="playButton"
                // title={}
                // height="40px"
                // width="40px"
              />
            ) : (
              <PlaySharp
                color={"#ffffff"}
                cssClasses="playButton"
                // title={}
                // height="40px"
                // width="40px"
              />
            )}
          </button>
          <button onClick={nextExercise} className="nextButton">
            <PlaySkipForwardSharp
              color={"#ffffff"}
              // title={}
              height="20px"
              width="20px"
            />
          </button>
        </div>
      </div>
    </div>
  );
  // return <div></div>;
};

export default ExercisePlayer;
