import React from "react";
import SiteHeader from "../components/header";
import MiniHeader from "../components/miniHeader";
import Workout from "../components/getWorkout";
import Footer from "../components/footer";
import "../styles/about.css";
import { Layout } from "antd";
import team_image from "../assets/images/cover2.jpg";
import profile from "../assets/images/profile_pic.png";
import rao from "../assets/images/rao.png";
import boss from "../assets/images/boss.png";
import TeamCard from "../components/teamCard";

const { Content } = Layout;

const About = () => {
  return (
    <div className="container-div">
      <div style={{ marginBottom: "50px" }}>
        <SiteHeader />
      </div>

      <MiniHeader name={"About Us"} />
      <div className="img-div">
        <img src={team_image} className="pic" />
      </div>

      <div className="text-box-div">
        <p className="small-text">WHO WE ARE</p>
        <p className="paragraph-text">
          Fitflex was established with the mission to challenge and inspire
          people to become the strongest version of themselves, physically as
          well as mentally.
        </p>

        <p className="big-text">
          We aim to create an environment where everyone can enjoy better
          fitness and wellbeing at home.
        </p>
        <div className="article-text-div">
          <div className="left-text-div">
            <p className="paragraph-text">
              The Fitflex app is the #1 fitness app in Pakistan/ Middle east. It
              offers rich content library with video-based training plans and
              muscle group focus exercises. With personalized training plans and
              workout Fitflex puts a personal trainer in your pocket, that
              allows you to train anytime, anywhere.
            </p>
            <p className="paragraph-text">
              {" "}
              Our fitness experts will inspire you to unlock your full potential
              if you are striving to stay fit. We are providing a platform that
              impeccably combines world class fitness content with personalized
              nutritional plans, all of which is accessible anytime, anywhere.
            </p>
            <p className="paragraph-text">
              {" "}
              Other features include; In-App Help Desk that allow users to reach
              out for query, feedback & complaint within the app
            </p>
          </div>
          <div className="right-text-div">
            <p className="paragraph-text">
              Easy To Use; The app has a simple design & intelligent workout
              builder that reduces complexity for users
            </p>{" "}
            <br />
            <p className="paragraph-text">
              {" "}
              Reminders for users to follow workout routines, flexibility to
              share content on social media
            </p>{" "}
            <br />
            <p className="paragraph-text">
              {" "}
              Fitflex keeps you motivated and accountable throughout your
              journey towards a fit and healthy lifestyle.
            </p>
          </div>
        </div>
      </div>
      <div className="team-div">
        <p className="small-text">MANAGEMENT</p>
        <p className="big-text">MEET OUR TEAM</p>
      </div>
      <div className="skew-div"></div>
      <div class="member-cards-div">
        <TeamCard
          name={"Asim Ali"}
          pic={boss}
          desc1={
            "Asim Ali has over 2 decades of experience in leadership, digital technology, corporate services (product, business) and market development across corporate sectors. He holds a Business Degree majoring in Marketing/ Marketing management. he also held an advanced Diploma from London Business School in Executive Education, Developing Strategy for Value Creation."
          }
          desc2={
            "Asim's extensive background includes being a Chief innovation officer at Globacom as well as Director products Digital and experience at Warid Telecom. Global experience in digital/ transformation/strategy/data/technology in the telecom industry. Highly experienced in African, Middle East and Asian market."
          }
        />
        <TeamCard
          name={"Rao Shehzad"}
          pic={rao}
          desc1={
            "Rao Shehzad holds an MBA degree with over 12 years of professional experience with 7 years of people management skills. Currently, He is building an OTT App based Startup, enabling customers manage their health & fitness routines, as we achieve a new normal for work-life balance during & post Covid-19."
          }
          desc2={
            "Earlier, over the years, the combination of data analytics, pricing strategy, product management, and team leadership made him execute category rejuvenation projects, set up new revenue/business stream, re-wire product experiences, manage value propositions and build growth based GTM strategies within Telecom, Oil, Tobacco & Ride Hailing Startups."
          }
        />
      </div>
      <Workout />
      <Footer />
    </div>
  );
};

export default About;
