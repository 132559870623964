import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "../styles/myCss.css";
import Scroll from "./ScrollBar";

const Training = () => {
  const { t } = useTranslation();
  return (
    <>
      <div style={container} className="bgImg">
        <div className="training-plans">
          <p className="workout-heading1">{t("training")}</p>
          <p
            className="workout-heading2"
            style={{
              width: "100%",
              marginTop: -2,
              marginBottom: 30,
            }}
          >
            {t("training_everyone")}
          </p>
        </div>

        {/* <div
        className="trainCardDiv"
        style={{
          overflow: "hidden",
          width: "100%",
        }}
      > */}

        {/* </div> */}
      </div>
      <Scroll />
    </>
  );
};

export default Training;
const container = {
  width: "100%",
  display: "flex",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center center",
  // flexGrow: 1,
  // width: "100%",
  // height: 700,
  // marginBottom: "20vh",
  marginTop: "30vh",
  paddingTop: 40,
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};
