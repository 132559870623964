import React, { useState, useEffect } from "react";
import "../styles/loginMarketingDirect.css";
import flag from "../assets/images/CzechFlag.svg";
import apple from "../assets/images/app_store_opt.png";
import android from "../assets/images/play_store_opt.png";
import { Button, Input, Spin, Dropdown, Menu, Modal } from "antd";
import {
  MobilePackage,
  indonesiaMarketingLogs,
  iraqLandingCharge,
  isAuthenticated,
} from "../api/requests";
import OTPInput, { ResendOTP } from "otp-input-react";
import { LoadingOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import MarketPlan from "../components/marketingPlan";
import { useNavigate, useSearchParams } from "react-router-dom";

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "#FF1E1E" }} spin />
);

const SouthAfricaMarketing = () => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [isOtp, setIsOtp] = useState(false);
  const [error, setError] = useState("");
  const [number, setNumber] = useState("");
  const [OTP, setOTP] = useState("");
  const [redirectTo, setRedirectTo] = useState(false);
  const [payments, setPayments] = useState([]);
  const [packageValue, setPackageValue] = useState("");
  const [resendStatus, setResendStatus] = useState(false);
  const [resendTimer, setResendTimer] = useState(180);
  const [redirectLoading, setRedirectLoading] = useState(false);
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  // console.log("Search Params: ", searchParams.get("msisdn").substring(3));

  // let headerEnrichmentNumber = searchParams.get("msisdn");

  //   let tokenCheck = isAuthenticated();

  useEffect(() => {
    let payload = {
      country_id: 197,
      operator: "vodacom",
      language_id: "7",
    };

    //   if (headerEnrichmentNumber !== null) {
    //     setNumber(searchParams.get("msisdn").substring(3));
    //   }

    MobilePackage(payload)
      .then((data) => {
        if (data.status === 200) {
          console.log("QATAR: ", data);
          setPayments(data.data);
          setLoading(false);
          setPackageValue(data.data[0].package_id);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  //   useEffect(() => {
  //     if (resendStatus) {
  //       resendTimer > 0 &&
  //         setTimeout(() => setResendTimer(resendTimer - 1), 1000);
  //     }
  //   }, [resendTimer, resendStatus]);

  const renderButton = () => {
    return resendTimer <= 0 ? (
      <button
        style={{ cursor: "pointer" }}
        className="resend"
        onClick={() => {
          LoginFunction();
          setResendTimer(180);
          setResendStatus(true);
        }}
      >
        Resend OTP
      </button>
    ) : (
      <button className="resend">
        OTP will Expire in {resendTimer} seconds
      </button>
    );
  };
  const renderTime = () => React.Fragment;

  function checkFirstTwoCharacters(str) {
    const forbiddenPatterns = /^(50|55|53)/; // Regular expression pattern

    // Check if the string matches any of the forbidden patterns
    return forbiddenPatterns.test(str);
  }

  const LoginFunction = () => {
    setError("");
    if (number == "") {
      console.log("Empty Check");
      setError("Please Enter A Valid Number to Continue");
      return;
    } else if (checkFirstTwoCharacters(number) === false) {
      console.log("Invaalid Substring check");
      setError("Invalid Number, please try again");
      return;
    } else {
      console.log("Test Clear");
      setLoading(true);
      console.log("package value is ::::", packageValue);
      let payload = {
        msisdn: "420" + number,
        package_id: packageValue,
        country_id: 57,
      };
      console.log("payload is :::", payload);
      iraqLandingCharge(payload)
        .then((data) => {
          console.log("My Payloadss", data);
          if (data.status === 200) {
            if (data.data.status === 200) {
              setResendStatus(true);
              setLoading(false);
              setError("");
              setIsOtp(true);
            } else {
              setLoading(false);
              setError(data.data.msg);
            }
          } else {
            setLoading(false);
            setError("Could not login at the moment, please try again later.");
          }
        })
        .catch((err) =>
          setError("Could not login at the moment, please try again later.")
        );
    }
  };

  const redirect = () => {
    window.location.href = "http://fitflexapp.com/sa_callback/";
  };

  //   const onSubmit = () => {
  //     if (OTP.length !== 4) {
  //       setError(`OTP must be of 4 digits`);
  //     } else {
  //       setLoading(true);
  //       let otpPayload = {
  //         msisdn: "966" + number,
  //         package_id: packageValue,
  //         country_id: 187,
  //         otp: OTP,
  //         marketPartner: "web",
  //       };

  //       iraqLandingCharge(otpPayload)
  //         .then((data) => {
  //           console.log("response data: ", data);
  //           if (data.status === 200) {
  //             if (data.data.status === 200) {
  //               let marketingLogPayload = {
  //                 msisdn: "420" + number,
  //                 package_id: packageValue,
  //                 country_id: 57,
  //                 otp: OTP,
  //                 MarketingUrl: window.location.href,
  //               };
  //               indonesiaMarketingLogs(marketingLogPayload).then((response) => {
  //                 setLoading(false);
  //                 setError("Package is subscribed succesfully");
  //                 setTimeout(() => {
  //                   setRedirectTo(true);
  //                 }, 1000);
  //               });
  //             } else {
  //               setLoading(false);
  //               setError(data.data.msg);
  //             }
  //           } else {
  //             setLoading(false);
  //             setError("Invalid OTP");
  //           }
  //         })
  //         .catch((err) => {
  //           setError("Invalid OTP");
  //           setLoading(false);
  //         });
  //     }
  //   };

  //   if (redirectTo) {
  //     window.location.replace("https://fitflexapp.com/checkuserlp");
  //   }

  if (redirectLoading) {
    return (
      <div className="loader">
        <Spin
          className="spinner"
          indicator={antIcon}
          tip="Fetching Details..."
        />
      </div>
    );
  }

  return (
    <div className="Login-page-containerMk">
      <div className="containerLeftMk">
        <div className="termsMk">
          {payments.length > 0 &&
            payments.map((item, index) => {
              return (
                <div className="marketCardMk" key={index}>
                  <MarketPlan
                    type={`${t("subscription_plan")}`}
                    rate={t(item.package_name) + ` R ` + item.package_rate}
                    duration={item.package_description}
                    description={item.package_description}
                    term={item.terms_and_conditions}
                    countryName={"SA"}
                  />
                </div>
              );
            })}
        </div>
      </div>

      <div className="containerRightMk">
        <div className="titleDivMk">
          <div style={{ display: "inline-block" }}>
            <span className="titletextMk" style={{ color: "#ff1e1e" }}>
              FIT
            </span>
            <span className="titletextMk" style={{ color: "#ff1e1e" }}>
              FLEX
            </span>
          </div>
        </div>

        <>
          <h1 className={"welcomeMk"}>Start Your Fitness Journey Today</h1>

          <Button className={"btn-loginMk"} onClick={redirect}>
            {t("Subscribe")}
          </Button>
          {/* <p
            style={{
              color: "#fff",
              margin: "20px 0px 0px 0px",
              fontSize: "15px",
              fontFamily: "Montserrat",
            }}
          >
            To Unsubscribe from Fitflex, dial *135*997#
          </p> */}
        </>

        <div className="margin-top-minus-on-smallMk">
          <a
            href="https://play.google.com/store/apps/details?id=fitflex.workout.fitness.weight.gym.fat.training"
            target="_blank"
          >
            <img
              src={android}
              style={{
                marginRight: "5px",
              }}
              alt="playstore"
              className="storeimg"
            />
          </a>
          <a
            href="https://apps.apple.com/us/app/fitflex/id1613514782"
            target="_blank"
          >
            <img
              src={apple}
              style={{
                border: "1px solid grey",
                borderRadius: "4px",
              }}
              alt="appstore"
              className="storeimg"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default SouthAfricaMarketing;
