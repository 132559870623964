import React from "react";
import "../styles/about.css";
import { Link } from "react-router-dom";
const MiniBlog = (props) => {
  return (
    <Link
      to={`/blogdetails/${props.blog_id}/${props.description_short
        .split(" ")
        .join("-")}`}
      state={{
        blog_id: props.blog_id,
      }}
      onClick={() => {
        // localStorage.clear();
        localStorage.removeItem("blog_id");
        localStorage.setItem("blog_id", props.blog_id);
      }}
      onFocus={() => {
        // localStorage.clear();
        localStorage.removeItem("blog_id");
        localStorage.setItem("blog_id", props.blog_id);
      }}
    >
      <div className="mini-blog-div">
        <img className="mini-blog-img" src={props.image} />
        <div className="sub-mini-div">
          <p className="apply-now-txt">{props.category}</p>
          <p className="mini-div-text">{props.description_short}</p>
          {/* <p className="author-name-text">{props.author}</p> */}
        </div>
      </div>
    </Link>
  );
};

export default MiniBlog;
