import React, { useState, useEffect } from "react";
import "../styles/CsLandingPage.css";
import FitflexLogo from "../assets/images/fitflex_logo.png";
import FitnessImage from "../assets/images/FitnessImage.jpg";
import { Button, Skeleton } from "antd";
import { ArrowForwardCircleOutline } from "react-ionicons";
import { iraqHE, logUser } from "../api/requests";
import { Helmet } from "react-helmet";

const CsLandingPage = () => {
  const [loading, setLoading] = useState(true);
  const [link, setLink] = useState("");
  const [IP, setIP] = useState("");
  const [htmlFunction, setHtmlFunction] = useState("");
  const [scriptSrc, setScriptSrc] = useState("");

  console.log(window.location.href);

  const getData = async () => {
    const res = await fetch("https://api.ipify.org/?format=json")
      .then((res) => res.json())
      .then((json) => {
        setIP(json.ip);
        let user = {
          page: "loading_landing_page",
          ip: json.ip,
          query: window.location.href,
        };
        logUser(user)
          .then((data) => {
            console.log(data);
            if (data.status === 200) {
              console.log("Success hit");
              iraqHE().then((data) => {
                if (data.status === 200) {
                  setLink(data.link);
                  setHtmlFunction(data.src);
                  setScriptSrc(data.script);
                  setLoading(false);
                }
              });
            } else {
              console.log("Failure: ", data);
            }
          })
          .catch((err) => console.log(err));
      });
  };

  const iraqRedirection = () => {
    setLoading(true);
    let user = {
      page: "landing_page",
      ip: IP,
      query: window.location.href,
    };
    logUser(user)
      .then((data) => {
        if (data.status === 200) {
          window.location.replace(link);
          // setLoading(false);
        } else {
          window.location.replace(link);
        }
      })
      .catch((err) => window.location.replace(link));
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="Cs-Container-Div">
      <Helmet>
        <meta name="referrer" content="unsafe-url" />
        <script async="" crossorigin="anonymous" src={scriptSrc} />
        <script>{htmlFunction}</script>
      </Helmet>

      <div className="Left-Cs-Div">
        <img
          src={FitflexLogo}
          alt="Fitflex_logo"
          className="fitflexLogoImage"
        />
        <div className="Left-Cs-Text-Div">
          <p className="Cs-text" style={{ fontWeight: "500" }}>
            Enjoy
          </p>
          <p
            className="Cs-text"
            style={{ fontWeight: "800", color: "#FF5858" }}
          >
            Unlimited Fitness
          </p>
          <p className="Cs-text" style={{ fontWeight: "500" }}>
            Anywhere
          </p>
          <p
            className="Cs-text"
            style={{ fontWeight: "800", color: "#FF5858" }}
          >
            At Your Convenience
          </p>
        </div>
        {loading ? (
          <div className="skeletonBtn">
            <Skeleton.Button
              active={true}
              size={"large"}
              style={{
                borderRadius: 20,
              }}
              block={true}
              shape={"default"}
            />
          </div>
        ) : (
          <Button className="Cs-Trial-Btn" onClick={iraqRedirection}>
            Get 3 Days Free Trial
            <p
              style={{
                textAlign: "center",
                alignSelf: "center",
                marginTop: "5px",
                marginLeft: "8px",
                marginBottom: 0,
              }}
            >
              <ArrowForwardCircleOutline
                height="20px"
                width="25px"
                color={"#FF5858"}
              />
            </p>
          </Button>
        )}

        <p style={{ color: "#fff", fontSize: "10px", padding: "10px" }}>
          *Daily IQD 300/day tax incl.
        </p>
      </div>
      <div className="Right-Cs-Div">
        <img
          style={{ width: "80%" }}
          src={FitnessImage}
          alt="Fitness_Background_Image"
        />
      </div>
    </div>
  );
};

export default CsLandingPage;
