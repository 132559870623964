import React, { useState, useEffect } from "react";
import { Button, Modal } from "antd";
import "../styles/nutrition.css";
import IcFood from "../assets/images/dinner.png";
import NutritionAboutYou from "./NutritionAboutYou";
import { useNavigate } from "react-router-dom";

const NutritionConfirmationModal = (props) => {
  console.log("props are :::", props);
  const [open, setOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const navigate = useNavigate();

  console.log("is Modal open is :::", isModalOpen);

  const openMethod = () => {
    navigate("/nutritionaboutyou");
    setIsModalOpen(false);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    navigate("/home");
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100vh",
        background: "black",
      }}
    >
      <Modal
        className="NutritonConfirmationModal"
        title="Do You Want Free Nutrition Support"
        footer={false}
        visible={isModalOpen}
        onCancel={closeModal}
      >
        <div className="NutritionFoodIcon">
          <div className="NutritionImageIcon">
            <img
              src={IcFood}
              style={{
                width: "80%",
                filter:
                  "invert(25%) sepia(64%) saturate(5528%) hue-rotate(351deg) brightness(105%) contrast(100%)",
              }}
              alt="Ic Food Logo"
            />
            <p
              style={{
                color: "white",
                textAlign: "center",
                fontSize: "13px",
                fontWeight: "bold",
                fontFamily: "'Montserrat', sans-serif",
              }}
            >
              {" "}
              Nutrition Plan
            </p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              alignItems: "center",
              width: "100%",
              padding: "15px",
            }}
          >
            <Button
              style={{
                width: "40%",
                borderRadius: "10px",
                background: "#282828",
                color: "white",
                border: "0",
                height: "7vh",
              }}
              onClick={closeModal}
            >
              Cancel
            </Button>
            <Button
              style={{
                width: "40%",
                borderRadius: "10px",
                background: "linear-gradient(180deg, #FF5858 0%, #FF5B37 100%)",
                color: "white",
                border: "0",
                height: "7vh",
              }}
              onClick={openMethod}
            >
              Confirm
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default NutritionConfirmationModal;
