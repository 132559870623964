import React, { useEffect, useState } from "react";
import { getSingleBlogsCategory } from "../api/requests";
import BlogCard from "../components/blogCard";
import SiteHeader from "../components/header";
import WorkoutVideoCard from "../components/workoutVideoCard";
import "../styles/workout.css";
import { Pagination } from "antd";
import Footer from "../components/footer";
import Workout from "../components/getWorkout";
import FooterDark from "../components/darkFooter";
import { useLocation } from "react-router-dom";

function BlogsCategory() {
  const location = useLocation();
  const [blogs_arr, setBlogs_arr] = useState([]);
  const [catName, setBlogCatName] = useState([]);
  const [minValue, setMinValue] = useState("0");
  const [maxValue, setMaxValue] = useState("3");

  useEffect(async () => {
    getSingleBlogsCategory(localStorage.getItem("blog_id")).then((res) => {
      console.log("resp: ", res.data[0]);
      setBlogs_arr(res.data[0].blogs);
      setBlogCatName(res.data[0].name);
    });
  }, []);

  const handleChange = (value) => {
    if (value <= 1) {
      setMinValue("0");
      setMaxValue("3");
    } else {
      setMinValue(minValue * 3);
      setMaxValue(value * 3);

      // window.scrollTo(100, 100);
    }
  };

  return (
    <div className="container-div">
      {/* {console.log(
        "CAtegories VIdeosasdasda: ",
        location.state.category_videos
      )} */}
      <div style={{ marginBottom: "50px" }}>
        <SiteHeader />
      </div>
      {console.log("catVides :", catName)}
      <div
        style={{
          marginTop: "10px",
          // background: "black",
          color: "white",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="blogs-viewmore">
          <div className="category-name-div ">
            <h1 style={{ color: "black" }}>{catName} Blogs</h1>
          </div>
          <div className="category-videos-div" style={{ paddingLeft: "20px" }}>
            {blogs_arr.length > 0 &&
              blogs_arr
                .map((key, i) => {
                  console.log("first", key);
                  return (
                    <BlogCard
                      pic={key.feature_image}
                      blog_id={key.blog_id}
                      category={catName}
                      title={key.title}
                    />
                  );
                })
                .slice(minValue, maxValue)}
          </div>
          <Pagination
            defaultCurrent={1}
            defaultPageSize={3}
            onChange={handleChange}
            total={blogs_arr.length}
            style={{ marginBottom: "20px" }}
          />
        </div>
        <Workout />
        <Footer />
      </div>
    </div>
  );
}

export default BlogsCategory;
