import React from "react";
import "../styles/myCss.css";
import { Button } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
// import MealCard from "../components/mealCard.js";
import mobile from "../assets/images/mobile.png";
import { useTranslation } from "react-i18next";
import { isAuthenticated } from "../api/requests";

const Nutritions = () => {
  const { t } = useTranslation();
  const encryptedMsisdn = JSON.parse(localStorage.getItem("encryptedMsisdn"));
  const country = localStorage.getItem("country");
  return (
    <div style={container} className="spacing-top-minus-on-mobile">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "50vw",
        }}
      >
        <p className="nutrition-heading">{t("nutrition_title")}</p>
        <p className="nutrition-description">{t("nutrition_desc")}</p>
        <div className="btonDiv">
          {isAuthenticated() === false ? (
            <Button
              className="btoncolor"
              onClick={() => {
                window.location.href = "https://fitflexapp.com/login";
              }}
            >
              {t("nutrition_button_1")} <ArrowRightOutlined />
            </Button>
          ) : (
            encryptedMsisdn === null &&
            country === "ZA" && (
              <Button
                className="btoncolor"
                onClick={() => {
                  window.location.href = "http://fitflexapp.com/sa_callback/";
                }}
              >
                {t("nutrition_button_1")} <ArrowRightOutlined />
              </Button>
            )
          )}
          <Button
            className="bton"
            onClick={() => {
              window.location.href = "https://fitflexapp.com/checkuserlp";
            }}
          >
            {t("nutrition_button_2")}
            <ArrowRightOutlined />
          </Button>
        </div>
      </div>
      <div
        style={{
          justifyContent: "center",
          alightItems: "center",
          display: "flex",
        }}
      >
        <img className="mobile-img" src={mobile} />
      </div>
    </div>
  );
};

export default Nutritions;

const container = {
  width: "100%",
  marginBottom: "5vw",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "120px",
};
