import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "../Skeleton/Styles/HomeTrendingSkeleton.css";

export const HomeTrendingSkeleton = () => {
  return (
    <div className="HomeTrendingContainer">
      <div className="leftTrendingVideoDiv">
        <div className="leftTrVideoContainer">
          <Skeleton
            containerClassName="leftTrendingVideoSkeleton"
            baseColor="#1a1a1a"
            highlightColor="#fff"
            // height={"350px"}
            width={"100%"}
          />
        </div>
        <div className="leftTrVideoDetailsDiv">
          <Skeleton
            containerClassName="homeSkeleton"
            baseColor="#1a1a1a"
            highlightColor="#fff"
            height={"20px"}
            width={"50%"}
            style={{
              marginTop: "7px",
            }}
          />
          <Skeleton
            containerClassName="homeSkeleton"
            baseColor="#1a1a1a"
            highlightColor="#fff"
            height={"20px"}
            width={"40%"}
            style={{
              marginTop: "7px",
            }}
          />
          <Skeleton
            containerClassName="homeSkeleton"
            baseColor="#1a1a1a"
            highlightColor="#fff"
            height={"20px"}
            width={"80%"}
            style={{
              marginTop: "7px",
            }}
          />
        </div>
      </div>
      <div className="rightTrendingVideoDiv">
        <div className="VideoContainer">
          <Skeleton
            containerClassName="rightTrendingVideoSkeleton"
            baseColor="#1a1a1a"
            highlightColor="#fff"
            // height={"140px"}
            width={"100%"}
          />
          <div className="rightVideoDetailsDiv">
            <Skeleton
              containerClassName="rightTrendingSkeleton"
              baseColor="#1a1a1a"
              highlightColor="#fff"
              height={"20px"}
              width={"100%"}
              style={{
                marginBottom: "7px",
              }}
            />
            <Skeleton
              containerClassName="rightTrendingSkeleton"
              baseColor="#1a1a1a"
              highlightColor="#fff"
              height={"20px"}
              width={"100%"}
              style={{
                marginBottom: "7px",
              }}
            />
            <Skeleton
              containerClassName="rightTrendingSkeleton"
              baseColor="#1a1a1a"
              highlightColor="#fff"
              height={"20px"}
              width={"50%"}
              style={{
                marginBottom: "7px",
              }}
            />
          </div>
        </div>
        <div className="VideoContainer">
          <Skeleton
            containerClassName="rightTrendingVideoSkeleton"
            baseColor="#1a1a1a"
            highlightColor="#fff"
            // height={"140px"}
            width={"100%"}
          />
          <div className="rightVideoDetailsDiv">
            <Skeleton
              containerClassName="rightTrendingSkeleton"
              baseColor="#1a1a1a"
              highlightColor="#fff"
              height={"20px"}
              width={"100%"}
              style={{
                marginBottom: "7px",
              }}
            />
            <Skeleton
              containerClassName="rightTrendingSkeleton"
              baseColor="#1a1a1a"
              highlightColor="#fff"
              height={"20px"}
              width={"100%"}
              style={{
                marginBottom: "7px",
              }}
            />
            <Skeleton
              containerClassName="rightTrendingSkeleton"
              baseColor="#1a1a1a"
              highlightColor="#fff"
              height={"20px"}
              width={"50%"}
              style={{
                marginBottom: "7px",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
