import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import "./index.css";
// import App from "./App";
import "./i18next";
import reportWebVitals from "./reportWebVitals";
// import * as Sentry from "@sentry/react";
// import { BrowserTracing } from "@sentry/tracing";
import i18n from "./i18next";
import { I18nextProvider } from "react-i18next";
// const App = lazy(() => import("./App"));
import App from "./App";

// Sentry.init({
//   dsn: "https://a17349051514495198859bd9367986d6@o1087909.ingest.sentry.io/6501718",
//   integrations: [new BrowserTracing()],

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });

// replace console.* for disable log on production
if (process.env.NODE_ENV === "production") {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

ReactDOM.render(
  <Suspense fallback={<div>~~~Loading~~~</div>}>
    <React.StrictMode>
      <I18nextProvider i18n={i18n}>
        <App />
      </I18nextProvider>
    </React.StrictMode>
  </Suspense>,
  document.getElementById("root")
);

reportWebVitals();
