import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SiteHeader from "../components/header";
import CalIcon from "../assets/images/calIcon.svg";

const NutritionFav = () => {
  const [fav, setFav] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    setFav(JSON.parse(localStorage.getItem("favNutrition")));
  }, []);

  //   console.log("fav meal is ", fav[0].meal);
  return (
    <div className="mainContainerDiv">
      <div style={{ marginBottom: "60px" }}>
        <SiteHeader />
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#000",
          flex: 3,
          paddingBottom: "150px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "87%",
            justifyContent: "flex-start",
            alignItems: "center",
            flexWrap: "wrap",
            marginTop: "20px",
            // flex: "1",
          }}
        >
          <div
            className="NutritionFavDiv"
            // style={{
            //   display: "flex",
            //   width: "97%",
            //   alignItems: "center",
            //   justifyContent: "flex-start",
            // }}
          >
            {/* <h1 style={{ color: "#fff" }}>{location.state.title}</h1> */}
          </div>
          {fav.map((item, index) => {
            console.log("item meal is :::", item.meal);
            return (
              <div
                className="mealCardDiv"
                style={{ margin: "5px", width: "24%", cursor: "pointer" }}
                onClick={() => {
                  navigate("/nutritiondetails", {
                    state: {
                      id: item.id,
                      band: item.band_first,
                      title: item.meal,
                    },
                  });
                }}
              >
                <div className="dishImageAndContentDiv">
                  <div>
                    <img
                      style={{ width: "100%", borderRadius: "10px" }}
                      src={item.dishes[0].dish_landscape_image}
                      alt="breakfast"
                    />
                  </div>
                  <div style={{ width: "100%" }}>
                    <p
                      style={{
                        color: "white",
                        textAlign: "left",
                        marginTop: "10px",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {item.dishes[0].name}
                    </p>
                  </div>
                  <div className="macroNutrientsDiv">
                    <div
                      className="nameAndGramDiv"
                      style={{ flexDirection: "row" }}
                    >
                      <img
                        src={CalIcon}
                        style={{ width: "20%", marginRight: "5px" }}
                        alt="calIcon"
                      />
                      <p style={{ color: "white", margin: 0 }}>
                        {item.dishes[0].calories}
                      </p>
                      <p style={{ color: "white", margin: 0 }}>Kcal</p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default NutritionFav;
