import React, { useEffect, useState } from "react";
import { Link, Navigate, useSearchParams } from "react-router-dom";
import {
  isAuthenticated,
  authenticate,
  MzaSignatureVerify,
  getTokenUserSegmentation,
} from "../api/requests";
import "../styles/login.css";
import "../styles/homescreen.css";
import "../styles/setplan.css";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Result } from "antd";

const antIcon = (
  <LoadingOutlined
    style={{ fontSize: 45, color: "#FF1E1E", marginBottom: 20 }}
    spin
  />
);

const MyZongApp = () => {
  const [error, setError] = useState(false);
  const [tokenNew, setTokenNew] = useState("");
  const [redirect, setRedirect] = useState();
  const [params] = useSearchParams();
  const signature = params.get("signature");
  const msisdn = params.get("data");

  let payload = {
    msisdn: "92" + msisdn,
  };

  useEffect(() => {
    const myZongAppSubscription = () => {
      localStorage.clear();
      if (isAuthenticated() === false) {
        MzaSignatureVerify(signature, "92" + msisdn).then((response) => {
          console.log("response is :::", response);
          if (response.status === 200) {
            if (response.message === "success") {
              if (response.signature_valid === "Yes") {
                console.log("yes yes yes");
                getTokenUserSegmentation(payload).then((response) => {
                  console.log("get token no no no is :::", response);
                  if (response.status === 200) {
                    // localStorage.clear();
                    localStorage.setItem("operator", 9);
                    setTokenNew(response.data.token);
                    localStorage.setItem("mza", true);
                    authenticate(
                      response.data.token,
                      "0" + msisdn,
                      "PK",
                      () => {
                        setRedirect(true);
                      }
                    );
                  } else {
                    setError(true);
                  }
                });
              } else {
                setError(true);
              }
            }
          } else {
            setError(true);
          }
        });
      }
    };
    myZongAppSubscription();
  }, []);

  if (redirect) {
    return <Navigate to={"/home"} />;
  }

  if (error) {
    return (
      <Result
        status="500"
        title="500"
        subTitle="Sorry, something went wrong."
        extra={<Link to="/">Back Home</Link>}
      />
    );
  }

  return (
    <div class="loader">
      <Spin className="spinner" indicator={antIcon} tip="Redirecting..." />
    </div>
  );
};

export default MyZongApp;
