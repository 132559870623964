import React, { useEffect, useState } from "react";
import { Input, Button, Spin } from "antd";
import OTPInput from "react-otp-input";
import "../styles/InHouseLandingPage.css";
import FitflexIcon from "../assets/images/fitflexLogo.png";
import CrossIcon from "../assets/images/cross.png";
import LoadingOverlay from "react-loading-overlay";
import { getmsisdn, getOTP, verifyOTP } from "../api/requests";
import { LoadingOutlined } from "@ant-design/icons";
import { Helmet } from "react-helmet";

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "#FF1E1E" }} spin />
);

const InHouseLandingPage = () => {
  const [OTP, setOTP] = useState("0000");
  const [number, setNumber] = useState("");
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loadProcessing, setLoadProcessing] = useState(false);
  const [failure, setFailure] = useState(false);

  const TikTokPixel = `!function (w, d, t) {
    w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++
)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script");n.type="text/javascript",n.async=!0,n.src=i+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};
  
    ttq.load('CHHJA8RC77U7EI1D8OJ0');
    ttq.page();
  }(window, document, 'ttq');`;

  useEffect(() => {
    getmsisdn()
      .then((data) => {
        console.log("DATA: ", data);
        if (data.status === 200) {
          console.log(data);
          if (data.data !== null) {
            let str = data.data;
            // str = "92" + str.substring(1);
            console.log("Str: ", str);
            setNumber(str);

            let payloadNumber = "92" + str.substring(1);

            let payload = {
              msisdn: payloadNumber,
              package_id: 8,
              country_id: 162,
            };

            console.log(payload);

            getOTP(payload)
              .then((data) => {
                console.log("GET OTP: ", data);
                if (data.status === 200) {
                  console.log(data);
                  setOTP(data.data.pin);
                  setLoading(false);
                } else {
                  console.log("FAIL: ", data);
                  setLoading(false);
                }
              })
              .catch((err) => console.log("FAILURE: ", err));
          }
        } else {
          window.location.replace("https://fitflexapp.com/daily-pk-jazz");
        }
      })
      .catch((err) => {
        window.location.replace("https://fitflexapp.com/daily-pk-jazz");
      });
  }, []);

  function gtag_report_conversion(url) {
    var callback = function () {
      if (typeof url != "undefined") {
        window.location = url;
      }
    };
    window.gtag("event", "conversion", {
      send_to: "AW-10900259174/MXCaCMO0qKMYEOaC080o",
      event_callback: callback,
    });
    return false;
  }
  const submitFunction = () => {
    setLoadProcessing(true);
    let payload = {
      package_id: 8,
      msisdn: "92" + number.substring(1),
      otp: OTP,
      country_id: 162,
    };
    verifyOTP(payload)
      .then((data) => {
        console.log("SUCESS: ", data);
        if (data.status === 200) {
          window.ttq.track("Subscribe");
          setLoadProcessing(false);
          gtag_report_conversion();
          setSuccess(true);
          setTimeout(() => {
            window.location.replace("https://fitflexapp.com/checkuserlp");
          }, 3000);
        } else {
          console.log(data);
          setLoadProcessing(false);
          setSuccess(true);
          setFailure(true);
          setTimeout(() => {
            window.location.replace("https://fitflexapp.com/checkuserlp");
          }, 3000);
        }
      })
      .catch((err) => console.log("ERROR: ", err));
  };

  return (
    <div className="MainContainer">
      <Helmet>
        <script>{TikTokPixel}</script>
      </Helmet>
      <div className="LandingPageContainer">
        <img src={FitflexIcon} alt="" />

        <p className="heading-one-text">Your Workout Partner</p>
        <p className="heading-two-text">Anywhere anytime</p>
        <p className="trial-one-text">One Day Free Trial</p>
        <p className="trial-two-text">IN JUST RS 5+TAX/DAY</p>
        {loading ? (
          <LoadingOverlay
            active={true}
            spinner
            text="Loading..."
            styles={{
              content: (base) => ({
                ...base,
                color: "#ff1e1e",
                fontWeight: "500",
                textTransform: "capitalize",
                marginTop: 40,
              }),
              spinner: (base) => ({
                ...base,
                width: "50%",
                "& svg circle": {
                  stroke: "rgba(255, 0, 0, 0.5)",
                },
              }),
            }}
          />
        ) : (
          <>
            {!success ? (
              <div className="subscribeUserContainer">
                <p className="subscribe-one-text">Subscribe Now</p>
                <p className="subscribe-input-text">Enter Your Mobile Number</p>
                <Input
                  className="subscribe-input"
                  style={{ background: "#fff", padding: "0" }}
                  bordered={true}
                  value={number}
                  type="number"
                  onChange={(e) => {
                    const re = /^[0-9\b]+$/;
                    // if value is not blank, then test the regex
                    if (e.target.value === "" || re.test(e.target.value)) {
                      setNumber(e.target.value);
                    }
                  }}
                  placeholder={"+92XXXXXXXXXX"}
                />

                <p className="subscribe-otp-text">
                  Enter OTP Sent To Your Number
                </p>
                <OTPInput
                  value={OTP}
                  onChange={setOTP}
                  //   onKeyDown={handleOtpKeyDown}
                  autoFocus
                  OTPLength={4}
                  otpType="number"
                  disabled={false}
                  secure={false}
                  inputStyle="subscribe-otp-input"
                  containerStyle="subscribe-otp-div"
                />
                <div className="checkbox">
                  <input
                    type="checkbox"
                    checked
                    id="vehicle1"
                    name="vehicle1"
                    value="Bike"
                    style={{ width: "12px", height: "12px", marginRight: 5 }}
                  />
                  <label for="checkBox" className="checkbox-text">
                    I agree to
                    <p
                      id="modal-btn"
                      onClick={() => {
                        setOpenModal(!openModal);
                      }}
                    >
                      Terms And Conditions
                    </p>
                  </label>
                  <br />
                </div>
                {loadProcessing ? (
                  <div className="loading-div">
                    <Spin indicator={antIcon} tip="Please wait..." />
                  </div>
                ) : (
                  <button
                    className="subscribe-contine-button"
                    onClick={submitFunction}
                  >
                    Submit
                  </button>
                )}
              </div>
            ) : (
              <div class="subscribeUserContainer">
                <p class="subscribe-one-text">
                  {failure ? "Information" : "Congratulations!"}
                </p>

                <p class="subscribe-input-text">
                  {failure
                    ? "You're already subscribed with FitFlex"
                    : "You've successfully subscribed with FitFlex."}
                </p>

                <img src="Images/Box.png" style={{ marginTop: 40 }} alt="" />
                {/* <button class="subscribe-contine-button">Continue</button> */}
              </div>
            )}

            <p
              className="final-text"
              style={{
                marginTop: 10,
              }}
            >
              Subscription charges: Rs 5+tax/day
            </p>
          </>
        )}
      </div>
      <div
        className="modal"
        style={{ display: openModal === true ? "block" : "none" }}
      >
        <div className="modal-content">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
            }}
          >
            <p className="modal-Heading">Terms And Conditions</p>
            <img
              onClick={() => {
                setOpenModal(!openModal);
              }}
              className="close-btn"
              style={{ cursor: "pointer" }}
              src={CrossIcon}
              alt=""
            />
          </div>
          <p className="modal-text">
            1.Start Fitness Journey with 01 day of FREE TRIAL.
          </p>
          <p className="modal-text">
            2.No Additional cellular data charges will apply.
          </p>
          <p className="modal-text">
            3.Fair Usage for content streaming is 100 minutes.
          </p>
          <p className="modal-text">
            4.Offer is for Jazz Prepaid Customers only.
          </p>
        </div>
      </div>
    </div>
  );
};
export default InHouseLandingPage;
