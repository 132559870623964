import React, { useState, useEffect } from "react";
import "../styles/login.css";
import flag from "../assets/images/flag.png";
import iraq from "../assets/images/iraq.png";
import indonesia from "../assets/images/indonesia.png";
import SouthAfrica from "../assets/images/south-africa.png";
import SaudiArabia from "../assets/images/saudiArabiaFlagIcon.svg";
import Qatar from "../assets/images/qatar.png";
import Czech from "../assets/images/CzechFlag.svg";
import apple from "../assets/images/apple.png";
import android from "../assets/images/android.png";
import Facebook from "../assets/images/facebook.svg";
import Instagram from "../assets/images/instagram.svg";
import Youtube from "../assets/images/youtube.svg";
import { Button, Input, Spin, Dropdown, Menu } from "antd";
import {
  userSignup,
  otpVerify,
  authenticate,
  getmsisdn,
  checkPakUser,
  indoSignInApi,
} from "../api/requests";
import OTPInput, { ResendOTP } from "otp-input-react";
import { Navigate, useLocation } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
// import { initializeFirebase } from "../firebaseConfig";
import { signInWithPhoneNumber, RecaptchaVerifier } from "firebase/auth";
import { getToken, onMessage } from "firebase/messaging";
import { browserName, browserVersion } from "react-device-detect";
import { auth, messaging } from "../firebaseConfig";

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "#FF1E1E" }} spin />
);

const Login = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [isOtp, setIsOtp] = useState(false);
  const [error, setError] = useState("");
  const [number, setNumber] = useState("");
  const [OTP, setOTP] = useState("");
  const [secret, setSecret] = useState("");
  const [redirectTo, setRedirectTo] = useState(false);
  const [resendStatus, setResendStatus] = useState(false);
  const [resendTimer, setResendTimer] = useState(60);
  const [indonesiaNumber, setIndonesiaNumber] = useState("");
  const [qatarNumber, setQatarNumber] = useState("");
  const [saudiNumber, setSaudiNumber] = useState("");
  const [zongNumber, setZongNumber] = useState("");
  const [saNumber, setSaNumber] = useState("");
  const [czechNumber, setCzechNumber] = useState("");
  const [pakOperator, setPakOperator] = useState();
  const [currentCountry, setCurrentCountry] = useState({
    countryName: "indonesia",
    code: "IND",
    countryCode: "+62",
  });
  const [confirmationState, setConfirmationState] = useState("");
  const [isTokenFound, setTokenFound] = useState(false);
  const [network_type, setNetwork_type] = useState("");
  const [zongSecret, setZongSecret] = useState("");
  // const firebase = initializeFirebase();
  // const auth = firebase.auth;
  // const messaging = firebase.messaging;

  let otp_check =
    currentCountry.code === "IQ"
      ? 5
      : currentCountry.code === "IND" ||
        currentCountry.code === "QA" ||
        currentCountry.code === "ZA" ||
        currentCountry.code === "KSA"
      ? 6
      : 4;
  let numberLength =
    currentCountry.code === "IND"
      ? 11
      : currentCountry.code === "QA"
      ? 8
      : currentCountry.code === "ZA"
      ? 9
      : currentCountry.code === "KSA"
      ? 9
      : currentCountry.code === "CZ"
      ? 9
      : 10;

  const getFCMToken = () => {
    return getToken(messaging, {
      vapidKey:
        "BOELTBKsgjyhmx7cgl3o7Oj_qiXDb2GYVhYc6Nw9-C_8Yv3SrS4R8T05S75XYt5xsw3H85I4DaDNuQ7GNjNMn2U",
    })
      .then((currentToken) => {
        if (currentToken) {
          console.log("current token for client: ", currentToken);
          setTokenFound(currentToken);
        } else {
          console.log(
            "No registration token available. Request permission to generate one."
          );
        }
      })
      .catch((err) => {
        console.log("An error occurred while retrieving token. ", err);
        // catch error while creating client token
      });
  };

  useEffect(() => {
    console.log("the browser name is :::", browserName);
    getmsisdn().then((res) => {
      console.log("RESPONSE: ", res);
      if (res !== undefined) {
        if (res.status === 200) {
          console.log(res);
          if (res.data !== null) {
            let str = res.data;
            str = str.substring(1);
            console.log("Str: ", str);
            setNumber(str);
          }
        }
      }
    });

    getFCMToken();
  }, []);

  useEffect(() => {
    if (resendStatus) {
      resendTimer > 0 &&
        setTimeout(() => setResendTimer(resendTimer - 1), 1000);
    }
  }, [resendTimer, resendStatus]);

  const renderButton = () => {
    return resendTimer <= 0 ? (
      <button
        style={{ cursor: "pointer" }}
        className="resend"
        onClick={() => {
          LoginFunction();
          setResendTimer(60);
          setResendStatus(true);
        }}
      >
        Resend OTP
      </button>
    ) : (
      <button className="resend">
        OTP will Expire in {resendTimer} seconds
      </button>
    );
  };
  const renderTime = () => React.Fragment;

  const generateRecaptcha = () => {
    // if (!window.recaptchaVerifier) {
    console.log("firebase auth is :::", auth);
    // return;
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response) => {
          console.log(
            "reCAPTCHA solved, allow signInWithPhoneNumber.",
            response
          );
        },
      },
      auth
    );
    // }
  };

  const indonesiaSignIn = (authObj, phoneNumber, verifier) => {
    signInWithPhoneNumber(authObj, phoneNumber, verifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        console.log("confirmation result: ", confirmationResult);
        setIndonesiaNumber(phoneNumber);
        setConfirmationState(confirmationResult);
        setLoading(false);
        setResendStatus(true);
        setError("");
        // setNumber(user.msisdn);
        setIsOtp(true);
      })
      .catch(function (error) {
        setLoading(false);
        console.log(error.code);
        console.log(error.message);
        let errorCode = error.code;
        if (errorCode === "auth/invalid-phone-number") {
          setError("The format of the phone number provided is incorrect");
        } else {
          setError("Could not login at the moment, try again after sometime");
        }
      });
  };

  const southAfricaSignIn = (authObj, phoneNumber, verifier) => {
    signInWithPhoneNumber(authObj, phoneNumber, verifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        console.log("confirmation result: ", confirmationResult);
        setSaNumber(phoneNumber);
        setConfirmationState(confirmationResult);
        setLoading(false);
        setResendStatus(true);
        setError("");
        // setNumber(user.msisdn);
        setIsOtp(true);
      })
      .catch(function (error) {
        setLoading(false);
        console.log(error.code);
        console.log(error.message);
        let errorCode = error.code;
        if (errorCode === "auth/invalid-phone-number") {
          setError("The format of the phone number provided is incorrect");
        } else {
          setError("Could not login at the moment, try again after sometime");
        }
      });
  };

  const qatarSignIn = (authObj, phoneNumber, verifier) => {
    signInWithPhoneNumber(authObj, phoneNumber, verifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        console.log("confirmation result: ", confirmationResult);
        setQatarNumber(phoneNumber);
        setConfirmationState(confirmationResult);
        setLoading(false);
        setResendStatus(true);
        setError("");
        // setNumber(user.msisdn);
        setIsOtp(true);
      })
      .catch(function (error) {
        setLoading(false);
        console.log(error.code);
        console.log(error.message);
        let errorCode = error.code;
        if (errorCode === "auth/invalid-phone-number") {
          setError("The format of the phone number provided is incorrect");
        } else {
          setError("Could not login at the moment, try again after sometime");
        }
      });
  };
  const saudiArabiaSignIn = (authObj, phoneNumber, verifier) => {
    signInWithPhoneNumber(authObj, phoneNumber, verifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        console.log("confirmation result: ", confirmationResult);
        setSaudiNumber(phoneNumber);
        setConfirmationState(confirmationResult);
        setLoading(false);
        setResendStatus(true);
        setError("");
        // setNumber(user.msisdn);
        setIsOtp(true);
      })
      .catch(function (error) {
        setLoading(false);
        console.log(error.code);
        console.log(error.message);
        let errorCode = error.code;
        if (errorCode === "auth/invalid-phone-number") {
          setError("The format of the phone number provided is incorrect");
        } else {
          setError("Could not login at the moment, try again after sometime");
        }
      });
  };

  const czechRepublicSignIn = (authObj, phoneNumber, verifier) => {
    signInWithPhoneNumber(authObj, phoneNumber, verifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        console.log("confirmation result: ", confirmationResult);
        setCzechNumber(phoneNumber);
        setConfirmationState(confirmationResult);
        setLoading(false);
        setResendStatus(true);
        setError("");
        // setNumber(user.msisdn);
        setIsOtp(true);
      })
      .catch(function (error) {
        setLoading(false);
        console.log(error.code);
        console.log(error.message);
        let errorCode = error.code;
        if (errorCode === "auth/invalid-phone-number") {
          setError("The format of the phone number provided is incorrect");
        } else {
          setError("Could not login at the moment, try again after sometime");
        }
      });
  };

  const zongPakSignIn = (authObj, phoneNumber, verifier) => {
    signInWithPhoneNumber(authObj, phoneNumber, verifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        console.log("confirmation result: ", confirmationResult);
        setZongNumber(phoneNumber);
        setConfirmationState(confirmationResult);
        setLoading(false);
        setResendStatus(true);
        setError("");
        // setNumber(user.msisdn);
        setIsOtp(true);
      })
      .catch(function (error) {
        setLoading(false);
        console.log(error.code);
        console.log(error.message);
        let errorCode = error.code;
        if (errorCode === "auth/invalid-phone-number") {
          setError("The format of the phone number provided is incorrect");
        } else {
          console.log("error code is :::", error.code);
          setError("Could not login at the moment, try again after sometime");
        }
      });
  };

  const menu_flag = (
    <Menu>
      <Menu.Item
        onClick={() =>
          setCurrentCountry({
            countryName: "pakistan",
            code: "PK",
            countryCode: "+92",
          })
        }
      >
        <div style={styles.flag_dropdown}>
          <img
            src={flag}
            style={{
              // border: "2px solid",
              borderRadius: 3,
            }}
            className="flagstyle"
          />
          <p style={styles.flag_text}>Pakistan (+92)</p>
        </div>
      </Menu.Item>
      <Menu.Item
        onClick={() =>
          setCurrentCountry({
            countryName: "Iraq",
            code: "IQ",
            countryCode: "+964",
          })
        }
      >
        <div style={styles.flag_dropdown}>
          <img src={iraq} className="flagstyle" />
          <p style={styles.flag_text}>Iraq (+964)</p>
        </div>
      </Menu.Item>
      <Menu.Item
        onClick={() =>
          setCurrentCountry({
            countryName: "Indonesia",
            code: "IND",
            countryCode: "+62",
          })
        }
      >
        <div style={styles.flag_dropdown}>
          <img
            src={indonesia}
            className="flagstyle"
            style={{
              // border: "2px solid",
              borderRadius: 3,
            }}
          />
          <p style={styles.flag_text}>Indonesia (+62)</p>
        </div>
      </Menu.Item>
      <Menu.Item
        onClick={() =>
          setCurrentCountry({
            countryName: "Qatar",
            code: "QA",
            countryCode: "+974",
          })
        }
      >
        <div style={styles.flag_dropdown}>
          <img
            src={Qatar}
            className="flagstyle"
            style={{
              // border: "2px solid",
              borderRadius: 3,
            }}
          />
          <p style={styles.flag_text}>Qatar (+974)</p>
        </div>
      </Menu.Item>
      <Menu.Item
        onClick={() =>
          setCurrentCountry({
            countryName: "South Africa",
            code: "ZA",
            countryCode: "+27",
          })
        }
      >
        <div style={styles.flag_dropdown}>
          <img
            src={SouthAfrica}
            className="flagstyle"
            style={{
              // width: "2%",
              // border: "2px solid",
              borderRadius: 3,
            }}
          />
          <p style={styles.flag_text}>South Africa (+27)</p>
        </div>
      </Menu.Item>
      <Menu.Item
        onClick={() =>
          setCurrentCountry({
            countryName: "Saudi Arabia",
            code: "KSA",
            countryCode: "+966",
          })
        }
      >
        <div style={styles.flag_dropdown}>
          <img
            src={SaudiArabia}
            className="flagstyle"
            style={{
              // width: "2%",
              // border: "2px solid",
              borderRadius: 3,
            }}
          />
          <p style={styles.flag_text}>Saudi Arabia (+966)</p>
        </div>
      </Menu.Item>
      <Menu.Item
        onClick={() =>
          setCurrentCountry({
            countryName: "Czech Republic",
            code: "CZ",
            countryCode: "+420",
          })
        }
      >
        <div style={styles.flag_dropdown}>
          <img
            src={Czech}
            className="flagstyle"
            style={{
              // width: "2%",
              // border: "2px solid",
              borderRadius: 3,
            }}
          />
          <p style={styles.flag_text}>Czech Republic (+420)</p>
        </div>
      </Menu.Item>
    </Menu>
  );

  const LoginFunction = () => {
    setError("");
    console.log("Login");
    if (number == "") {
      setError("Field is empty. Enter a valid number.");
    } else if (number.length < numberLength) {
      console.log("number length is :::", number.length);
      setError("Invalid entry");
    } else if (
      // number.substr(0, 1) == "3" &&
      number.length == numberLength &&
      number.match(/^[0-9]+$/)
    ) {
      setLoading(true);
      setError("");

      if (currentCountry.code === "IND") {
        let phoneNumber = "62" + number;
        // generateRecaptcha();
        setLoading(false);
        setResendStatus(true);
        setError("");
        // setSecret(data.data);
        // setNumber(user.msisdn);
        setIsOtp(true);
        setNumber(phoneNumber);
        // let appVerifier = window.recaptchaVerifier;
        // indonesiaSignIn(auth, phoneNumber, appVerifier);
      } else if (currentCountry.code === "QA") {
        let phoneNumber = "+974" + number;
        generateRecaptcha();
        let appVerifier = window.recaptchaVerifier;
        qatarSignIn(auth, phoneNumber, appVerifier);
      } else if (currentCountry.code === "ZA") {
        let phoneNumber = "+27" + number;
        generateRecaptcha();
        let appVerifier = window.recaptchaVerifier;
        southAfricaSignIn(auth, phoneNumber, appVerifier);
      } else if (currentCountry.code === "CZ") {
        let phoneNumber = "+420" + number;
        generateRecaptcha();
        let appVerifier = window.recaptchaVerifier;
        czechRepublicSignIn(auth, phoneNumber, appVerifier);
      } else if (currentCountry.code === "KSA") {
        if (
          number.substring(0, 2) === "55" ||
          number.substring(0, 2) === "50" ||
          number.substring(0, 2) === "53" ||
          number.substring(0, 2) === "58" ||
          number.substring(0, 2) === "59" ||
          number.substring(0, 2) === "56" ||
          number.substring(0, 2) === "54"
        ) {
          let phoneNumber = "+966" + number;
          generateRecaptcha();
          let appVerifier = window.recaptchaVerifier;
          saudiArabiaSignIn(auth, phoneNumber, appVerifier);
        } else {
          setError("Please Enter Stc number or Zain number");
        }
      } else {
        if (currentCountry.code === "PK") {
          let num = "92" + number;
          checkPakUser(num).then((response) => {
            console.log("check pak user response is :::", response);
            if (response.success === true) {
              setNetwork_type(response.network);
              setPakOperator(response.operator);
              // if (response.operator === 9) {
              //   console.log("hit operator");
              //   if (response.network === "postpaid") {
              //     console.log("hit postpaid");
              //     setError("This service is only for Zong prepaid customer");
              //     setLoading(false);
              //   } else {
              //     setPakOperator(response.operator);
              //     localStorage.setItem("operator", response.operator);

              //     let firebaseNum = "+92" + number;
              //     const user = {
              //       msisdn: num,
              //       country_id: 162,
              //       device_name: browserName,
              //       app_version: browserVersion,
              //       user_platform: "web",
              //       email: "",
              //       type: "msisdn",
              //     };
              //     userSignup(user)
              //       .then((data) => {
              //         if (
              //           data.message === "error" ||
              //           data.message === "Error"
              //         ) {
              //           console.log("the api response is :::", data);
              //           setError(data.message);
              //           // setLoading(false);
              //         } else {
              //           // console.log("Secret: ", data.data);
              //           // console.log("Phone: ", user.msisdn);
              //           // console.log("login data :::", data.data);
              //           console.log("data is :::", data);

              //           // setLoading(false);
              //           // setResendStatus(true);
              //           // setError("");
              //           // setSecret(data.data);
              //           setZongSecret(data.data);
              //           // setNumber(user.msisdn);
              //           // setIsOtp(true);
              //         }
              //       })
              //       .catch((error) => {
              //         console.log("user signup is :::", error);
              //       });
              //     generateRecaptcha();
              //     let appVerifier = window.recaptchaVerifier;
              //     zongPakSignIn(auth, firebaseNum, appVerifier);
              //   }
              // } else {
              if (
                response.operator === 3 ||
                response.operator === 11 ||
                response.operator === 9
              ) {
                if (response.operator === 11 || response.operator === 9) {
                  localStorage.setItem("operator", response.operator);
                  localStorage.setItem("postpaid", response.network);
                }
                const user = {
                  msisdn: "92" + number,
                  country_id: 162,
                  device_name: browserName,
                  app_version: browserVersion,
                  user_platform: "web",
                  type: "msisdn",
                  email: "",
                };
                console.log("iraq user is :::", user);
                userSignup(user)
                  .then((data) => {
                    if (data.message === "error" || data.message === "Error") {
                      console.log("the api response is :::", data);
                      setError(data.message);
                      setLoading(false);
                    } else {
                      // console.log("Secret: ", data.data);
                      // console.log("Phone: ", user.msisdn);
                      // console.log("login data :::", data.data);
                      console.log("data is :::", data);

                      setLoading(false);
                      setResendStatus(true);
                      setError("");
                      setSecret(data.data);
                      // setNumber(user.msisdn);
                      setIsOtp(true);
                    }
                  })
                  .catch((error) => {
                    console.log("error is :::", error);
                  });
              }
              // }
            }
          });
        } else {
          const user = {
            msisdn:
              currentCountry.code === "PK"
                ? "92" + number
                : currentCountry.code === "IQ"
                ? "964" + number
                : currentCountry.code === "IND"
                ? "62" + number
                : currentCountry.code === "ZA"
                ? "27" + number
                : currentCountry.code === "KSA"
                ? number
                : currentCountry.code === "CZ"
                ? "420" + number
                : "974" + number,
            country_id:
              currentCountry.code === "PK"
                ? 162
                : currentCountry.code === "IQ"
                ? 102
                : currentCountry.code === "QA"
                ? 174
                : currentCountry.code === "ZA"
                ? 197
                : currentCountry.code === "KSA"
                ? 187
                : currentCountry.code === "CZ"
                ? 57
                : 100,
            device_name: browserName,
            app_version: browserVersion,
            user_platform: "web",
            email: "",
            type: "msisdn",
          };
          console.log("iraq user is :::", user);
          userSignup(user)
            .then((data) => {
              if (data.message === "error" || data.message === "Error") {
                console.log("the api response is :::", data);
                setError(data.message);
                setLoading(false);
              } else {
                // console.log("Secret: ", data.data);
                // console.log("Phone: ", user.msisdn);
                // console.log("login data :::", data.data);
                console.log("data is :::", data);

                setLoading(false);
                setResendStatus(true);
                setError("");
                setSecret(data.data);
                // setNumber(user.msisdn);
                setIsOtp(true);
              }
            })
            .catch((error) => {
              console.log("error is :::", error);
            });
        }
      }
    } else {
      setError("Invalid entry.");
    }
  };

  const onSubmit = () => {
    if (OTP.length === 0) {
      return;
    }
    if (OTP.length < otp_check) {
      setError(`Invalid Entry, otp must be of ${otp_check} digits`);
      return;
    } else {
      setLoading(true);
      if (currentCountry.code === "IND") {
        // confirmationState
        //   .confirm(OTP)
        //   .then((data) => {
        //     console.log(data);
        //     const userDetails = {
        //       msisdn: indonesiaNumber.substring(1),
        //       pin: OTP,
        //       country_id: 100,
        //       fcm_token: isTokenFound,
        //       type: "msisdn",
        //       email: "",
        //     };
        //     otpVerify(userDetails)
        //       .then((data) => {
        //         if (data.status === 200) {
        //           setLoading(false);
        //           console.log("data otp : ", data.user.subscribed);
        //           localStorage.removeItem("subscribed");
        //           localStorage.setItem("subscribed", data.user.subscribed);
        //           // localStorage.removeItem("subscribed");
        //           localStorage.removeItem("dailyLimit");
        //           // localStorage.setItem("subscribed", data.user.subscribed);
        //           localStorage.setItem(
        //             "dailyLimit",
        //             data.user.daily_limit_reached
        //           );
        //           authenticate(
        //             data.user.token,
        //             indonesiaNumber,
        //             currentCountry.code,
        //             () => setRedirectTo(true)
        //           );
        //         } else {
        //           setLoading(false);
        //         }
        //       })
        //       .catch((err) =>
        //         setError("Some error occured, try again please.")
        //       );
        //   })
        //   .catch(function (error) {
        //     setLoading(false);
        //     console.log(error.code);
        //     console.log(error.message);
        //     let errorCode = error.code;
        //     if (errorCode === "auth/invalid-verification-code") {
        //       setError("Invalid OTP, please try again.");
        //     } else {
        //       setError(
        //         "Could not login at the moment, try again after sometime"
        //       );
        //     }
        //   });
        let body = {
          country_id: 100,
          msisdn: number,
          otp: OTP,
        };
        indoSignInApi(body)
          .then((response) => {
            if (response.status === 200) {
              setLoading(false);
              console.log("data otp : ", response.user.subscribed);
              localStorage.removeItem("subscribed");
              localStorage.setItem("subscribed", response.user.subscribed);
              // localStorage.removeItem("subscribed");
              localStorage.removeItem("dailyLimit");
              // localStorage.setItem("subscribed", data.user.subscribed);
              localStorage.setItem(
                "dailyLimit",
                response.user.daily_limit_reached
              );
              authenticate(
                response.user.token,
                indonesiaNumber,
                currentCountry.code,
                () => setRedirectTo(true)
              );
            } else {
              setLoading(false);
            }
          })
          .catch((error) => {
            setLoading(false);
            console.log("error is :::", error);
          });
      }
      //  else if (currentCountry.code === "PK" && pakOperator === 9) {
      //   confirmationState
      //     .confirm(OTP)
      //     .then((data) => {
      //       console.log(data);
      //       const userDetails = {
      //         msisdn: zongNumber.substring(1),
      //         pin: OTP,
      //         country_id: 162,
      //         fcm_token: isTokenFound,
      //         type: "msisdn",
      //         email: "",
      //         operator: pakOperator,
      //         network_type: network_type,
      //       };
      //       otpVerify(userDetails)
      //         .then((data) => {
      //           if (data.status === 200) {
      //             setLoading(false);
      //             console.log("data otp : ", data.user.subscribed);
      //             localStorage.removeItem("subscribed");
      //             localStorage.setItem("subscribed", data.user.subscribed);
      //             // localStorage.removeItem("subscribed");
      //             localStorage.removeItem("dailyLimit");
      //             // localStorage.setItem("subscribed", data.user.subscribed);
      //             localStorage.setItem(
      //               "dailyLimit",
      //               data.user.daily_limit_reached
      //             );
      //             authenticate(
      //               data.user.token,
      //               zongNumber,
      //               currentCountry.code,
      //               () => setRedirectTo(true)
      //             );
      //           } else {
      //             setLoading(false);
      //           }
      //         })
      //         .catch((err) =>
      //           setError("Some error occured, try again please.")
      //         );
      //     })
      //     .catch(function (error) {
      //       setLoading(false);
      //       console.log(error.code);
      //       console.log(error.message);
      //       let errorCode = error.code;
      //       if (errorCode === "auth/invalid-verification-code") {
      //         setError("Invalid OTP, please try again.");
      //       } else {
      //         setError(
      //           "Could not login at the moment, try again after sometime"
      //         );
      //       }
      //     });
      // }
      else if (currentCountry.code === "QA") {
        confirmationState
          .confirm(OTP)
          .then((data) => {
            console.log(data);
            const userDetails = {
              msisdn: qatarNumber.substring(1),
              pin: OTP,
              country_id: 174,
              fcm_token: isTokenFound,
              type: "msisdn",
              email: "",
            };
            otpVerify(userDetails)
              .then((data) => {
                if (data.status === 200) {
                  setLoading(false);
                  console.log("data otp : ", data.user.subscribed);
                  localStorage.removeItem("subscribed");
                  localStorage.setItem("subscribed", data.user.subscribed);
                  // localStorage.removeItem("subscribed");
                  localStorage.removeItem("dailyLimit");
                  // localStorage.setItem("subscribed", data.user.subscribed);
                  localStorage.setItem(
                    "dailyLimit",
                    data.user.daily_limit_reached
                  );
                  authenticate(
                    data.user.token,
                    qatarNumber,
                    currentCountry.code,
                    () => setRedirectTo(true)
                  );
                } else {
                  setLoading(false);
                }
              })
              .catch((err) =>
                setError("Some error occured, try again please.")
              );
          })
          .catch(function (error) {
            setLoading(false);
            console.log(error.code);
            console.log(error.message);
            let errorCode = error.code;
            if (errorCode === "auth/invalid-verification-code") {
              setError("Invalid OTP, please try again.");
            } else {
              setError(
                "Could not login at the moment, try again after sometime"
              );
            }
          });
      } else if (currentCountry.code === "ZA") {
        confirmationState
          .confirm(OTP)
          .then((data) => {
            console.log("otp data is :::", data);
            const userDetails = {
              msisdn: saNumber.substring(1),
              pin: OTP,
              country_id: 197,
              fcm_token: isTokenFound,
              type: "msisdn",
              email: "",
            };
            console.log("SA USER DETAILS ARE :::", userDetails);
            otpVerify(userDetails)
              .then((data) => {
                if (data.status === 200) {
                  setLoading(false);
                  console.log("data otp : ", data.user.subscribed);
                  console.log("sa otp data is :::", data);
                  localStorage.setItem(
                    "encryptedMsisdn",
                    data.user.encrypted_msisdn
                  );
                  localStorage.removeItem("subscribed");
                  localStorage.setItem("subscribed", data.user.subscribed);
                  // localStorage.removeItem("subscribed");
                  localStorage.removeItem("dailyLimit");
                  // localStorage.setItem("subscribed", data.user.subscribed);
                  localStorage.setItem(
                    "dailyLimit",
                    data.user.daily_limit_reached
                  );
                  authenticate(
                    data.user.token,
                    saNumber,
                    currentCountry.code,
                    () => setRedirectTo(true)
                  );
                } else {
                  setLoading(false);
                }
              })
              .catch((err) =>
                setError("Some error occured, try again please.")
              );
          })
          .catch(function (error) {
            setLoading(false);
            console.log(error.code);
            console.log(error.message);
            let errorCode = error.code;
            if (errorCode === "auth/invalid-verification-code") {
              setError("Invalid OTP, please try again.");
            } else {
              setError(
                "Could not login at the moment, try again after sometime"
              );
            }
          });
      } else if (currentCountry.code === "KSA") {
        confirmationState
          .confirm(OTP)
          .then((data) => {
            console.log("otp data is :::", data);
            const userDetails = {
              msisdn: saudiNumber.substring(1),
              pin: OTP,
              country_id: 187,
              fcm_token: isTokenFound,
              type: "msisdn",
              email: "",
            };
            console.log("user Details of saudi arabia is :::", userDetails);
            console.log(
              "country code of saudi arabia is :::",
              currentCountry.code
            );
            otpVerify(userDetails)
              .then((data) => {
                if (data.status === 200) {
                  setLoading(false);
                  console.log("data otp : ", data.user.subscribed);
                  localStorage.removeItem("subscribed");
                  localStorage.setItem("subscribed", data.user.subscribed);
                  // localStorage.removeItem("subscribed");
                  localStorage.removeItem("dailyLimit");
                  // localStorage.setItem("subscribed", data.user.subscribed);
                  localStorage.setItem(
                    "dailyLimit",
                    data.user.daily_limit_reached
                  );
                  authenticate(
                    data.user.token,
                    saudiNumber,
                    currentCountry.code,
                    () => setRedirectTo(true)
                  );
                } else {
                  setLoading(false);
                }
              })
              .catch((err) =>
                setError("Some error occured, try again please.")
              );
          })
          .catch(function (error) {
            setLoading(false);
            console.log(error.code);
            console.log(error.message);
            let errorCode = error.code;
            if (errorCode === "auth/invalid-verification-code") {
              setError("Invalid OTP, please try again.");
            } else {
              setError(
                "Could not login at the moment, try again after sometime"
              );
            }
          });
      } else if (currentCountry.code === "CZ") {
        confirmationState
          .confirm(OTP)
          .then((data) => {
            console.log("otp data is :::");
            const userDetails = {
              msisdn: czechNumber.substring(1),
              pin: OTP,
              country_id: 57,
              fcm_token: "",
              type: "msisdn",
              email: "",
            };
            console.log("user Details of cz is :::", userDetails);
            console.log("country code of cz is :::", currentCountry.code);
            otpVerify(userDetails)
              .then((data) => {
                if (data.status === 200) {
                  setLoading(false);
                  console.log("data otp : ", data.user.subscribed);
                  localStorage.removeItem("subscribed");
                  localStorage.setItem("subscribed", data.user.subscribed);
                  // localStorage.removeItem("subscribed");
                  localStorage.removeItem("dailyLimit");
                  // localStorage.setItem("subscribed", data.user.subscribed);
                  localStorage.setItem(
                    "dailyLimit",
                    data.user.daily_limit_reached
                  );
                  authenticate(
                    data.user.token,
                    czechNumber,
                    currentCountry.code,
                    () => setRedirectTo(true)
                  );
                } else {
                  setLoading(false);
                }
              })
              .catch((err) =>
                setError("Some error occured, try again please.")
              );
          })
          .catch(function (error) {
            setLoading(false);
            console.log(error.code);
            console.log(error.message);
            let errorCode = error.code;
            if (errorCode === "auth/invalid-verification-code") {
              setError("Invalid OTP, please try again.");
            } else {
              setError(
                "Could not login at the moment, try again after sometime"
              );
            }
          });
      } else {
        console.log("otp is :::", OTP);
        // return;
        const otpdetails = {
          msisdn:
            currentCountry.code === "KSA"
              ? number
              : currentCountry.code === "CZ"
              ? "420" + number
              : pakOperator === 9
              ? "92" + number
              : secret,
          pin: OTP,
          country_id:
            currentCountry.code === "PK"
              ? 162
              : currentCountry.code === "IQ"
              ? 102
              : currentCountry.code === "ZA"
              ? 197
              : currentCountry.code === "QA"
              ? 174
              : currentCountry.code === "KSA"
              ? 187
              : currentCountry.code === "CZ"
              ? 57
              : 100,
          device_name: browserName,
          app_version: browserVersion,
          user_platform: "Web",
          type: "msisdn",
          email: "",
          operator: pakOperator,
          network_type: network_type,
        };
        console.log("otp details are :::", otpdetails);
        otpVerify(otpdetails).then((data) => {
          if (data.message === "Error") {
            setError("Invalid OTP, try again");
            setLoading(false);
          } else {
            setLoading(false);
            console.log("data is :::", data);
            // console.log("data otp : ", data.user.subscribed);
            localStorage.removeItem("subscribed");
            localStorage.removeItem("dailyLimit");
            localStorage.setItem("subscribed", data.user.subscribed);
            localStorage.setItem("dailyLimit", data.user.daily_limit_reached);

            authenticate(data.user.token, 0 + number, currentCountry.code, () =>
              setRedirectTo(true)
            );
          }
        });
      }
    }
  };
  const _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      console.log("do validate");
      LoginFunction();
    }
  };
  const handleOtpKeyDown = (e) => {
    if (e.key === "Enter") {
      console.log("otp enter");
      onSubmit();
    }
  };

  if (redirectTo) {
    return <Navigate to="/LpAboutYou" state={location.state} />;
  }

  return (
    <div className="Login-page-container">
      <div className="containerLeft"></div>
      <div className="containerRight">
        <div style={{ flexDirection: "row" }}>
          <span className="titletext" style={{ color: "#ff1e1e" }}>
            FIT
          </span>
          <span className="titletext" style={{ color: "#707070" }}>
            FLEX
          </span>
        </div>
        {isOtp ? (
          <>
            <h1 className="welcome">Enter OTP </h1>
            <p className="ttext">
              <ResendOTP
                renderButton={renderButton}
                renderTime={renderTime}
                onResendClick={() => console.log("Resend clicked")}
              />
            </p>
            <OTPInput
              value={OTP}
              onChange={setOTP}
              onKeyDown={handleOtpKeyDown}
              autoFocus
              OTPLength={
                currentCountry.code === "IQ"
                  ? 5
                  : currentCountry.code === "IND" ||
                    currentCountry.code === "ZA" ||
                    currentCountry.code === "QA" ||
                    currentCountry.code === "KSA" ||
                    currentCountry.code === "CZ"
                  ? 6
                  : currentCountry.code === "PK" && 4
              }
              otpType="number"
              disabled={false}
              // secure
              inputClassName={
                currentCountry.code === "IND" ||
                currentCountry.code === "QA" ||
                currentCountry.code === "ZA" ||
                currentCountry.code === "KSA" ||
                currentCountry.code === "CZ"
                  ? "indonesiaInput"
                  : "otpinput"
              }
              className={
                currentCountry.code === "IND" ||
                currentCountry.code === "QA" ||
                currentCountry.code === "ZA" ||
                currentCountry.code === "KSA" ||
                currentCountry.code === "CZ"
                  ? "indonesiaOTP"
                  : "otp"
              }
            />
            <p
              className="ttext"
              style={{
                color: "red",
                marginTop: 20,
                marginBottom: 0,
                display: error != "" ? "flex" : "none",
              }}
            >
              {error}
            </p>
            {loading ? (
              <div className="loading-div">
                <Spin indicator={antIcon} tip="Verifying OTP..." />
              </div>
            ) : (
              <Button className="btn-login" onClick={onSubmit}>
                Continue
              </Button>
            )}
          </>
        ) : (
          <>
            <h1 className="welcome">Welcome Back</h1>

            <p className="ttext">
              We’ll text you a verification code. Message and data rates may
              apply.
            </p>
            <div className="inputdiv">
              <span className="myDropdown">
                <Dropdown overlay={menu_flag}>
                  <img
                    src={
                      currentCountry.code === "IQ"
                        ? iraq
                        : currentCountry.code === "PK"
                        ? flag
                        : currentCountry.code === "IND"
                        ? indonesia
                        : currentCountry.code === "QA"
                        ? Qatar
                        : currentCountry.code === "KSA"
                        ? SaudiArabia
                        : currentCountry.code === "CZ"
                        ? Czech
                        : SouthAfrica
                    }
                    className="flagstyle"
                  />
                </Dropdown>
              </span>
              <Input
                className="inputfeild"
                value={number}
                maxLength={numberLength}
                onKeyDown={_handleKeyDown}
                bordered={false}
                // onChange={(e) => setNumber(e.target.value)}
                onChange={(e) => {
                  const re = /^[0-9\b]+$/;
                  // if value is not blank, then test the regex
                  if (e.target.value === "" || re.test(e.target.value)) {
                    setNumber(e.target.value);
                  }
                }}
                placeholder={"XXXXXXXXXX"}
                prefix={
                  <span
                    style={{
                      fontSize: 14,
                    }}
                  >
                    {currentCountry.countryCode}
                  </span>
                }
              />
            </div>
            <p
              className="ttext"
              style={{
                color: "red",
                marginTop: 20,
                marginBottom: 0,
                display: error != "" ? "flex" : "none",
              }}
            >
              {error}
            </p>
            {loading ? (
              <div className="loading-div">
                <Spin indicator={antIcon} tip="Loading..." />
              </div>
            ) : (
              <Button
                className="btn-login"
                onClick={() => {
                  LoginFunction();
                }}
                id="sign-in-button"
              >
                Continue
              </Button>
            )}
          </>
        )}

        <div className="social-icons">
          <a href="https://www.facebook.com/Fitflex-108023608383081/">
            <img src={Facebook} style={{ marginRight: 50 }} />
          </a>
          <a href="https://www.instagram.com/fitflex_app/">
            <img src={Instagram} style={{ marginRight: 50 }} />
          </a>
          <a href="https://www.youtube.com/channel/UCajFgus4QBtdyA0MCTDSUZg">
            <img src={Youtube} className="icon-spacing" />
          </a>
        </div>
        <div id="recaptcha-container"></div>
        <div
          style={{
            marginTop: "50px",
          }}
          className="center-images-on-resize"
        >
          <a href="https://play.google.com/store/apps" target="_blank">
            <img
              src={android}
              style={{
                width: 180,
                // maxWidth: "100%",
                height: "auto",
                marginRight: "4px",
              }}
              alt="playstore"
            />
          </a>
          <a href="https://www.apple.com/app-store/" target="_blank">
            <img
              src={apple}
              style={{
                width: 180,
                // maxWidth: "100%",
                height: "auto",
                border: "1px solid grey",
                borderRadius: "4px",
              }}
              alt="appstore"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

const styles = {
  flag_dropdown: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: -10,
  },
  flag_text: {
    marginTop: 10,
    marginLeft: 10,
  },
};

export default Login;
